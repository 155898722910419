import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import appConfig from './appConfigReducer';
import currentUser from './currentUserReducer';
import users from './usersReducer';
import createUser from './createUserReducer';
import deleteUser from './deleteUserReducer';
import updateUser from './updateUserReducer';
import dictionaryWordsInPrefix from './dictionaryWordsInPrefixReducer';
import fileManagerData from './fileManagerDataReducer';
import deleteFile from "./deleteFileReducer";
import updateFile from "./updateFileReducer";
import files from './filesReducer';
import fileUploadStatus from './fileUploadStatusReducer';
import saveAppConfig from './saveAppConfigReducer';
import saveSearchResult from './saveSearchResultReducer';
import searchResults from './searchResultsReducer';
import suggest from './suggestReducer';
import metaSearchMoveToTop from "./metaSearchMoveToTopReducer";
import metaSearchAddRedirect from "./metaSearchAddtoRedirectReducer";
import metaSearchAddVideoEmbed from "./metaSearchAddVideoEmbedReducer";
import metaSearchExtractInfo from "./metaSearchExtractInfoReducer";
import metaSearchRemove from "./metaSearchRemoveReducer";
import login from "./userLoginReducer";
import signUp from "./userSignUpReducer";
import saveUserProfile from "./saveUserProfileReducer";
import placeOrder from "./placeOrderReducer";
import setCartItemQuantity from "./setCartItemQuantityReducer";

const rootReducer = history => combineReducers({
  router: connectRouter(history),

  appConfig,
  currentUser,

  users,
  createUser,
  deleteUser,
  updateUser,

  dictionaryWordsInPrefix,

  fileManagerData,
  deleteFile,
  updateFile,
  files,
  fileUploadStatus,

  saveAppConfig,
  saveSearchResult,
  searchResults,
  suggest,

  metaSearchMoveToTop,
  metaSearchAddRedirect,
  metaSearchAddVideoEmbed,
  metaSearchExtractInfo,
  metaSearchRemove,

  login,
  signUp,
  saveUserProfile,

  placeOrder,
  setCartItemQuantity,

});

export default rootReducer;
