import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import cookie from "react-cookies";
import moment from "moment";

class FirstTimeUserModal extends React.Component {
  constructor(props) {
    super(props);

    let hasVisited = cookie.load('hasVisited');
    if (!hasVisited) {
      hasVisited = false;
    }

    this.state = {
      hasVisited,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    const cookieOptions = {
      path: '/',
      sameSite: true,
    };

    cookieOptions.maxAge = 100 * 365 * 24 * 60 * 60;
    cookieOptions.expires = moment().add(1, 'year').toDate();

    const hasVisited = true;
    cookie.save('hasVisited', hasVisited, cookieOptions);

    this.setState({
      hasVisited,
    });
  }

  render() {
    const {appConfig} = this.props;
    const {search} = appConfig.data;
    const {hasVisited} = this.state;

    console.log(search);

    if (hasVisited) {
      return null;
    }

    if (!search) {
      return null;
    }

    if (!search.showFirstTimeUserModal) {
      return null;
    }

    return (
      <Modal isOpen={true} className="ShowModal">
        <ModalHeader> {search.noticeHeading} </ModalHeader>
        <ModalBody>
          <div
            className="mt-0 notice-body"
            dangerouslySetInnerHTML={{__html: search.firstTimeUserNotice}}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggleModal}>
            {search.noticeButtonText}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

FirstTimeUserModal.defaultProps = {
  actions: {},

};

FirstTimeUserModal.defaultProps = {
  appConfig: {},
};

FirstTimeUserModal.propTypes = {
  appConfig: PropTypes.object,
};

const mapStateToProps = (state => ({
  appConfig: state.appConfig,
}));

export default connect(mapStateToProps)(FirstTimeUserModal);
