import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardHeader,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Stickies from 'react-stickies';

// import * as actionsActions from '../actions/actions';

class StickyNotesWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="dashboard-widget sticky-notes-container">
        <Card>
          <CardHeader>
              Things TO DO
            </CardHeader>
            <CardBody>
              Meeting scheduled at
              .........
              ........


              <Stickies />
          </CardBody>
        </Card>
      </div>
    );
  }
}

StickyNotesWidget.defaultProps = {
  actions: {},
};

StickyNotesWidget.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */)  => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StickyNotesWidget);
