import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Tooltip} from 'reactstrap';
import {connect} from 'react-redux';
import classNames from 'classnames';

class PrivacyStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
    };

    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  toggleTooltip() {
    const {tooltipOpen} = this.state;
    this.setState({
      tooltipOpen: !tooltipOpen,
    });
  }

  render() {
    const {tooltipOpen} = this.state;
    const {appConfig} = this.props;

    if (!appConfig.loaded) {
      return null;
      //return (<div className="privacy-status-container">Privacy Status: Loading</div>);
    }

    let privacySettings = null;
    let badgeColor, description, statusStr, privacyStatus;

    if (appConfig.data.privacy) {
      privacySettings = appConfig.data.privacy;

      if (privacySettings.saveUserInfo || privacySettings.trackMetaSearchClicks) {
        badgeColor = 'danger';
        description = 'This site stores your IP address and User Agent information along with your search query.';
        statusStr = 'AT RISK';
      } else if (privacySettings.trackSearchCount) {
        badgeColor = 'warning';
        description = 'This site does not store your IP address and User Agent information, but it tracks count of search queries.';
        statusStr = 'MEDIUM';
      } else {
        badgeColor = 'success';
        description = 'This site does not store your IP address and User Agent information. It also does not track count of search queries.';
        statusStr = 'PRIVATE';
      }

    } else {
      badgeColor = 'danger';
      description = 'The site administrator has not set any privacy settings.';
      statusStr = 'NOT SET';
    }

    if (appConfig.data.privacy) {
      privacySettings = appConfig.data.privacy;

      if (privacySettings.showPrivacyStatus) {

        privacyStatus = (<div>
          <span className="">Privacy<span className="d-none d-md-inline"> Status</span> &nbsp;</span>
          <span>
            <Badge color={badgeColor} id="PrivacyBadge" className="pointer link">
              {statusStr}
            </Badge>

            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target="PrivacyBadge"
              toggle={this.toggleTooltip}
            >
              {description}
            </Tooltip>
          </span>
        </div>);
      }
    }

    const {alignment} = this.props;

    return (
      <div className="privacy-status-container">
        <div
          className={
            classNames({
              "d-flex": true,
              "justify-content-center justify-content-lg-end": alignment === 'end',
              "justify-content-center justify-content-lg-center": alignment === 'center',
            })
          }
        >
          {privacyStatus}
          {/*<span className="">Privacy<span className="d-none d-md-inline"> Status</span> &nbsp;</span>

          <span>
            <Badge color={badgeColor} id="PrivacyBadge" className="pointer link">
              {statusStr}
            </Badge>

            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target="PrivacyBadge"
              toggle={this.toggleTooltip}
            >
              {description}
            </Tooltip>
          </span>*/}
        </div>
      </div>
    );
  }
}

PrivacyStatus.defaultProps = {
  alignment: 'end',
  appConfig: {},
};

PrivacyStatus.propTypes = {
  alignment: PropTypes.string,
  appConfig: PropTypes.object,
};

const mapStateToProps = state => ({
  appConfig: state.appConfig,
});

export default connect(mapStateToProps)(PrivacyStatus);
