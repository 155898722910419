import React from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown, DropdownItem, DropdownMenu,
  DropdownToggle,

} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EditResultModal from '../../EditPage/EditResultModal';
// import * as actionsActions from '../actions/actions';

class AddResultTypeDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDropdownOpen: false,
      showEditForm: false,
      chosenResultType: '',
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleEditForm = this.toggleEditForm.bind(this);
  }

  toggleDropdown() {
    const { isDropdownOpen } = this.state;
    this.setState({
      isDropdownOpen: !isDropdownOpen,
    });
  }

  toggleEditForm(chosenResultType) {
    const { showEditForm } = this.state;
    this.setState({
      showEditForm: !showEditForm,
      chosenResultType,
    });
  }

  render() {
    const { query } = this.props;
    const { isDropdownOpen, showEditForm, chosenResultType } = this.state;

    let editResultModal = null;
    if (showEditForm) {
      editResultModal = (
        <EditResultModal
          query={query}
          defaultResultType={chosenResultType}
          toggleModal={this.toggleEditForm}
        />
      );
    }

    return (
      <div className="d-inline-block">
        <Dropdown tag="span" isOpen={isDropdownOpen} toggle={this.toggleDropdown} direction="down"
                  className="web-page-options custom-dropdown-style1">
          <DropdownToggle
            tag="a"
            data-toggle="dropdown"
            aria-expanded={isDropdownOpen}
            className="pl-2 pr-2 pt-1 pb-1 pointer link"
            style={{ color: '#555' }}
          >
            <i className="fa fa-paint-brush" />
            &nbsp;
            Customize
          </DropdownToggle>
          <DropdownMenu className="p-0">
            <DropdownItem onClick={() => {
              this.toggleEditForm('alias');
            }}>
              <i className="fa fa-at" />
              &nbsp;
              Alias
            </DropdownItem>
            <DropdownItem onClick={() => {
              this.toggleEditForm('redirect');
            }}>
              <i className="fa fa-external-link" />
              &nbsp;
              Redirect
            </DropdownItem>
            <DropdownItem onClick={() => {
              this.toggleEditForm('fixed_text');
            }}>
              <i className="fa fa-file-text" />
              &nbsp;
              Fixed Text
            </DropdownItem>

            <DropdownItem onClick={() => {
              this.toggleEditForm('files');
            }}>
              <i className="fa fa-upload" />
              &nbsp;
              File Upload
            </DropdownItem>

            <DropdownItem onClick={() => {
              this.toggleEditForm('video');
            }}>
              <i className="fa fa-file-video-o" />
              &nbsp;
              Video Embed
            </DropdownItem>

            <DropdownItem onClick={() => {
              this.toggleEditForm('blog_article');
            }}>
              <i className="fa fa-cloud-upload" />
              &nbsp;
              Blog Article
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        {editResultModal}
      </div>
    );
  }
}

AddResultTypeDropdown.defaultProps = {
  actions: {},
  query: '',
};

AddResultTypeDropdown.propTypes = {
  actions: PropTypes.object,
  query: PropTypes.string,
  resultData: PropTypes.object.isRequired,
};

const mapStateToProps = (/* state */)=> ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddResultTypeDropdown);
