import React from 'react';
import {
  Card, CardBody, CardFooter, CardHeader, Col, Row,
} from 'reactstrap';
import CalculatorContainer from "./CalculatorResult/CalculatorContainer";
import SearchCount from '../Common/SearchCount';
import ResultUpdatedDate from './ResultUpdatedDate';
import AddResultTypeDropdown from "./MetaSearchResult/AddResultTypeDropdown";

// import * as actionsActions from '../actions/actions';

class CalculatorResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { resultData, query } = this.props;

    return (
      <Row className="calculator-result">
        <Col xs={12} className="">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  Calculator
                </Col>
                <Col className='text-right'>
                  <SearchCount searchCount={resultData.result.searchCount} />
                </Col>
                <Col xs="auto" className="d-none d-md-block">
                  <div className="d-inline-block  ml-3">
                    <AddResultTypeDropdown
                      query={query}
                      resultData={resultData}
                    />
                  </div>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              <CalculatorContainer
                query={query}
                resultData={resultData}
              />
            </CardBody>
            <CardFooter>
              <ResultUpdatedDate updatedAt ={resultData.result.updatedAt}/>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    );
  }
}




export default CalculatorResult;
