import React from 'react';
import {Button, Col, Row} from 'reactstrap';
import QuantityButtons from "./QuantityButtons";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as cartActions from "../../actions/cartActions";

class ProductDescription extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.onRemove = this.onRemove.bind(this);

    this.removeStarted = false;
  }


  onRemove() {
    const qty = 0;
    const { _id } = this.props.product;

    this.props.actions.setCartItemQuantityStarting(_id);
    this.props.actions.setCartItemQuantity(_id, qty);

    this.removeStarted = false;

  }

  render() {
    const {title, features, seller, msrp, discount, discountedPrice, imgPath} = this.props.product;

    let featuresText = null;
    let discountTmp = null;
    let discountedPriceTmp = null;

    if (features) {
      featuresText = (
        <p className="text-muted txt-small mt-0 mb-0"> {features} </p>
      );
    }
    if (discount) {
      discountTmp = (
        <span className=" ml-1 txt-green "> {discount} </span>
      );
    }

    if (discountedPrice) {
      discountedPriceTmp = (
        <span className=" ml-1 txt-green "> {discountedPrice} </span>
      );
    }

    return (
      <div className="custom-page cart-page">
        <Row>
          <Col xs={4} md={2}>
            <img src={imgPath}/>
             <QuantityButtons
               product={this.props.product}

            />
          </Col>

          <Col xs={8} md={10}>
            <p className="m-0 txt-bold"> {title}</p>
            {featuresText}
            <p className="text-muted txt-small mt-1"> {seller}</p>
            <p><span className="txt-bold"> {discountedPriceTmp} </span>
              <span className=" ml-1 txt-strike text-muted txt-small"> {msrp} </span>
              {discountTmp}</p>
            <Button size="sm" color="white" className="btn-outline-danger" onClick={this.onRemove}>REMOVE</Button>

          </Col>
        </Row>
        <div className="divider " />

      </div>
    );
  }
}

ProductDescription.defaultProps = {
  actions: {},
  setCartItemQuantity: {},
};

ProductDescription.propTypes = {
  actions: PropTypes.object,
  product: PropTypes.object.isRequired,
  setCartItemQuantity: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  setCartItemQuantity: state.setCartItemQuantity,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, cartActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDescription);

