import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getFileUrl} from '../../../utils/general';
import FilePreviewPDF from './FilePreviewPDF';
import FilePreviewPhoto from "./FilePreviewPhoto";
import FilePreviewVideo from "./FilePreviewVideo";
import FilePreviewAudio from "./FilePreviewAudio";
import FileDownloadLink from "./FileDownloadLink";

const FileTypeUnsupported = () => (
  <div>
    Sorry, this file type is currently unsupported.
  </div>
);

class FilePreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {fileId, files} = this.props;

    if (!files[fileId] || !files[fileId].loaded) {
      return (
        <div>
          File not ready yet.
        </div>
      );
    }

    const file = files[fileId].file;

    let content = null;
    const fileUrl = getFileUrl(file);

    switch (file.fileType) {
      case 'photo': {
        content = (
          <div>
            <FilePreviewPhoto
              fileUrl={fileUrl}
              file={files[fileId].file}
            />

            <FileDownloadLink
              fileUrl={fileUrl}
              file={files[fileId].file}
            />

          </div>
        );

        break;
      }

      case 'video': {
        content = (
          <div>
            <FilePreviewVideo
              fileUrl={fileUrl}
              file={files[fileId].file}/>

            <FileDownloadLink
              fileUrl={fileUrl}
              file={files[fileId].file}
            />
          </div>
        );

        break;
      }

      case 'audio': {
        content = (
          <div>
            <FilePreviewAudio
              fileUrl={fileUrl}
              file={files[fileId].file}/>

            <FileDownloadLink
              fileUrl={fileUrl}
              file={files[fileId].file}
            />
          </div>
        );

        break;
      }

      case 'document': {
        const {showDocument} = this.props;

        if (!showDocument) {
          content = (
            <div>
              Document preview not available.
            </div>
          );
        } else if (file.originalFileName.indexOf('.pdf') !== -1 && file.mimeType === 'application/pdf') {
          content = (
            <div>
              <FilePreviewPDF fileUrl={fileUrl}/>

              <FileDownloadLink
                fileUrl={fileUrl}
                file={files[fileId].file}
              />
            </div>
          );
        } else {
          content = (
            <div>
              <FileTypeUnsupported/>
            </div>
          );
        }

        break;
      }

      default: {
        content = (
          <div>
            <FileTypeUnsupported/>
          </div>
        );

        break;
      }
    }

    return (
      <div>
        {content}
      </div>
    );
  }
}

FilePreview.defaultProps = {
  actions: {},
  files: {},
  showDocument: true,

};

FilePreview.propTypes = {
  actions: PropTypes.object,
  files: PropTypes.object,
  fileId: PropTypes.string.isRequired,
  showDocument: PropTypes.bool,


};

const mapStateToProps = state => ({
  files: state.files,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilePreview);
