import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {isNil} from "lodash";

// import * as actionsActions from '../actions/actions';

class JournalNotes extends React.Component {
  render() {
    const { resultData } = this.props;
    const notesHtml = resultData.result.journalNotes;

    if (isNil(notesHtml)  || !notesHtml.length) {
      return (
        <div>
          <i>No notes added yet.</i>
        </div>
      );
    }

    return (
      <div className="mt-0">
        <div
          className="mt-0 notes-body"
          dangerouslySetInnerHTML={{__html: notesHtml}}
        />
      </div>
    );
  }
}


JournalNotes.defaultProps = {
  actions: {},
};

JournalNotes.propTypes = {
  actions: PropTypes.object,
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(JournalNotes);

