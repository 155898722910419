import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardHeader, CardBody, Container, Button } from 'reactstrap';
import Footer from "./Common/Footer";
import { isLokiExtensionActive, isMobile } from '../utils/general';
import { apiBaseUrl, appName, siteName } from '../constants/appConfig';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SearchForm from "./Common/SearchForm";
import PhotoWidget from "./CustomHomePage/PhotoWidget";
import QuoteWidget from "./CustomHomePage/QuoteWidget";
import DigitalClockWidget from "./CustomHomePage/DigitalClockWidget";
import CalendarWidget from "./CustomHomePage/CalendarWidget";
import MoviesWidget from "./CustomHomePage/MoviesWidget";
import NewsWidget from "./CustomHomePage/NewsWidget";
import AnalogClockWidget from "./CustomHomePage/AnalogClockWidget";
import StickyNotesWidget from "./CustomHomePage/StickyNotesWidget";
import FirstTimeUserModal from "./Common/FirstTimeUserModal";
import Activities from "./AcitivityHomePage/Activities";
import CommonHeader from './Common/CommonHeader';


class CustomHomePage2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileBrowser: isMobile.any(),
      isExtensionActive: true,
    };
  }

  componentDidMount() {
    document.title = siteName;

    setTimeout(() => {
      this.setState({
        isExtensionActive: isLokiExtensionActive(),
      });
    }, 2 * 1000);
  }

  render() {
    let topWarning = null;
    const { appConfig } = this.props;

    if (appConfig.loaded && (!appConfig.data.search || !appConfig.data.search.baseUrl)) {
      topWarning = (
        <div className="unsecure-warning">
          You have not set the Base URL for this instance.
          If you are the site administrator, use the <Link to="/settings">
          Settings page
        </Link> to configure Base URL and install the browser extension.
        </div>
      );
    } else if (appConfig.loaded && appConfig.isPubliclyEditable) {
      topWarning = (
        <div className="unsecure-warning">
          This {appName} instance is publicly editable.
          If you are the site administrator, use the <Link to="/settings">
          Settings page
        </Link> to create user accounts immediately.
        </div>
      );
    }

    let extensionAlert = null;
    if (appConfig.loaded && appConfig.data.search && appConfig.data.search.baseUrl) {
      const { isExtensionActive, isMobileBrowser } = this.state;
      if (!isMobileBrowser && !isExtensionActive) {
        const extensionLink = `${apiBaseUrl}/extension/download`;
        extensionAlert = (
          <div className="download-extension-alert">
            Download the {siteName} browser extension&nbsp;
            <a href={extensionLink} target="_blank" rel="noopener noreferrer">here</a>.
          </div>
        );
      }
    }

    return (
      <div className="activity-home-page ">
        <div>
          <CommonHeader />

          <div className="container activity-container ">
            <Activities />
          </div>
        </div>

        {extensionAlert}
        {topWarning}

        <Footer />
      </div>

    );
  }
}

CustomHomePage2.defaultProps = {
  appConfig: {},
};

CustomHomePage2.propTypes = {
  appConfig: PropTypes.object,
};

const mapStateToProps = (state => ({
  appConfig: state.appConfig,
}));

export default connect(mapStateToProps)(CustomHomePage2);
