import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form, FormGroup, Input, Label,
  Modal, ModalBody, ModalFooter, ModalHeader,
  Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Noty from 'noty';
import * as usersActions from '../../actions/usersActions';


class EditUserModal extends React.Component {
  constructor(props) {
    super(props);

    const { selectedUser } = this.props;

    this.state = {
      email: selectedUser.email,
      password:selectedUser.password,
      confirmPassword: selectedUser.confirmPassword,
      canEdit: selectedUser.canEdit,
      isAdministrator: selectedUser.isAdministrator,
    };

    this.onEmailChanged = this.onEmailChanged.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.onConfirmPasswordChanged = this.onConfirmPasswordChanged.bind(this);
    this.onIsAdministratorChanged = this.onIsAdministratorChanged.bind(this);
    this.onCanEditChanged = this.onCanEditChanged.bind(this);
    this.onSaveClicked = this.onSaveClicked.bind(this);

    this.saveStarted = false;
  }

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    const userId = this.props.selectedUser._id;

    if (
      this.saveStarted &&
      this.props.updateUser !== nextProps.updateUser &&
      nextProps.updateUser[userId]
    ) {
      this.handleStatusChange(nextProps);
    }
  }

  handleStatusChange(props) {
    const userId = props.selectedUser._id;

    if (
      props.updateUser[userId] && !props.updateUser[userId].saving && props.updateUser[userId].saved
    ) {
      new Noty({
        type: 'success',
        layout: 'bottomLeft',
        text: 'User data has been updated successfully.',
      }).show();

      this.props.toggleModal();

      this.saveStarted = false;
    } else if (
      props.updateUser[userId] && !props.updateUser[userId].saving && !props.updateUser[userId].saved
    ) {
      let errorMessage = props.updateUser[userId].error;
      if (!errorMessage) {
        errorMessage = 'Failed to update user data due to server error.';
      }

      new Noty({
        type: 'error',
        layout: 'bottomLeft',
        text: errorMessage,
      }).show();

      this.saveStarted = false;
    }
  }

  onEmailChanged(event) {
    this.setState({
      email: event.target.value,
    });
  }

  onPasswordChanged(event) {
    this.setState({
      password: event.target.value,
    });
  }

  onConfirmPasswordChanged(event) {
    this.setState({
      confirmPassword: event.target.value,
    });
  }

  onCanEditChanged(event) {
    this.setState({
      canEdit: event.target.checked,
    });
  }

  onIsAdministratorChanged(event) {
    this.setState({
      isAdministrator: event.target.checked,
    });
  }

  onSaveClicked() {
    const { selectedUser } = this.props;
    this.props.actions.updateUserStarting();
    this.props.actions.updateUser(selectedUser._id, this.state);
    this.saveStarted = true;
  }

  render() {
    const { email, isAdministrator, password, confirmPassword, canEdit } = this.state;
    const { toggleModal, selectedUser, updateUser } = this.props;

    let btnSave;

    const updateData = updateUser[selectedUser._id];
    if (updateData && updateData.saving) {
      btnSave = (
        <Button color="success" disabled>
          <i className="fa fa-spinner fa-spin" />
          &nbsp;
          Saving
        </Button>
      );
    } else {
      btnSave = (
        <Button color="success" onClick={this.onSaveClicked}>
          <i className="fa fa-save" />
          &nbsp;
          Save
        </Button>
      );
    }

    return (
      <Modal isOpen={true} toggle={toggleModal} className="edit-user-modal">
        <ModalHeader toggle={toggleModal}>Edit User </ModalHeader>
        <ModalBody>
          <div>
            <Form>
              <Row>
                <Col sm="12">
                  <FormGroup className="">
                    <Label for="userName">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder=""
                      value={email}
                      onChange={this.onEmailChanged}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup className="">
                    <Label for="password">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder=""
                      value={password}
                      onChange={this.onPasswordChanged}
                    />
                  </FormGroup>
                </Col>

                <Col sm="12">
                  <FormGroup className="">
                    <Label for="confirPassword">Confirm Password</Label>
                    <Input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder=""
                      value={confirmPassword}
                      onChange={this.onConfirmPasswordChanged}
                    />
                  </FormGroup>
                </Col>


                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input type="checkbox"
                             checked={canEdit}
                             onChange={this.onCanEditChanged}
                      />&nbsp;
                      Can Edit
                    </Label>
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup check>
                    <Label check>
                      <Input type="checkbox"
                             checked={isAdministrator}
                             onChange={this.onIsAdministratorChanged}
                      />&nbsp;
                      Is Administrator
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </ModalBody>
        <ModalFooter>
          {btnSave}

          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

EditUserModal.defaultProps = {
  actions: {},
  updateUser: {},
};

EditUserModal.propTypes = {
  actions: PropTypes.object,
  selectedUser: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  updateUser: PropTypes.object,
};

const mapStateToProps = state => ({
  updateUser: state.updateUser,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, usersActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal);
