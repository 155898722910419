import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class NewsPost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { title, description, } = this.props;
    return (
      <div className= "news-post">
        <Card className="mb-2">
          <CardBody>
            <Row>
              <Col xs={12}>

                <a href={""}> <h6>  {title} </h6></a>
                <p className=""> {description}</p>

              </Col>

            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

NewsPost.defaultProps = {
  actions: {},
  title: '',
  description: '',

};

NewsPost.propTypes = {
  actions: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
}

const mapStateToProps = (/* state */) => ({})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsPost);
