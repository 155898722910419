import { cloneDeep } from 'lodash';
import {
  PLACE_ORDER_STARTING,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_ERROR,

} from '../constants/actionTypes';
import initialState from './initialState';

export default function placeOrderReducer(state = initialState.placeOrder, action) {
  switch (action.type) {
    case PLACE_ORDER_STARTING: {
      const newState = cloneDeep(state);
      newState.saving = true;
      newState.saved = false;
      return newState;
    }

    case PLACE_ORDER_SUCCESS: {
      const newState = cloneDeep(state);
      newState.saving = false;
      newState.saved = true;
      newState.error = null;

      return newState;
    }

    case PLACE_ORDER_ERROR: {
      const newState = cloneDeep(state);
      newState.saving = false;
      newState.saved = false;
      newState.error = action.err ? action.err.message : 'Order Failed';

      return newState;
    }

    default: {
      return state;
    }
  }
}
