import React from 'react';
import {
  Container, Col, Row,
} from 'reactstrap';

class Marketing extends React.Component {
  render() {
    return (
      <div className="marketing-section hero-marketing mt-2">
        <Container>
          <Row className=" px-3 px-md-0 justify-content-center flex-sm-column align-items-sm-center flex-lg-row">
            <Col sm={10} md={8} lg={5} >
              <h2 className="pb-2"> Time to spread the word! </h2>
              <p className=""> Equipped with the talking points above, you’re ready to help anyone
                search and browse protected </p>

              <h5 > <a href=""> <span> <i className="fa fa-arrow-right" /> </span> Search privately with DuckDuckGo.  </a> </h5>
              <h5> <a href=""> <span> <i className="fa fa-arrow-right" /> </span> Browse safely with the extension and app. </a></h5>
              <h5> <a href=""> <span> <i className="fa fa-arrow-right" /> </span> Get there faster with duck.com. </a></h5>

            </Col>
            <Col sm={10} md={8} lg={5} className=" ">
              <img src="../../assets/high-five.svg"/>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}


export default Marketing;
