import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalBody
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EditResultForm from "./EditResultForm";
import * as searchActions from '../../actions/searchActions';

class EditResultModal extends React.Component {
  componentDidMount() {
    const { query } = this.props;
    const { searchResults } = this.props;
    const resultData = searchResults[query];

    if (!resultData || (!resultData.loading && !resultData.loaded)) {
      this.props.actions.getSearchResultStarting(query);
      this.props.actions.getSearchResult(query);
    }
  }

  render() {
    const { query, defaultResultType, toggleModal } = this.props;
    const { searchResults } = this.props;
    const resultData = searchResults[query];

    let content;

    if (!resultData || !resultData.loaded || !resultData.result) {
      content = (
        <div>
          <i className="fa fa-spinner fa-spin" />
        </div>
      );
    } else {
      content = (
        <EditResultForm
          isInsideModal={true}
          query={query}
          defaultResultType={defaultResultType}
          resultData={resultData}
          toggleModal={toggleModal}
        />
      );
    }

    return (
      <Modal isOpen={true} toggle={toggleModal} className="edit-result-modal">
        <ModalBody>
          {content}
        </ModalBody>
      </Modal>
    );
  }
}

EditResultModal.defaultProps = {
  defaultResultType: '',
};

EditResultModal.propTypes = {
  defaultResultType: PropTypes.string,
  query: PropTypes.string.isRequired,
  searchResults: PropTypes.object,
  toggleModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state => ({
  searchResults: state.searchResults,
}));

const mapDispatchToProps = (dispatch => ({
  actions: bindActionCreators(
    Object.assign({}, searchActions),
    dispatch,
  ),
}));

export default connect(mapStateToProps, mapDispatchToProps)(EditResultModal);
