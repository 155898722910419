import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Card, CardBody, CardHeader, CardFooter, Row, Col} from "reactstrap";
import ResultUpdatedDate from "../ResultUpdatedDate";
import DisplaySpecs from "./DisplaySpecs";


class ProductSpecs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {resultData, query} = this.props;
    const {result} = resultData;
    const productSpecs = [
        {
          imgPath:"/../assets/samsung.png",

          network: {
            technology: "GSM / HSPA /LTE",
            twoGbands: "2G GSM 850/900/1800/1900",
            threeGbands: "HSDPA 850/900/1700(AWS)/1900/2100",
            fourGbands: "1, 2, 3, 4, 5, 7, 8, 12, 17, 20, 28, 38, 40, 66",
            speed: "",
          },

          launch: {
            announced: "2019, February 15",
            status: "Available. Released 2019, April",
          },

          body: {
            dimensions: "245.2 x 149.4 x 7.5 mm (9.65 x 5.88 x 0.30 in)",
            weight: "469 g (1.03 lb)",
            build: "Glass front, aluminum back, aluminum frame",
            sim: "Nano-SIM",
          },

          display: {
            type: "TFT",
            size: "469 g (1.03 lb)",
            resolution: "10.1 inches, 295.8 cm2 (~80.7% screen-to-body ratio) " +
              "           1200 x 1920 pixels, 16:10 ratio (~224 ppi density)",

          }
        }
      ];

    return (
      <div className="product-specifications">
        <Card>
          <CardHeader>
            <Row>
              <Col>
                {query}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>

            <DisplaySpecs
              query={query}
              resultData={resultData}
            />
            </CardBody>
          <CardFooter>
            <ResultUpdatedDate updatedAt={result.updatedAt}/>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

ProductSpecs.defaultProps = {
  actions: {},
};

ProductSpecs.propTypes = {
  actions: PropTypes.object,

};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSpecs);

