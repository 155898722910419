import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getFileUrl} from "../../../utils/general";

// import * as actionsActions from '../actions/actions';

class FileDownloadLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

  }

  render() {

    const {file} = this.props;
    const downloadLink = getFileUrl(file);

    return (
      <div className="file-download-link">
        <a color="primary" className="btn btn-light btn-sm border-primary" href={downloadLink}
           target="_blank">
          <i className="fa fa-download"/>
          &nbsp;
          Download
        </a>
      </div>

    );
  }
}

FileDownloadLink.defaultProps = {
  actions: {},
};

FileDownloadLink.propTypes = {
  actions: PropTypes.object,
  fileUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileDownloadLink);
