import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col,
  Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import * as actionsActions from '../actions/actions';

class NewsPost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { headline, description, imgPath } = this.props;
    return (
      <div className="news-post">
        <Card className="mb-2">
          <CardBody>
            <Row>
              <Col xs={9}>
                <a href={""}><h6>  {headline} </h6></a>
                <p className=""> {description}</p>
              </Col>
              <Col xs={3}>
                <img src={imgPath} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

NewsPost.defaultProps = {
  actions: {},
  channel: '',
  headline: '',
  description: '',
  time: '',
};

NewsPost.propTypes = {
  actions: PropTypes.object,
  channel: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
  time: PropTypes.string,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsPost);
