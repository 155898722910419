import React from 'react';
import {Button, CardBody, Col, Row} from 'reactstrap';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ImagesPreviews from "../Common/ImagesPreviews";

class DisplaySpecs extends React.Component {
  render() {
   // const specsData = this.props.specs;

    const {resultData, query} = this.props;
    const {result} = resultData;
    let {productDescription} = result;

    if (productDescription.length > 0) {
      productDescription = (<p className="mt-0 mb-2"> Description :</p>) &&
        (<div
          className="mt-3 mb-0 des"
          dangerouslySetInnerHTML={{__html: productDescription}}
        />)
    }
    let btnShow;
    if (result.productQtyInStock >= 1) {
      btnShow = (
        <Button type="button" color="primary">
          Add to Cart
        </Button>)

    } else if (result.productQtyInStock <= 0) {
      btnShow = (
        <Button type="button" color="danger">
          Out of Stock
        </Button>)
    }
    let currency;

    switch (result.productCurrency) {
      case 'inr': {
        currency = "₹"
        break;
      }

      case 'usd': {
        currency = "$"
        break;
      }

      case 'eur': {
        currency = "€"
        break;
      }

      case '':{
        break;
      }
    }

    return (
      <div className="product-result ">
        <Row>
          <Col xs={12} sm={12} md={4}>

            <ImagesPreviews
              query={query}
              resultData={resultData}/>

          </Col>

          <Col xs={12} sm={12} md={7}>
            <div>
              <h6> {result.productTitle}</h6>

              <a href={null} className=" txt-small pointer link"> {currency} {result.productBrand}</a>
              <div>
                <i className="fa fa-star" aria-hidden="true"/>
                <i className="fa fa-star" aria-hidden="true"/>
                <i className="fa fa-star" aria-hidden="true"/>
                <i className="fa fa-star" aria-hidden="true"/>
                <i className="fa fa-star-half-o" aria-hidden="true"/> &nbsp;
                <small> 3,888 ratings</small>

                <div className="mt-2 mb-2 divider"/>

                <p><span className="txt-bold"> {result.productSellingPrice} </span>
                  <span className=" ml-1 txt-strike text-muted txt-small"> {result.productMsrp} </span>
                </p>

                {btnShow}
                <div className="mt-3 mb-0 divider"/>
                {productDescription}
                <div className="mt-3 mb-0 divider"/>
                {result.productSpecifications}
              </div>
            </div>

          </Col>
        </Row>
      </div>
    );
  }
}

DisplaySpecs.defaultProps = {
  actions: {},
};

DisplaySpecs.propTypes = {
  actions: PropTypes.object,
  specs: PropTypes.object.isRequired,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplaySpecs);

