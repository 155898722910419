import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import ResultTypeUnderDevelopment from "./Common/ResultTypeUnderDevelopment";
// import * as actionsActions from '../actions/actions';
import ProductSpecs from "./ProductResult/ProductSpecs";

class ProductResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {query, resultData} = this.props;

    return (
      <Row className="product-result">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ResultTypeUnderDevelopment/>

          <ProductSpecs
            query={query}
            resultData={resultData}/>
        </Col>
      </Row>
    );
  }
}

ProductResult.defaultProps = {
  actions: {},
};

ProductResult.propTypes = {
  actions: PropTypes.object,

};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductResult);
