import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Col, FormGroup, Input
} from 'reactstrap';

class AddPostModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      post:'',
    };

    this.onPostChanged = this.onPostChanged.bind(this);
    this.onSaveClicked = this.onSaveClicked.bind(this);
  }

  onPostChanged(event) {
    this.setState({
      post: event.target.value,
    });
  }

  onSaveClicked() {
    const {post} = this.state;
    const output = `Post : ${post}`;

    alert(output);

  }

  render() {
    const {post} = this.state;
    const {toggleModal} = this.props;


    return (
      <Modal isOpen={true} toggle={toggleModal} className="add-post-modal">
        <ModalHeader toggle={toggleModal}>Add Post </ModalHeader>
        <ModalBody>
          <div>
            <Form>
              <Row>
                <Col xs="1" className="">
                  <img src="../assets/drbiden.jpg" style={{width: "40px", borderRadius: "50%"}}/>
                </Col>

                <Col sm="11">
                  <FormGroup className="">
                    <Input
                      type="textarea"
                      name="post"
                      id="post"
                      placeholder=""
                      value={post}
                      onChange={this.onPostChanged}
                    />
                  </FormGroup>
                </Col>
             </Row>
            </Form>
          </div>
        </ModalBody>
        <ModalFooter>

          <Button color="primary" onClick={this.onSaveClicked}>Save</Button>
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

AddPostModal.defaultProps = {};

AddPostModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
};

export default AddPostModal;
