import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NewsPost from "./NewsPost";
import m1 from "../../../assets/m1.png";
import m2 from "../../../assets/m2.png";
import m3 from "../../../assets/m3.png";


class News extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="news-container">

        <NewsPost
          headline={"Will never forget the first time I looked in his eyes: Why Shaaz Jung, " +
          "the man behind viral black panther photo, will always remember Saya "}
          description=" As kids, we have all read the story of Mowgli the boy or 'man-cub' who lived in the jungle with his friends —  Akela, a wolf, Bagheera, a black panther, and Baloo, a bear, courtesy The Jungle Book. But until a few days ago we couldn't have thought in our wildest dreams
          that the photos of a real-life Bagheera will eventually fill all our social media timelines.n
           "
          imgPath={m1}
          />
        <NewsPost

          headline={"DD Brings Back ‘The Jungle Book’ on TV: Check Time And Schedule "}
          description="The classic animation series that aired from October 1989, occupied TV screens for decades and became a favourite for many kids across the world. The Jungle Book is based on Rudyard Kipling’s novel about a human child who was brought up in the jungle by wild animals. "
          imgPath={m2}
         />
        <NewsPost
          headline={"Will never forget the first time I looked in his eyes: Why Shaaz Jung, " +
          "the man behind viral black panther photo, will always remember Saya "}
          description=" As kids, we have all read the story of Mowgli the boy or 'man-cub' who lived in the jungle with his friends —  Akela, a wolf, Bagheera, a black panther, and Baloo, a bear, courtesy The Jungle Book. But until a few days ago we couldn't have thought in our wildest dreams
          that the photos of a real-life Bagheera will eventually fill all our social media timelines.n
           "
          imgPath={m3}
        />
      </div>
    );
  }
}

News.defaultProps = {
  actions: {},
};

News.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(News);
