import { cloneDeep } from 'lodash';
import {
  META_SEARCH_ADD_VIDEO_EMBED_STARTING,
  META_SEARCH_ADD_VIDEO_EMBED_SUCCESS,
  META_SEARCH_ADD_VIDEO_EMBED_ERROR,

} from '../constants/actionTypes';
import initialState from './initialState';

export default function metaSearchAddVideoEmbedReducer(state = initialState.metaSearchAddVideoEmbed, action) {
  switch (action.type) {
    case META_SEARCH_ADD_VIDEO_EMBED_STARTING: {
      const { query } = action;
      const newState = cloneDeep(state);

      if (!newState[query]) {
        newState[query] = cloneDeep(initialState.metaSearchAddVideoEmbed.query);
      }

      const addVideoEmbedData = newState[query];
      addVideoEmbedData.saving = true;
      addVideoEmbedData.saved = false;
      addVideoEmbedData.error = null;
      return newState;
    }

    case META_SEARCH_ADD_VIDEO_EMBED_SUCCESS: {
      const { query } = action;
      const newState = cloneDeep(state);

      if (!newState[query]) {
        newState[query] = cloneDeep(initialState.metaSearchAddVideoEmbed.query);
      }

      const addVideoEmbedData = newState[query];
      addVideoEmbedData.saving = false;
      addVideoEmbedData.saved = true;
      return newState;
    }

    case META_SEARCH_ADD_VIDEO_EMBED_ERROR: {
      const { query } = action;
      const newState = cloneDeep(state);

      if (!newState[query]) {
        newState[query] = cloneDeep(initialState.metaSearchAddVideoEmbed.query);
      }

      const addVideoEmbedData = newState[query];
      addVideoEmbedData.saving = false;
      addVideoEmbedData.saved = false;
      addVideoEmbedData.error = action.err ? action.err.message : 'Add Video Embed failed';
      return newState;
    }

    default: {
      return state;
    }
  }
}
