import React from 'react';
import { Col, Row, Container} from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <div className="hero-footer">
        <Container>
          <div className="header">

                <Row className=" justify-content-center pb-3">
                  <Col xs={12} md={4} lg={3} className="pb-4">
                    <h5> DuckDuckGo </h5>
                    <p> About </p>
                    <p> App </p>
                    <p> Traffic </p>
                    <p> Privacy </p>
                    <p> Press </p>
                  </Col>
                  <Col xs={12} md={4} lg={3}className="pb-4">
                    <h5> Follow Us </h5>
                    <p> Blog</p>
                    <p> Twitter</p>
                    <p> Reddit</p>
                  </Col>
                  <Col xs={12} md={4} lg={3} >
                    <h5> About Us </h5>
                    <p className="pb-2"> DuckDuckGo is an Internet privacy company that empowers you to
                      seamlessly take control of your personal information online, without any
                      tradeoffs. With our roots as the search engine that doesn’t track you,
                      we’ve expanded what we do to protect you no matter where the Internet takes you.
                    </p>

                    <a className="" href=""> Learn more about story &nbsp;
                      <i className="fa fa-chevron-circle-right"/> </a>
                  </Col>

                </Row>

            <div className="footer text-center ">
              © DuckDuckGo. • Privacy, simplified.
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default Footer;
