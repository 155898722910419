import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import * as actionsActions from '../actions/actions';

class CalculatorDocumentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <Card className="mt-0 border-info bg-light">
         <CardBody>
           <p>
             This is a text expression calculator. Use the textbox above
             to type your expression. Some examples are given below.
           </p>

            <table className="table table-bordered table-striped mt-3">
              <thead>
              <tr>
                <th>Expression</th>
                <th>Evaluates to</th>
              </tr>
              </thead>

              <tbody>
              <tr>
                <td><code>1.2 * (2 + 4.5)</code></td>
                <td><code>7.8</code></td>
              </tr>

              <tr>
                <td><code>12.7 cm to inch</code></td>
                <td><code>5 inch</code></td>
              </tr>

              <tr>
                <td><code>sin(45 deg) ^ 2</code></td>
                <td><code>0.5</code></td>
              </tr>

              <tr>
                <td><code>9 / 3 + 2i</code></td>
                <td><code>3 + 2i</code></td>
              </tr>

              <tr>
                <td><code>det([-1, 2; 3, 1])</code></td>
                <td><code>-7</code></td>
              </tr>

              </tbody>
            </table>
          </CardBody>
        </Card>
      </div>
    );
  }
}

CalculatorDocumentation.defaultProps = {
  actions: {},
};

CalculatorDocumentation.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorDocumentation);
