import { cloneDeep } from 'lodash';
import {
  GET_DICT_WORDS_IN_PREFIX_STARTING,
  GET_DICT_WORDS_IN_PREFIX_SUCCESS,
  GET_DICT_WORDS_IN_PREFIX_ERROR,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function dictionaryWordsInPrefixReducer(state = initialState.dictionaryWordsInPrefix, action) {
  switch (action.type) {
    case GET_DICT_WORDS_IN_PREFIX_STARTING: {
      const { prefixStr, searchQuery } = action;
      const newState = cloneDeep(state);

      if (!newState[prefixStr]) {
        newState[prefixStr] = cloneDeep(initialState.dictionaryWordsInPrefix.prefixStr);
      }

      const wordsData = newState[prefixStr];
      wordsData.loading = true;
      wordsData.loaded = false;
      wordsData.error = null;
      wordsData.searchQuery = searchQuery;

      return newState;
    }

    case GET_DICT_WORDS_IN_PREFIX_SUCCESS: {
      const { prefixStr, searchQuery, data } = action;
      const newState = cloneDeep(state);

      if (!newState[prefixStr]) {
        newState[prefixStr] = cloneDeep(initialState.dictionaryWordsInPrefix.prefixStr);
      }

      if (newState[prefixStr].searchQuery !== searchQuery) {
        return newState;
      }

      const { words } = data;

      const ids = [];
      const map = {};

      words.forEach((word) => {
        ids.push(word._id);
        map[word._id] = word;
      });

      const wordsData = newState[prefixStr];
      wordsData.loading = false;
      wordsData.loaded = true;
      wordsData.ids = ids;
      wordsData.map = map;
      wordsData.matchCount = data.matchCount;

      return newState;
    }

    case GET_DICT_WORDS_IN_PREFIX_ERROR: {
      const { prefixStr, searchQuery } = action;
      const newState = cloneDeep(state);

      if (!newState[prefixStr]) {
        newState[prefixStr] = cloneDeep(initialState.dictionaryWordsInPrefix.prefixStr);
      }

      if (newState[prefixStr].searchQuery !== searchQuery) {
        return newState;
      }

      const wordsData = newState[prefixStr];
      wordsData.loading = false;
      wordsData.loaded = false;
      wordsData.error = action.err ? action.err.message : 'Failed to get words in prefix';
      return newState;
    }

    default: {
      return state;
    }
  }
}
