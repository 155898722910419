import React from 'react';

import {
  Col, Row,
} from 'reactstrap';

// import * as actionsActions from '../actions/actions';

class TwoPhotoView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};


  }

  render() {
    const {media} = this.props;

    return (
      <div className="text-center two-photoview-container ">
        <Row noGutters>
          <Col xs={6} >
           <img src={media[0]} />
          </Col>
          <Col xs={6} >
            <img src={media[1]} /></Col>
        </Row>
      </div>
    );
  }
}


export default TwoPhotoView;

