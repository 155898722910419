import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ProductDescription from "./ProductDescription";
import {Button, Card, CardBody, CardHeader, CardFooter} from "reactstrap";
import {setDocumentTitle} from "../../utils/general";
import *  as cartActions from '../../actions/cartActions'
import Noty from "noty";

class ProductData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onPlaceOrderClicked = this.onPlaceOrderClicked.bind(this);

    this.placeOrderStarted = false;
  }

  componentDidMount() {
    setDocumentTitle('Shopping Cart Page');
  }

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    if (
      this.placeOrderStarted &&
      this.props.placeOrder !== nextProps.placeOrder
    ) {
      this.handleStatusChange(nextProps);
    }
  }

  handleStatusChange(props) {
    if (
      !props.placeOrder.saving && props.placeOrder.saved
    ) {
      new Noty({
        type: 'success',
        layout: 'bottomLeft',
        text: 'Order placed successfully.',
      }).show();

      this.placeOrderStarted = false;
    } else if (
      !props.placeOrder.saving && !props.placeOrder.saved
    ) {
      let errorMessage = props.placeOrder.error;
      if (!errorMessage) {
        errorMessage = 'Failed to place Order due to server error.';
      }

      new Noty({
        type: 'error',
        layout: 'bottomLeft',
        text: errorMessage,
      }).show();

      this.placeOrderStarted = false;
    }
  }

  onPlaceOrderClicked() {
    this.props.actions.placeOrderStarting();
    this.props.actions.placeOrder(this.state);
    this.placeOrderStarted = true;
  }

  render() {

    const { placeOrder } = this.props;
    const orderData = placeOrder;
    const products = [
      {
        _id: "1",
        title: "Canon EOS 3000D DSLR Camera Single Kit with 18-5...",
        features: "Black",
        seller: "Seller : CORSECA",
        discount: "32% off",
        discountedPrice: "₹ 19,999",
        msrp: " ₹ 29,495",
        imgPath: "../../assets/camera.png",
      },

      {
        _id: "2",
        title: "Duroflex Duropedic Back Magic 5inch Single Coir Mattress",
        seller: "Seller : OMNITechRetail",
        discount: "9% off",
        discountedPrice: "₹ 8,999",
        msrp: " ₹ 9,929",
        imgPath: "../../assets/bed.png",
      },
      {
        _id: "3",
        title: "MSI GF75 Thin Core i7 9th Gen (16GB /1 TBB HDD/",
        features: "17.3 inch, Black, 2.2 kg",
        seller: "Seller : RetailNet",
        discount: "25% off",
        discountedPrice: "₹ 71,990",
        msrp: " ₹ 95,990",
        imgPath: "../../assets/laptop.png",
      },
    ];

    let btnPlaceOrder;
    if (orderData && orderData.saving) {
      btnPlaceOrder = (
        <Button color="success" disabled>
          <i className="fa fa-spinner fa-spin"/>
          &nbsp;
          Please wait...
        </Button>
      );
    } else {
      btnPlaceOrder = (
        <Button type="button" color="primary" onClick={this.onPlaceOrderClicked}>
          PLACE ORDER
        </Button>
      );
    }

    return (
      <div className="products-cart">
        <Card >
          <CardHeader>
            <h6> My Cart (3) </h6>
          </CardHeader>
          <CardBody>

            <ProductDescription
              product={products[0]}
            />
            <ProductDescription
              product={products[1]}
            />
            <ProductDescription
              product={products[2]}
            />

          </CardBody>
          <CardFooter>
            <div className="text-lg-right">
              {btnPlaceOrder}
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

ProductData.defaultProps = {
  actions: {},
  placeOrder:{},
};

ProductData.propTypes = {
  actions: PropTypes.object,
  placeOrder: PropTypes.object,
};

const mapStateToProps = state => ({
  placeOrder: state.placeOrder,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, cartActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductData);

