import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import QuotePost from "./QuoteWidget/QuotePost";
// import * as actionsActions from '../actions/actions';

class QuoteWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="dashboard-widget quote-container">
               <QuotePost
                quote="“Be yourself; everyone else is already taken.”
                        ― Oscar Wilde " />
      </div>
    );
  }
}

QuoteWidget.defaultProps = {
  actions: {},
};

QuoteWidget.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */)  => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuoteWidget);
