import React from 'react';
import ReactPlayer from 'react-player/lazy';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardFooter, CardHeader, Col,
  Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SearchCount from "../../Common/SearchCount";
import ResultUpdatedDate from "../ResultUpdatedDate";
import AddResultTypeDropdown from "../MetaSearchResult/AddResultTypeDropdown";

// import * as actionsActions frome '../actions/actions';

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: true,
    };

    this.onEnded = this.onEnded.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onPlay = this.onPlay.bind(this);
  }

  onEnded() {
    this.setState({
      playing: false,
    });
  }

  onPause() {
    this.setState({
      playing: false,
    });
  }

  onPlay() {
    this.setState({
      playing: true,
    });
  }

  render() {
    const { query, resultData } = this.props;
    const { videoUrl, searchCount } = this.props.resultData.result;
    const { playing } = this.state;
    const {result} = resultData;

    return (
      <div>
        <Card>
          <CardHeader>
            <Row>
              <Col>
                {query}
              </Col>
              <Col className='text-right'>
                <SearchCount searchCount={searchCount} />
              </Col>
              <Col xs="auto" className="d-none d-md-block">
                <div className="d-inline-block  ml-3">
                  <AddResultTypeDropdown
                    query={query}
                    resultData={resultData}
                  />
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="">
            <ReactPlayer
              className="react-player"
              controls={true}
              playing={playing}
              loop={true}
              pip={false}
              stopOnUnmount={true}
              url={videoUrl}
              onPause={this.onPause}
              onPlay={this.onPlay}
              onEnded={this.onEnded}
            />
          </CardBody>
          <CardFooter>
            <ResultUpdatedDate updatedAt ={result.updatedAt}/>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

VideoPlayer.defaultProps = {
  actions: {},
};

VideoPlayer.propTypes = {
  actions: PropTypes.object,
  resultData: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired,
};

const mapStateToProps = (/*state*/) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
