import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SearchForm from './SearchForm';
import { history } from '../../store/configureStore';
// import * as actionsActions from '../actions/actions';

class CommonHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.goToLoginPage = this.goToLoginPage.bind(this);
  }

  goToLoginPage() {
    history.push('/sign-in');
  }

  getHeaderStyle() {
    const headerStyle = {};

    if (!this.props.appConfig.loaded) {
      return headerStyle;
    }

    const { data } = this.props.appConfig;

    if (data.appearance && data.appearance.backgroundColor) {
      headerStyle.backgroundColor = data.appearance.backgroundColor;
    }

    return headerStyle;
  }

  render() {
    const { currentUser, defaultQuery } = this.props;

    const headerStyle = this.getHeaderStyle();


    let rightContent = null;

    if (currentUser.loading || currentUser.loginInProgress) {
      rightContent = (
        <Col xs={12} md={4} className="d-none d-md-block text-right pt-2">
          <p>Signing you in...</p>
        </Col>
      )
    } else if (!currentUser.loginInProgress && !currentUser.loggedIn) {
      rightContent = (
        <Col xs={12} md={4} className="d-none d-md-block text-right pt-2">
          <Button color="info" onClick={this.goToLoginPage}>
            <i className="fa fa-sign-in" /> &nbsp;Sign In
          </Button>
        </Col>
      );
    }

    return (
      <div className="search-form-container" style={headerStyle}>
        <Container>
          <Row>
            <Col xs={12} md={8}>
              <SearchForm
                defaultQuery={defaultQuery}
                autoFocus={false}
                showPrivacy={false}
              />
            </Col>

            {/*{rightContent}*/}
          </Row>
        </Container>
      </div>
    );
  }
}

CommonHeader.defaultProps = {
  actions: {},
  appConfig: {},
  defaultQuery: '',
  currentUser: {},
};

CommonHeader.propTypes = {
  actions: PropTypes.object,
  appConfig: PropTypes.object,
  defaultQuery: PropTypes.string,
  currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
  appConfig: state.appConfig,
  currentUser: state.currentUser,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonHeader);
