import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NewsPost from "./NewsPost";
import hyd6 from "../../../assets/hyd6.png";
import hyd7 from "../../../assets/hyd7.png";
import hyd3 from "../../../assets/hyd3.png";



class News extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="news-container">

        <NewsPost
          channel="The New Indian Express"
          headline={"Telangana reports 894 fresh Covid-19 cases "}
          description=" Telangana reported 894 fresh Covid-19 cases, taking the overall tally of
           postive cases in the state to 92,255 even as the Greater Hyderabad Municipal Corporation
           "
          imgPath={hyd6}
          time=" 3 hours ago" />
        <NewsPost
          channel=" ABP Live"
          headline={"RIP! Nishikant Kamat who directed Ajay Devgn's Drishyam Dies in Hyderabad "}
          description="Earlier, 50 year old Nishikant Kamat was admitted in AIG hospitals, Gachibowli, Hyderabad
          on 31st July with jaundice and abdomnial... "
          imgPath={hyd7}
          time="2 days ago" />
        <NewsPost
          channel="Indian Express"
          headline={"Tree branches used as IV stands at Osmania Hospital, inquiry launched"}
          description="Several videos of tree branches or wooden sticks being used as stands for intravenous  "
          imgPath={hyd3}
          time="13 hours ago" />
      </div>
    );
  }
}

News.defaultProps = {
  actions: {},
};

News.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps =(/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(News);
