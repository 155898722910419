import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class FilePreviewAudio extends React.Component {

  render() {
    const {fileUrl, file} = this.props;

    return (
      <div className="file-preview-audio">
        <Row>
          <Col xs={12} md={6} lg={4}>
            <audio controls preload="auto">
              <source src={fileUrl} type="audio/mpeg"/>
            </audio>
          </Col>
        </Row>
      </div>
    );
  }
}

FilePreviewAudio.defaultProps = {
  actions: {},
};

FilePreviewAudio.propTypes = {
  actions: PropTypes.object,
  fileUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilePreviewAudio);
