import React from 'react';
import {
 Col, Row,
} from 'reactstrap';
import PlaceDetails from "./PlaceResult/PlaceDetails";
import ResultTypeUnderDevelopment from "./Common/ResultTypeUnderDevelopment";
import PersonDetails from "./PersonResult/PersonDetails";
import PropTypes from "prop-types";
import PersonResult from "./PersonResult";

// import * as actionsActions from '../actions/actions';

class PlaceResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { query, resultData } = this.props;
    return (
      <Row className="place-result">
        <Col xs={12} sm={12} md={12} lg={9} xl={10}>
          <ResultTypeUnderDevelopment />

          <PlaceDetails
            query={query}
            resultData={resultData}

          />
        </Col>
      </Row>
    );
  }
}


PlaceResult.propTypes = {
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};


export default PlaceResult;

