import { cloneDeep } from 'lodash';
import {
  UPDATE_FILE_STARTING,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_ERROR,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function updateFileReducer(state = initialState.updateFile, action) {
  switch (action.type) {
    case UPDATE_FILE_STARTING: {
      const { fileId } = action;
      const newState = cloneDeep(state);

      if (!newState[fileId]) {
        newState[fileId] = cloneDeep(initialState.updateFile.fileId);
      }

      const updateData = newState[fileId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;
      return newState;
    }

    case UPDATE_FILE_SUCCESS: {
      const { fileId } = action;
      const newState = cloneDeep(state);

      if (!newState[fileId]) {
        newState[fileId] = cloneDeep(initialState.updateFile.fileId);
      }

      const updateData = newState[fileId];
      updateData.saving = false;
      updateData.saved = true;
      return newState;
    }

    case UPDATE_FILE_ERROR: {
      const { fileId } = action;
      const newState = cloneDeep(state);

      if (!newState[fileId]) {
        newState[fileId] = cloneDeep(initialState.updateFile.fileId);
      }

      const updateData = newState[fileId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err ? action.err.message : 'Failed to update File';
      return newState;
    }

    default: {
      return state;
    }
  }
}
