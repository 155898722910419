import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Card, CardBody,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class ActivityItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {

    const activity = this.props.activity;
    return (

      <div className='mb-4'>
        <Row>
          <Col xs={12} md={3}>
            <img src={activity.imgPath} className="mt-1 img-responsive" alt="Card image cap"/>
          </Col>
          <Col>
            {activity.description}
            <p className="text-muted txt-small mt-0 mb-0"> {activity.time}</p>
          </Col>
        </Row>
        <div className="divider mt-2 mb-2 " />
      </div>

    );
  }
}

ActivityItem.defaultProps = {
  actions: {},
};

ActivityItem.propTypes = {
  actions: PropTypes.object,
  activity: PropTypes.object,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityItem);
