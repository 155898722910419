import { cloneDeep } from 'lodash';
import {
  META_SEARCH_REMOVE_STARTING,
  META_SEARCH_REMOVE_SUCCESS,
  META_SEARCH_REMOVE_ERROR,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function metaSearchRemoveReducer(state = initialState.metaSearchRemove, action) {
  switch (action.type) {
    case META_SEARCH_REMOVE_STARTING: {
      const { query } = action;
      const newState = cloneDeep(state);

      if (!newState[query]) {
        newState[query] = cloneDeep(initialState.metaSearchRemove.query);
      }

      const removeData = newState[query];
      removeData.saving = true;
      removeData.saved = false;
      removeData.error = null;
      return newState;
    }

    case META_SEARCH_REMOVE_SUCCESS: {
      const { query } = action;
      const newState = cloneDeep(state);

      if (!newState[query]) {
        newState[query] = cloneDeep(initialState.metaSearchRemove.query);
      }

      const removeData = newState[query];
      removeData.saving = false;
      removeData.saved = true;
      return newState;
    }

    case META_SEARCH_REMOVE_ERROR: {
      const { query } = action;
      const newState = cloneDeep(state);

      if (!newState[query]) {
        newState[query] = cloneDeep(initialState.metaSearchRemove.query);
      }

      const removeData = newState[query];
      removeData.saving = false;
      removeData.saved = false;
      removeData.error = action.err ? action.err.message : 'Remove failed';
      return newState;
    }

    default: {
      return state;
    }
  }
}
