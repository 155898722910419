import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalBody, Row, Col, ModalFooter,
} from 'reactstrap';
import {getFileUrl} from '../../../utils/general';


class PhotoViewModal extends React.Component {
  render() {
    const {selectedFile, toggleModal } = this.props;

    const imageUrl = getFileUrl(selectedFile);
    return (
      <Modal isOpen={true} toggle={toggleModal} className="photo-result-modal">
        <ModalBody>

          <Row>
            <Col xs={12} lg={8}>
              <img src={imageUrl} alt="File picture"/>
            </Col>
            <Col xs={12} lg={4}>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
              pariatur.
              Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum."
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <p className="text-right"> #photo </p>
        </ModalFooter>
      </Modal>
    );
  }
}

PhotoViewModal.defaultProps = {
  actions: {},
  isInsideModal: false,
};

PhotoViewModal.propTypes = {
  selectedFile: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  isInsideModal: PropTypes.bool,
};

export default PhotoViewModal;
