import { cloneDeep } from 'lodash';
import {
  SAVE_USER_PROFILE_STARTING,
  SAVE_USER_PROFILE_SUCCESS,
  SAVE_USER_PROFILE_ERROR,
} from '../constants/actionTypes';
import initialState from './initialState';


export default function saveUserProfileReducer(state = initialState.saveUserProfile, action) {
  switch (action.type) {
    case SAVE_USER_PROFILE_STARTING: {
      const newState = cloneDeep(state);
      newState.saving = true;
      newState.saved = false;
      return newState;
    }

    case SAVE_USER_PROFILE_SUCCESS: {
      const newState = cloneDeep(state);
      newState.saving = false;
      newState.saved = true;
      newState.error = null;

      return newState;
    }

    case SAVE_USER_PROFILE_ERROR: {
      const newState = cloneDeep(state);
      newState.saving = false;
      newState.saved = false;
      newState.error = action.err ? action.err.message : 'Saving User details Failed';

      return newState;
    }
    default: {
      return state;
    }
  }
}
