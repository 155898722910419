import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class FileManagerDataRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {fileData, fileLink, fileId, index} = this.props;

    return (
      <tr key={fileId}>
        <th scope="row" className="text-center">{index + 1}</th>
        <td>

          {fileData.originalFileName}
          <div className="d-block d-md-none">
            <p>Type: {fileData.fileType}</p>
            <p>Updated Date:{fileData.createdAt}</p>
            <p><a className="btn btn-sm btn-primary" href={fileLink} target="_blank">
              <i className="fa fa-download"/>
            </a>

              <Button size="sm" color="info" className="ml-2" onClick={() => {
                this.toggleEditFileModal(fileData)
              }}>
                <i className="fa fa-edit"/>
              </Button>

              <Button size="sm" color="danger" className="ml-2" onClick={() => {
                this.toggleDeleteFileModal(fileData);
              }}>
                <i className="fa fa-times"/>
              </Button></p>
          </div>

        </td>

        <td className="text-center d-none d-md-table-cell">
          {fileData.fileType}
        </td>
        <td className="text-center d-none d-md-table-cell">
          {fileData.createdAt}
        </td>
        <td className="text-center d-none d-md-table-cell">

          <a className="btn btn-sm btn-primary" href={fileLink} target="_blank">
            <i className="fa fa-download"/>
          </a>

          <Button size="sm" color="info" className="ml-2" onClick={() => {
            this.toggleEditFileModal(fileData)
          }}>
            <i className="fa fa-edit"/>
          </Button>

          <Button size="sm" color="danger" className="ml-2" onClick={() => {
            this.toggleDeleteFileModal(fileData);
          }}>
            <i className="fa fa-times"/>
          </Button>

        </td>

      </tr>


    );
  }
}

FileManagerDataRow.defaultProps = {
  actions: {},
};

FileManagerDataRow.propTypes = {
  actions: PropTypes.object,
  fileId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  fileData: PropTypes.object.isRequired,
  fileLink: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileManagerDataRow);
