import React from 'react';

import {
   Col, Row,
} from 'reactstrap';
import {connect} from 'react-redux';


// import * as actionsActions from '../actions/actions';

class FourPhotoView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

  }

  render() {
    const {media} = this.props;

    return (
      <div className="four-photoview-container text-center ">
        <Row noGutters>
          <Col xs={6}>
            <img src={media[0]} />
          </Col>
          <Col xs={6} >
            <img src={media[1]}/>
          </Col>
        </Row>
        <Row noGutters>
          <Col xs={6}>
            <img src={media[2]}/>
          </Col>
          <Col xs={6}>
            <img src={media[3]}/>
          </Col>
        </Row>
      </div>
    );
  }
}

FourPhotoView.defaultProps = {};


FourPhotoView.propTypes = {};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FourPhotoView);

