import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardHeader, CardFooter, Col,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class PriceDetails extends React.Component {
  render() {
    return (
      <div className="mt-0">
        <Card style={{width: '25em'}} className="price-details">
          <CardHeader >
            <h6> Price Details </h6>
          </CardHeader>

           <CardBody>
             <Row>
               <Col xs={8} >
                <p> Price (3 items) </p>
               </Col>
               <Col xs={4} >
                <p> ₹  1,00,988</p>
               </Col>
             </Row>

             <Row>
               <Col xs={8} >
                 <p> Delivery Charges </p>
               </Col>
               <Col xs={4} className="txt-green" >
                 <p> FREE </p>
               </Col>
             </Row>
             <div className="divider mt-2 mb-2 " />
             <Row>
               <Col xs={8} >
                 <p> Total Amount </p>
               </Col>
               <Col xs={4} >
                 <p> ₹  1,00,988</p>
               </Col>
             </Row>
             <div className="divider mt-2 mb-2 " />
             <Row>
               <Col>
                 <p className="txt-green"> You will save ₹  34,426 on this order </p>
               </Col>

             </Row>
          </CardBody>
          <CardFooter>

          </CardFooter>
        </Card>
      </div>
    );
  }
}

PriceDetails.defaultProps = {
  actions: {},
};

PriceDetails.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceDetails);
