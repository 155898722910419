import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Noty from 'noty';
import * as usersActions from '../../actions/usersActions';

class DeleteUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onConfirmClicked = this.onConfirmClicked.bind(this);

    this.deleteStarted = false;
  }

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    const userId = this.props.selectedUser._id;

    if (
      this.deleteStarted &&
      this.props.deleteUser !== nextProps.deleteUser &&
      nextProps.deleteUser[userId]
    ) {
      this.handleStatusChange(nextProps);
    }
  }

  handleStatusChange(props) {
    const userId = props.selectedUser._id;

    if (
      props.deleteUser[userId] && !props.deleteUser[userId].saving && props.deleteUser[userId].saved
    ) {
      new Noty({
        type: 'success',
        layout: 'bottomLeft',
        text: 'User deleted successfully.',
      }).show();

      this.props.toggleModal();

      this.deleteStarted = false;
    } else if (
      props.deleteUser[userId] && !props.deleteUser[userId].saving && !props.deleteUser[userId].saved
    ) {
      let errorMessage = props.deleteUser[userId].error;
      if (!errorMessage) {
        errorMessage = 'Failed to delete user due to server error.';
      }

      new Noty({
        type: 'error',
        layout: 'bottomLeft',
        text: errorMessage,
      }).show();

      this.deleteStarted = false;
    }
  }

  onConfirmClicked() {
    const { selectedUser } = this.props;
    this.props.actions.deleteUserStarting();
    this.props.actions.deleteUser(selectedUser._id);
    this.deleteStarted = true;
  }

  render() {
    const { toggleModal, selectedUser, deleteUser } = this.props;

    let btnConfirm;

    const deleteData = deleteUser[selectedUser._id];
    if (deleteData && deleteData.saving) {
      btnConfirm = (
        <Button color="danger" disabled>
          <i className="fa fa-spinner fa-spin" />
          &nbsp;
          Please wait...
        </Button>
      );
    } else {
      btnConfirm = (
        <Button color="danger" onClick={this.onConfirmClicked}>
          <i className="fa fa-save" />
          &nbsp;
          Confirm
        </Button>
      );
    }

    return (
      <Modal isOpen={true} toggle={toggleModal} className="delete-user-modal">
        <ModalHeader toggle={toggleModal}> Delete User </ModalHeader>
        <ModalBody>
          <div>
            <p>
              Are you sure you want to delete the
               user <b>{selectedUser.userName}</b>?
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          {btnConfirm}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

DeleteUserModal.defaultProps = {
  actions: {},
  deleteUser: {},
};

DeleteUserModal.propTypes = {
  actions: PropTypes.object,
  selectedUser: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  deleteUser: PropTypes.object,
};

const mapStateToProps = state => ({
  deleteUser: state.deleteUser,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, usersActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserModal);
