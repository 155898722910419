import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card, CardBody, CardFooter, CardHeader, Col,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PhotoViewModal from "./PhotosTimelineResult/PhotoViewModal";
import {getFileUrl} from '../../utils/general';
import ResultUpdatedDate from "./ResultUpdatedDate";
import UploadPhotoModal from "./PhotosTimelineResult/UploadPhotoModal";
import ImagesPreviews from "./Common/ImagesPreviews";


class PhotosTimelineResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      /*selectedFile: null,
      showPhotoViewModal: false,
      showUploadPhotoModal: false,*/
    };

   /* this.togglePhotoViewModal = this.togglePhotoViewModal.bind(this);
    this.toggleUploadPhotoModal = this.toggleUploadPhotoModal.bind(this);
*/
  }

  /*togglePhotoViewModal(selectedFile) {
    const {showPhotoViewModal} = this.state;
    this.setState({
      selectedFile,
      showPhotoViewModal: !showPhotoViewModal,
    });
  }


  toggleUploadPhotoModal() {
    const { showUploadPhotoModal } = this.state;
    this.setState({
      showUploadPhotoModal: !showUploadPhotoModal,
    });
  }

  getImageCols() {
    const {resultData} = this.props;
    const {result} = resultData;
    const {files} = this.props;

    const rows = [];

    result.fileIds.forEach((fileId, index) => {
      if (!files[fileId]) {
        return true;
      }

      const file = files[fileId].file;
      if (!file) {
        return true;
      }

      const imageUrl = getFileUrl(file);

      rows.push((
        <Col xs={4} key={index} className="photos-container pb-2 ">
          <img
            src={imageUrl}
            className="pointer link"
            onClick={() => {
              this.togglePhotoViewModal(file)
            }}
          />
        </Col>
      ));
    });

    return rows;
  }*/

  render() {
    const {resultData, query} = this.props;
    const {result} = resultData;

   /* if (!result.fileIds) {
      return null;
    }

    let content;
    if (!result.fileIds.length) {
      content = (
        <div>
          No pictures configured yet.
        </div>
      );
    } else {
      const imageCols = this.getImageCols();

      content = (
        <div className="images-container">
          <Row>
            {imageCols}
          </Row>
        </div>
      );
    }

    const {selectedFile, showPhotoViewModal, showUploadPhotoModal} = this.state;

    let photoViewModal = null;
    let uploadPhotoModal = null;

    if (showPhotoViewModal) {
      photoViewModal = (
        <PhotoViewModal
          selectedFile={selectedFile}
          toggleModal={this.togglePhotoViewModal}
        />
      );
    }
    if (showUploadPhotoModal) {
      uploadPhotoModal = (
        <UploadPhotoModal
          toggleModal={this.toggleUploadPhotoModal}
        />
      );
    }
*/

    return (
      <div>
        <Col xs={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  {query}
                </Col>
                <Col className='text-right'>
                  <Button size="sm" type="button" color="info" onClick={this.toggleUploadPhotoModal} title="Upload">
                    <i className="fa fa-upload" />
                    {' '}
                    Upload
                  </Button>
                  {/*<SearchCount searchCount={resultData.result.searchCount}/>*/}
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="fixed-text-container">

              <ImagesPreviews
                query={query}
                resultData={resultData}
              />
         {/*     {content}*/}
            </CardBody>
            <CardFooter>
              <ResultUpdatedDate updatedAt={result.updatedAt}/>
            </CardFooter>
          </Card>
        </Col>
       {/* {photoViewModal}
        {uploadPhotoModal}*/}
      </div>
    );
  }
}

PhotosTimelineResult.defaultProps = {
  actions: {},
  files: {},
};

PhotosTimelineResult.propTypes = {
  actions: PropTypes.object,
  files: PropTypes.object,
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  files: state.files,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotosTimelineResult);

