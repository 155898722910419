import { cloneDeep } from 'lodash';
import {
  SET_CART_ITEM_QUANTITY_STARTING,
  SET_CART_ITEM_QUANTITY_SUCCESS,
  SET_CART_ITEM_QUANTITY_ERROR,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function setCartItemQuantityReducer(state = initialState.setCartItemQuantity, action) {
  switch (action.type) {
    case SET_CART_ITEM_QUANTITY_STARTING: {
      const { productId } = action;
      const newState = cloneDeep(state);

      if (!newState[productId]) {
        newState[productId] = cloneDeep(initialState.setCartItemQuantity.productId);
      }

      const updateData = newState[productId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;
      return newState;
    }

    case SET_CART_ITEM_QUANTITY_SUCCESS: {
      const { productId } = action;
      const newState = cloneDeep(state);

      if (!newState[productId]) {
        newState[productId] = cloneDeep(initialState.setCartItemQuantity.productId);
      }

      const updateData = newState[productId];
      updateData.saving = false;
      updateData.saved = true;
      return newState;
    }

    case SET_CART_ITEM_QUANTITY_ERROR: {
      const { productId } = action;
      const newState = cloneDeep(state);

      if (!newState[productId]) {
        newState[productId] = cloneDeep(initialState.setCartItemQuantity.productId);
      }

      const updateData = newState[productId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err ? action.err.message : 'Failed to set the New Quantity';
      return newState;
    }

    default: {
      return state;
    }
  }
}
