import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import CompanyBasic from "./BasicDetails";
import Detailedinfo from "./DetailedInfo";
import DetailedInfo from "../PersonResult/DetailedInfo";


// import * as actionsActions from '../actions/actions';

class CompanyDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { query, resultData } = this.props;
    return (
      <div>
        <Row>
          <Col xs={12} md={4}>
            <CompanyBasic/>

          </Col>

          <Col xs={12} md={8} className="mt-3 mt-md-0">

            <Detailedinfo
              query={query}
              resultData={resultData}
            />

          </Col>
        </Row>
      </div>

    );
  }
}

CompanyDetails.defaultProps = {
  actions: {},
};

CompanyDetails.propTypes = {
  actions: PropTypes.object,
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
