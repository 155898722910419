import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Card, CardBody,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PostSuggestion from "./PostSuggestion";

// import * as actionsActions from '../actions/actions';

class PostSuggestions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const suggestion = [
      {
        title: "covid",
        imgPath:"/../assets/pic2.webp",
        link : "https://www.ndtv.com/india-news/rich-nations-stockpiling-covid-vaccine-not-enough-for-rest-us-study-2337040",
        url: "Rich Nations Stockpiling Covid Vaccine, Not Enough for Rest:USA Study",

      },

      {
        title: "kapil sharma",
        imgPath:"/../assets/pic1.webp",
        link : "https://www.ndtv.com/entertainment/kapil-sharma-shares-pics-from-daughter-anayras-first-birthday-party-2337024",
        url: "Kapil Sharma Shares Pics From Daughter Anayra's First Birthday Party",

      },
    ];

    return (
      <div className="post-suggestions">
        <Card>
          <CardBody>

            <PostSuggestion
              suggestion={suggestion[0]}
            />
            <PostSuggestion
              suggestion={suggestion[1]}
            />

          </CardBody>
        </Card>
      </div>
    );
  }
}

PostSuggestions.defaultProps = {
  actions: {},
};

PostSuggestions.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostSuggestions);
