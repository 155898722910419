import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Dropdown, DropdownItem, DropdownToggle,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';

import DropdownMenu from "reactstrap/es/DropdownMenu";
import TwoPhotoView from "./TwoPhotoView";
import ThreePhotoView from "./ThreePhotoView";
import FourPhotoView from "./FourPhotoView";

// import * as actionsActions from '../actions/actions';

class Post extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      isDropdownOpen: false,
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
  }


  toggleDropdown() {
    const {isDropdownOpen} = this.state;
    this.setState({
      isDropdownOpen: !isDropdownOpen,
    });
  }

  render() {
    const {time, description, media} = this.props;
    const {isDropdownOpen} = this.state;

    let imageView = null;

    if (media) {
      if (media.length === 1) {
        imageView = (
          <div className="text-center"><img src={media[0]}/></div>
        );
      } else if (media.length === 2) {
        imageView =
          <TwoPhotoView
            media={media}
          />;
      } else if (media.length === 3) {
        imageView =
          <ThreePhotoView
            media={media}
          />;
      } else if (media.length === 4) {
        imageView =
          <FourPhotoView
            media={media}
          />;
      } else {
        imageView = null
      }
    }

    return (
      <div className="post-container">
        <Row className="">
          <Col xs="auto" className="">
            <img src="../assets/drbiden.jpg" className="image" />
          </Col>
          <Col className="pl-0">

            <p className="time"> {time} </p>
          </Col>
          <Col>
            <Col className="d-none d-md-flex text-right justify-content-end">
              <Dropdown tag="span" isOpen={isDropdownOpen} toggle={this.toggleDropdown} direction="left">
                <DropdownToggle
                  tag="a"
                  data-toggle="dropdown"
                  aria-expanded={isDropdownOpen}
                  className="pl-2 pr-2 pt-1 pb-1"
                  style={{color: '#CCC'}}
                >
                  <i className="fa fa-ellipsis-v"/>
                </DropdownToggle>
                <DropdownMenu className="p-0">
                  <DropdownItem>
                    Pin to top
                  </DropdownItem>

                  <DropdownItem>
                    Edit
                  </DropdownItem>

                  <DropdownItem>
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Col>
        </Row>

        <p className="mt-3"> {description} </p>
        {imageView}
        <hr/>
      </div>
    );
  }
}

Post.defaultProps = {};


Post.propTypes = {
  media: PropTypes.string,
  description: PropTypes.string,
  time: PropTypes.string,
};

const mapStateToProps =(/* state */) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Post);

