export const GET_SEARCH_RESULT_STARTING = 'GET_SEARCH_RESULT_STARTING';
export const GET_SEARCH_RESULT_SUCCESS = 'GET_SEARCH_RESULT_SUCCESS';
export const GET_SEARCH_RESULT_ERROR = 'GET_SEARCH_RESULT_ERROR';

export const SAVE_SEARCH_RESULT_STARTING = 'SAVE_SEARCH_RESULT_STARTING';
export const SAVE_SEARCH_RESULT_SUCCESS = 'SAVE_SEARCH_RESULT_SUCCESS';
export const SAVE_SEARCH_RESULT_ERROR = 'SAVE_SEARCH_RESULT_ERROR';

export const GET_SEARCH_SUGGESTIONS_STARTING = 'GET_SEARCH_SUGGESTIONS_STARTING';
export const GET_SEARCH_SUGGESTIONS_SUCCESS = 'GET_SEARCH_SUGGESTIONS_SUCCESS';
export const GET_SEARCH_SUGGESTIONS_ERROR = 'GET_SEARCH_SUGGESTIONS_ERROR';
export const SET_SELECTED_SUGGESTION = 'SET_SELECTED_SUGGESTION';

export const GET_DICT_WORDS_IN_PREFIX_STARTING = 'GET_DICT_WORDS_IN_PREFIX_STARTING';
export const GET_DICT_WORDS_IN_PREFIX_SUCCESS = 'GET_DICT_WORDS_IN_PREFIX_SUCCESS';
export const GET_DICT_WORDS_IN_PREFIX_ERROR = 'GET_DICT_WORDS_IN_PREFIX_ERROR';

export const GET_APP_CONFIG_STARTING = 'GET_APP_CONFIG_STARTING';
export const GET_APP_CONFIG_SUCCESS = 'GET_APP_CONFIG_SUCCESS';
export const GET_APP_CONFIG_ERROR = 'GET_APP_CONFIG_ERROR';

export const SAVE_APP_CONFIG_STARTING = 'SAVE_APP_CONFIG_STARTING';
export const SAVE_APP_CONFIG_SUCCESS = 'SAVE_APP_CONFIG_SUCCESS';
export const SAVE_APP_CONFIG_ERROR = 'SAVE_APP_CONFIG_ERROR';

export const FILE_UPLOAD_STARTING = 'FILE_UPLOAD_STARTING';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';

export const GET_USERS_STARTING = 'GET_USERS_STARTING';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const CREATE_USER_STARTING = 'CREATE_USER_STARTING';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const UPDATE_USER_STARTING = 'UPDATE_USER_STARTING';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const DELETE_USER_STARTING = 'DELETE_USER_STARTING';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const GET_FILES_STARTING = 'GET_FILES_STARTING';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_ERROR = 'GET_FILES_ERROR';

export const DELETE_FILE_STARTING = 'DELETE_FILE_STARTING';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_ERROR = 'DELETE_FILE_ERROR';

export const UPDATE_FILE_STARTING = 'UPDATE_FILE_STARTING';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_ERROR = 'UPDATE_FILE_ERROR';

export const META_SEARCH_MOVE_TO_TOP_STARTING = 'META_SEARCH_MOVE_TO_TOP_STARTING';
export const META_SEARCH_MOVE_TO_TOP_SUCCESS = 'META_SEARCH_MOVE_TO_TOP_SUCCESS';
export const META_SEARCH_MOVE_TO_TOP_ERROR = 'META_SEARCH_MOVE_TO_TOP_ERROR';

export const META_SEARCH_ADD_REDIRECT_STARTING = 'META_SEARCH_ADD_REDIRECT_STARTING';
export const META_SEARCH_ADD_REDIRECT_SUCCESS = 'META_SEARCH_ADD_REDIRECT_SUCCESS';
export const META_SEARCH_ADD_REDIRECT_ERROR = 'META_SEARCH_ADD_REDIRECT_ERROR';

export const META_SEARCH_ADD_VIDEO_EMBED_STARTING = 'META_SEARCH_ADD_VIDEO_EMBED_STARTING';
export const META_SEARCH_ADD_VIDEO_EMBED_SUCCESS = 'META_SEARCH_ADD_VIDEO_EMBED_SUCCESS';
export const META_SEARCH_ADD_VIDEO_EMBED_ERROR = 'META_SEARCH_ADD_VIDEO_EMBED_ERROR';

export const META_SEARCH_EXTRACT_INFO_STARTING = 'META_SEARCH_EXTRACT_INFO_STARTING';
export const META_SEARCH_EXTRACT_INFO_SUCCESS = 'META_SEARCH_EXTRACT_INFO_SUCCESS';
export const META_SEARCH_EXTRACT_INFO_ERROR = 'META_SEARCH_EXTRACT_INFO_ERROR';

export const META_SEARCH_REMOVE_STARTING = 'META_SEARCH_REMOVE_STARTING';
export const META_SEARCH_REMOVE_SUCCESS = 'META_SEARCH_REMOVE_SUCCESS';
export const META_SEARCH_REMOVE_ERROR = 'META_SEARCH_REMOVE_ERROR';

export const USER_LOGIN_STARTING = 'USER_LOGIN_STARTING';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';

export const USER_SIGNUP_STARTING = 'USER_SIGNUP_STARTING';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_ERROR = 'USER_SIGNUP_ERROR';

export const USER_LOGOUT_STARTING = 'USER_LOGOUT_STARTING';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';

export const GET_USER_INFO_STARTING = 'GET_USER_INFO_STARTING';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';

export const SAVE_USER_PROFILE_STARTING = 'SAVE_USER_PROFILE_STARTING';
export const SAVE_USER_PROFILE_SUCCESS = 'SAVE_USER_PROFILE_SUCCESS';
export const SAVE_USER_PROFILE_ERROR = 'SAVE_USER_PROFILE_ERROR';

export const SET_CART_ITEM_QUANTITY_STARTING = 'SET_CART_ITEM_QUANTITY_STARTING';
export const SET_CART_ITEM_QUANTITY_SUCCESS = 'SET_CART_ITEM_QUANTITY_SUCCESS';
export const SET_CART_ITEM_QUANTITY_ERROR = 'SET_CART_ITEM_QUANTITY_ERROR';

export const PLACE_ORDER_STARTING = 'PLACE_ORDER_STARTING';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_ERROR = 'PLACE_ORDER_ERROR';
