import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Card, CardBody, Col, Dropdown, DropdownItem, DropdownToggle,
  Row,
} from 'reactstrap';
import Noty from 'noty';
import DropdownMenu from "reactstrap/es/DropdownMenu";
import AddRedirectModal from "./AddRedirectModal";
import AddVideoEmbedModal from "./AddVideoEmbedModal";
import RemoveModal from "./RemoveModal";
import * as searchResultActions from '../../../actions/searchResultActions';
import { checkIfVideoUrl } from '../../../utils/general';

class WebPageRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDropdownOpen: false,
      isDescriptionOpen: false,
      showAddRedirectModal: false,
      showAddVideoEmbedModal: false,
      showRemoveModal: false,
    };

    this.onMoveToTop = this.onMoveToTop.bind(this);
    this.toggleAddRedirectModal = this.toggleAddRedirectModal.bind(this);
    this.toggleAddVideoEmbedModal = this.toggleAddVideoEmbedModal.bind(this);
    this.onExtractInfo = this.onExtractInfo.bind(this);
    this.toggleDescription = this.toggleDescription.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleRemoveModal = this.toggleRemoveModal.bind(this);

    this.moveToTopStarted = false;
    this.extractInfoStarted = false;
  }

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    const { query } = this.props;

    if (
      this.moveToTopStarted &&
      this.props.metaSearchMoveToTop !== nextProps.metaSearchMoveToTop &&
      nextProps.metaSearchMoveToTop[query]
    ) {
      this.handleMoveToTopStatusChange(nextProps);
    } else if (
      this.extractInfoStarted &&
      this.props.metaSearchExtractInfo !== nextProps.metaSearchExtractInfo &&
      nextProps.metaSearchExtractInfo[query]
    ) {
      this.handleExtractInfoStatusChange(nextProps);
    }
  }

  handleMoveToTopStatusChange(props) {
    const { query } = props;

    if (
      props.metaSearchMoveToTop[query] && !props.metaSearchMoveToTop[query].saving && props.metaSearchMoveToTop[query].saved
    ) {
      new Noty({
        type: 'success',
        layout: 'bottomLeft',
        text: 'Selected web page has been moved to the top.',
      }).show();

      this.moveToTopStarted = false;
    } else if (
      props.metaSearchMoveToTop[query] && !props.metaSearchMoveToTop[query].saving && !props.metaSearchMoveToTop[query].saved
    ) {
      let errorMessage = props.metaSearchMoveToTop[query].error;
      if (!errorMessage) {
        errorMessage = 'Failed to move web page to top due to server error.';
      }

      new Noty({
        type: 'error',
        layout: 'bottomLeft',
        text: errorMessage,
      }).show();

      this.moveToTopStarted = false;
    }
  }

  handleExtractInfoStatusChange(props) {
    const { query } = props;

    if (
      props.metaSearchExtractInfo[query] && !props.metaSearchExtractInfo[query].saving && props.metaSearchExtractInfo[query].saved
    ) {
      new Noty({
        type: 'success',
        layout: 'bottomLeft',
        text: 'Selected web page extracted info.',
      }).show();

      this.extractInfoStarted = false;
    } else if (
      props.metaSearchExtractInfo[query] && !props.metaSearchExtractInfo[query].saving && !props.metaSearchExtractInfo[query].saved
    ) {
      let errorMessage = props.metaSearchExtractInfo[query].error;
      if (!errorMessage) {
        errorMessage = 'Failed to extract info of the selected web page ';
      }

      new Noty({
        type: 'error',
        layout: 'bottomLeft',
        text: errorMessage,
      }).show();

      this.extractInfoStarted = false;
    }
  }

  toggleDescription() {
    const { isDescriptionOpen } = this.state;
    this.setState({
      isDescriptionOpen: !isDescriptionOpen,
    });
  }

  toggleDropdown() {
    const { isDropdownOpen } = this.state;
    this.setState({
      isDropdownOpen: !isDropdownOpen,
    });
  }

  toggleRemoveModal() {
    const { showRemoveModal } = this.state;
    this.setState({
      showRemoveModal: !showRemoveModal,
    });
  }

  onMoveToTop() {
    const { query, webPageId } = this.props;
    this.props.actions.metaSearchMoveToTopStarting(query);
    this.props.actions.metaSearchMoveToTop(query, webPageId);
    this.moveToTopStarted = true;
  }

  onExtractInfo() {
    const { query, webPageId } = this.props;
    this.props.actions.metaSearchExtractInfoStarting(query);
    this.props.actions.metaSearchExtractInfo(query, webPageId);
    this.extractInfoStarted = true;
  }

  toggleAddRedirectModal() {
    const { showAddRedirectModal } = this.state;
    this.setState({
      showAddRedirectModal: !showAddRedirectModal,
    });
  }

  toggleAddVideoEmbedModal() {
    const { showAddVideoEmbedModal } = this.state;
    this.setState({
      showAddVideoEmbedModal: !showAddVideoEmbedModal,
    });
  }


  getSpecialIcon(url) {
    let icon = 'fa fa-externa-link';

    if (!url) {
      return icon;
    }

    if (url.indexOf('wikipedia.org') !== -1) {
      icon = 'fa fa-wikipedia-w';
    } else if (url.indexOf('facebook.com') !== -1) {
      icon = 'fa fa-facebook-official';
    } else if (url.indexOf('twitter.com') !== -1) {
      icon = 'fa fa-twitter-square';
    } else if (url.indexOf('linkedin.com') !== -1) {
      icon = 'fa fa-linkedin';
    } else if (url.indexOf('youtube.com') !== -1) {
      icon = 'fa fa-youtube-play';
    } else if (url.indexOf('imdb.com') !== -1) {
      icon = 'fa fa-imdb';
    } else if (url.indexOf('apple.com') !== -1 || url.indexOf('apple.co.uk') !== -1) {
      icon = 'fa fa-apple';
    } else if (
      url.indexOf('google.com') !== -1 ||
      url.indexOf('google.co.uk') !== -1 ||
      url.indexOf('google.co.in') !== -1
    ) {
      icon = 'fa fa-google';
    } else if (
      url.indexOf('amazon.com') !== -1 ||
      url.indexOf('amazon.co.uk') !== -1 ||
      url.indexOf('amazon.co.in') !== -1 ||
      url.indexOf('amazon.co.de') !== -1
    ) {
      icon = 'fa fa-amazon';
    } else if (url.indexOf('github.com') !== -1) {
      icon = 'fa fa-github';
    } else if (url.indexOf('microsoft.com') !== -1) {
      icon = 'fa fa-windows';
    } else if (url.indexOf('gitlab.com') !== -1) {
      icon = 'fa fa-gitlab';
    } else if (url.indexOf('trello.com') !== -1) {
      icon = 'fa fa-trello';
    } else if (url.indexOf('telegram.com') !== -1) {
      icon = 'fa fa-telegram';
    } else if (url.indexOf('whatsapp.com') !== -1) {
      icon = 'fa fa-whatsapp';
    } else if (url.indexOf('vimeo.com') !== -1) {
      icon = 'fa fa-vimeo-square';
    } else {
      icon = 'fa fa-globe';
    }

    return icon;
  }

  render() {
    const { isDropdownOpen, showAddRedirectModal, showAddVideoEmbedModal, showRemoveModal } = this.state;
    const { webPageId, webPageData, query, index } = this.props;
    const icon = this.getSpecialIcon(webPageData.url);

    let addRedirectModal = null;
    let removeModal = null;

    let isVideoUrl = false;
    let addVideoEmbedModal = null;

    if (showAddRedirectModal) {
      addRedirectModal = (
        <AddRedirectModal
          query={query}
          webPageId={webPageId}
          webPageData={webPageData}
          toggleModal={this.toggleAddRedirectModal}
        />
      );
    }

    if (showAddVideoEmbedModal) {
      addVideoEmbedModal = (
        <AddVideoEmbedModal
          query={query}
          webPageId={webPageId}
          webPageData={webPageData}
          toggleModal={this.toggleAddVideoEmbedModal}
        />
      );
    }

    if (showRemoveModal) {
      removeModal = (
        <RemoveModal
          query={query}
          webPageId={webPageId}
          webPageData={webPageData}
          toggleModal={this.toggleRemoveModal}
        />
      );
    }

    let { title, url } = webPageData;

    if (title.length > 120) {
      title = `${title.substring(0, 120)}...`;
    }

    if (url.length > 80) {
      url = `${url.substring(0, 80)}...`;
    }

    isVideoUrl = checkIfVideoUrl(webPageData.url);

    return (
      <Card className="web-page-link" key={webPageId}>
        <CardBody>
          <Row>
            <Col xs={12} md={11}>
              <a href={webPageData.url}>
                <h5 className="result-title">{webPageData.title}</h5>
              </a>

              <a href={webPageData.url}>
                <p className="result-url">
                  <i className={icon} />&nbsp;
                  {url}
                </p>
              </a>

              {/*<Collapse isOpen={isDescriptionOpen}>*/}
              <a href={webPageData.url}>
                <p className="result-desc">{webPageData.description}</p>
              </a>
              {/*</Collapse>*/}
            </Col>
            <Col md={1} className="d-none d-md-flex text-right justify-content-end">
              <Dropdown tag="span" isOpen={isDropdownOpen} toggle={this.toggleDropdown} direction="left"
                        className="web-page-options custom-dropdown-style1">
                <DropdownToggle
                  tag="a"
                  data-toggle="dropdown"
                  aria-expanded={isDropdownOpen}
                  className="pl-2 pr-2 pt-1 pb-1"
                  style={{ color: '#CCC' }}
                >
                  <i className="fa fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="p-0">
                  {(index !== 0) && (
                    <DropdownItem onClick={this.onMoveToTop}>
                      <i className="fa fa-sort-asc" />
                      &nbsp;
                      Move to Top
                    </DropdownItem>
                  )}

                  <DropdownItem onClick={this.toggleAddRedirectModal}>
                    <i className="fa fa-external-link" />
                    &nbsp;
                    Add Redirect
                  </DropdownItem>

                  {
                    isVideoUrl && (<DropdownItem onClick={this.toggleAddVideoEmbedModal}>
                      <i className="fa fa-video-camera " />
                      &nbsp;
                      Add Video Embed
                    </DropdownItem>)
                  }

                  <DropdownItem onClick={this.onExtractInfo}>
                    <i className="fa fa-info-circle " />
                    &nbsp;
                    Extract Info
                  </DropdownItem>

                  <DropdownItem className="border-danger" onClick={this.toggleRemoveModal}>
                    <i className="fa fa-times" />
                    &nbsp;
                    Remove
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
            {addRedirectModal}
            {addVideoEmbedModal}
            {removeModal}

            {/*<Col md={1} className="d-none d-md-flex text-right justify-content-end">
             <a href={null} className="btn-toggle-desc pointer link" onClick={this.toggleDescription}>
             <i className={classnames({
             "fa": true,
             "fa-chevron-down": !isDescriptionOpen,
             "fa-chevron-up": isDescriptionOpen,
             })} />
             </a>
             </Col>*/}
          </Row>
        </CardBody>
      </Card>
    );
  }
}


WebPageRow.defaultProps = {
  actions: {},
  index: 0,
  query: '',
  metaSearchMoveToTop: {},
  metaSearchExtractInfo: {},
};

WebPageRow.propTypes = {
  actions: PropTypes.object,
  index: PropTypes.number,
  metaSearchMoveToTop: PropTypes.object,
  metaSearchExtractInfo: PropTypes.object,
  query: PropTypes.string,
  webPageId: PropTypes.string.isRequired,
  webPageData: PropTypes.object.isRequired,

};

const mapStateToProps = state => ({
  metaSearchMoveToTop: state.metaSearchMoveToTop,
  metaSearchExtractInfo: state.metaSearchExtractInfo,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, searchResultActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebPageRow);

