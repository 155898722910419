import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardHeader,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SearchForm from './SearchForm';
import CalculatorDocumentation from './CalculatorDocumentation';

// import * as actionsActions from '../actions/actions';

class CalculatorContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { resultData, query } = this.props;

    return (
      <div className="calculator-container">
        <Card className="calculator border-primary">
          <CardHeader>
            <div className="problem-str mb-0">
              {query}
            </div>

          </CardHeader>
          <CardBody className="p-0">
            <div className="result-str mb-0 mt-0">
              <SearchForm
                defaultQuery={resultData.result.exprResult.toString()}
              />
            </div>

            <div>
              <CalculatorDocumentation />
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

CalculatorContainer.defaultProps = {
  actions: {},
};

CalculatorContainer.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */)  => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorContainer);
