import React from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody,
} from 'reactstrap';
import classnames from 'classnames';

import News from "./News";

class DetailedInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',

    };

    this.setActiveTab = this.setActiveTab.bind(this);

  }


  setActiveTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      activeTab
    } = this.state;

    return (

      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1', 'pointer link': true })}
              onClick={() => {
                this.setActiveTab('1');
              }}
              tag="div"
            >
              News
            </NavLink>
          </NavItem>

        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Card>
              <CardBody>
                <News />

              </CardBody>
            </Card>
          </TabPane>

         </TabContent>
      </div>
    )
      ;
  }
}

DetailedInfo.defaultProps = {
  actions: {},
};

DetailedInfo.propTypes = {};

export default DetailedInfo;

