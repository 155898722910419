import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NewsPost from "./NewsPost";


class News extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="news-container">

        <NewsPost
          title={"The Lion, the Witch and the Wardrobe,"}
          description=" The best known, the Lion, the Witch and the Wardrobe, has been translated into 29 languages! The illustrations in this book have been coloured by the original artist, Pauline Baynes. ***Contains Colour Images**
           "
         />
        <NewsPost
          title={"Narnia Beckons: C.S. Lewis's The Lion, the Witch, and the ..."}
          description="Presents essays by C.S. Lewis experts in order to help readers gain a better understanding of the deeper Christian meanings within the novel and examines the television and movie productions."

          />
        <NewsPost
           title={"Inside Narnia: A Guide to Exploring The Lion, the Witch and ..."}
          description="The Lion, the Witch and the Wardrobe tells the story of four adventurous children: Peter, Susan, Edmund, and Lucy Pevensie, who have been evacuated to the English countryside in 1940 following the outbreak of World War II. They discover a ..."
           />
      </div>
    );
  }
}

News.defaultProps = {
  actions: {},
};

News.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(News);

