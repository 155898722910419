import { cloneDeep } from 'lodash';
import {
  CREATE_USER_STARTING,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function createUserReducer(state = initialState.createUser, action) {
  switch (action.type) {
    case CREATE_USER_STARTING: {
      const newState = cloneDeep(state);
      newState.saving = true;
      newState.saved = false;
      return newState;
    }

    case CREATE_USER_SUCCESS: {
      const newState = cloneDeep(state);
      newState.saving = false;
      newState.saved = true;
      newState.error = null;

      return newState;
    }

    case CREATE_USER_ERROR: {
      const newState = cloneDeep(state);
      newState.saving = false;
      newState.saved = false;
      newState.error = action.err ? action.err.message : 'Failed to create user';

      return newState;
    }

    default: {
      return state;
    }
  }
}
