import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card, CardBody, CardFooter, CardHeader, Col,
  Row,
} from 'reactstrap';
import Post from "./PostTimelineResult/Post";
import ResultUpdatedDate from "./ResultUpdatedDate";
import AddPostModal from "../SettingsPage/AddPostModal";
import ResultTypeUnderDevelopment from "./Common/ResultTypeUnderDevelopment";

// import * as actionsActions from '../actions/actions';

class PostTimelineResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddPostModal: false,
    };

    this.toggleAddPostModal = this.toggleAddPostModal.bind(this);
  }

  toggleAddPostModal() {
    const { showAddPostModal } = this.state;
    this.setState({
      showAddPostModal: !showAddPostModal,
    });
  }


  render() {
    const { showAddPostModal } = this.state;
    const {resultData, query} = this.props;
    const {result} = resultData;

    let addPostModal = null;

    if (showAddPostModal) {
      addPostModal = (
        <AddPostModal toggleModal={this.toggleAddPostModal} />
      );
    }

    return (
      <div className="post-timeline-container">
        <Card>
          <CardHeader>
            <Row>
              <Col>
                {query}
              </Col>
              <Col>
                <Button color="primary  float-right" onClick={this.toggleAddPostModal}>
                  Add Post
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <ResultTypeUnderDevelopment />
            <Row>
              <Col xs={12} md={6}>
                <Post
                  time={"5 hours"}
                  description={"Its an honor to welcome @DrBiden back to Atlanta as early voting begins across our state. Let’s GO Georgia! “The vote is the most                  powerful nonviolent tool we have.” - Congressman John Lewis"}
                 />
                <Post
                  time={"5 hours"}
                  description={"Its an honor to welcome @DrBiden back to Atlanta as early voting begins across our state. Let’s GO Georgia! “The vote is the most                  powerful nonviolent tool we have.” - Congressman John Lewis"}
                  media={["../assets/dr2.jfif"]}
                />
                <Post
                  time={"5 hours"}
                  description={"Its an honor to welcome @DrBiden back to Atlanta as early voting begins across our state. Let’s GO Georgia! “The vote is the most                  powerful nonviolent tool we have.” - Congressman John Lewis"}
                  media={["../assets/dr2.jfif", "../assets/dr3.jfif" ]}
                />
                <Post
                  time={"9 Oct"}
                  description={"Debating responsibly. There's no need to once again endanger the lives of people in the debate room.\n" +
                  "                  Debate via Zoom or closed rooms with mics and cameras. Honestly -\n" +
                  "                  they have websites people can go to and read their plans. A debate isn't necessary."}

                />
                <Post
                  time={"5 Oct"}
                  description={"I'm voting in 11 days. Early voting in person with a mask and hand sanitizer\n" +
                  "                  and I'm taking my whole household with me"}
                  media={["../assets/dr2.jfif", "../assets/dr3.jfif", "../assets/dr8.jfif"]}
                />

                <Post
                  time={"2 Oct"}
                  description={"Join me for a virtual mobilization event in Milwaukee!"}
                  media={["../assets/dr8.jfif", "../assets/dr5.jfif", "../assets/dr6.jfif", "../assets/dr3.jfif" ]}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <ResultUpdatedDate updatedAt={result.updatedAt}/>
          </CardFooter>
        </Card>
        {addPostModal}
      </div>
    );
  }
}

PostTimelineResult.defaultProps = {};

PostTimelineResult.propTypes = {
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,

};

export default PostTimelineResult;

