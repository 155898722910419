import React from 'react';
import { Link } from 'react-router-dom';
import Redirect from './Common/Redirect';
import { apiBaseUrl } from '../constants/appConfig';
import Header from './CustomPage/Header';
import { Card, CardBody, Container } from 'reactstrap';
import Footer from './Common/Footer';

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);

    let shouldRedirect = false;
    let redirectWithQuery = '';

    const { pathname } = this.props.location;
    const urlParts = pathname.split('/').filter(p => p.length > 0);

    if (urlParts.length === 1) {
      shouldRedirect = true;
      redirectWithQuery = urlParts[0];
    }

    this.state = {
      shouldRedirect,
      redirectWithQuery,
    };
  }

  render() {
    const { shouldRedirect, redirectWithQuery } = this.state;
    if (shouldRedirect) {
      const searchUrl = `${apiBaseUrl}/search?q=${redirectWithQuery}`;
      return (
        <Card className="redirect-card">
          <CardBody>
            <Redirect to={searchUrl} />
          </CardBody>
        </Card>
      );
    }

    return (
      <div className="custom-page not-found-page">
        <Header pageTitle={`Page not found!`} />

        <Container>
          <Card>
            <CardBody>
              <Link to="/"> Go back to homepage </Link>
            </CardBody>
          </Card>
        </Container>

        <Footer />
      </div>
    );
  }
}


export default NotFoundPage;
