import { cloneDeep } from 'lodash';
import {
  META_SEARCH_EXTRACT_INFO_STARTING,
  META_SEARCH_EXTRACT_INFO_SUCCESS,
  META_SEARCH_EXTRACT_INFO_ERROR,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function metaSearchExtractInfoReducer(state = initialState.metaSearchExtractInfo, action) {
  switch (action.type) {
    case META_SEARCH_EXTRACT_INFO_STARTING: {
      const { query } = action;
      const newState = cloneDeep(state);

      if (!newState[query]) {
        newState[query] = cloneDeep(initialState.metaSearchExtractInfo.query);
      }

      const extractInfoData = newState[query];
      extractInfoData.saving = true;
      extractInfoData.saved = false;
      extractInfoData.error = null;
      return newState;
    }

    case META_SEARCH_EXTRACT_INFO_SUCCESS: {
      const { query } = action;
      const newState = cloneDeep(state);

      if (!newState[query]) {
        newState[query] = cloneDeep(initialState.metaSearchExtractInfo.query);
      }

      const extractInfoData = newState[query];
      extractInfoData.saving = false;
      extractInfoData.saved = true;
      return newState;
    }

    case META_SEARCH_EXTRACT_INFO_ERROR: {
      const { query } = action;
      const newState = cloneDeep(state);

      if (!newState[query]) {
        newState[query] = cloneDeep(initialState.metaSearchExtractInfo.query);
      }

      const extractInfoData = newState[query];
      extractInfoData.saving = false;
      extractInfoData.saved = false;
      extractInfoData.error = action.err ? action.err.message : 'Extract Info action  failed';
      return newState;
    }

    default: {
      return state;
    }
  }
}
