import {
  META_SEARCH_MOVE_TO_TOP_STARTING,
  META_SEARCH_MOVE_TO_TOP_SUCCESS,
  META_SEARCH_MOVE_TO_TOP_ERROR,

  META_SEARCH_ADD_REDIRECT_STARTING,
  META_SEARCH_ADD_REDIRECT_SUCCESS,
  META_SEARCH_ADD_REDIRECT_ERROR,

  META_SEARCH_ADD_VIDEO_EMBED_STARTING,
  META_SEARCH_ADD_VIDEO_EMBED_SUCCESS,
  META_SEARCH_ADD_VIDEO_EMBED_ERROR,

  META_SEARCH_REMOVE_STARTING,
  META_SEARCH_REMOVE_SUCCESS,
  META_SEARCH_REMOVE_ERROR,

  META_SEARCH_EXTRACT_INFO_STARTING,
  META_SEARCH_EXTRACT_INFO_SUCCESS,
  META_SEARCH_EXTRACT_INFO_ERROR,

} from '../constants/actionTypes';
import axios from "./axios";
import { apiBaseUrl } from '../constants/appConfig';

export function metaSearchMoveToTopStarting(query) {
  return { type: META_SEARCH_MOVE_TO_TOP_STARTING, query };
}

export function metaSearchMoveToTopSuccess(query, data) {
  return { type: META_SEARCH_MOVE_TO_TOP_SUCCESS, query, data };
}

export function metaSearchMoveToTopFailed(query, err) {
  return { type: META_SEARCH_MOVE_TO_TOP_ERROR, query, err };
}

export function metaSearchMoveToTop(query, webPageId) {
  return dispatch => (
    axios.post(`${apiBaseUrl}/search_results/meta_search/move_to_top`, {
      q: query,
      webPageId,
    }).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(metaSearchMoveToTopSuccess(query, response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Move To Top Failed');
      dispatch(metaSearchMoveToTopFailed(query, err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(metaSearchMoveToTopFailed(query, error));
    }));
}

export function metaSearchAddRedirectStarting(query) {
  return { type: META_SEARCH_ADD_REDIRECT_STARTING, query };
}

export function metaSearchAddRedirectSuccess(query, data) {
  return { type: META_SEARCH_ADD_REDIRECT_SUCCESS, query, data }
}

export function metaSearchAddRedirectFailed(query, err) {
  return { type: META_SEARCH_ADD_REDIRECT_ERROR, query, err }
}

export function metaSearchAddRedirect(query, webPageId) {
  return dispatch => (
    axios.post(`${apiBaseUrl}/search_results/meta_search/add_redirect`, {
      q: query,
      webPageId,
    }).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(metaSearchAddRedirectSuccess(query, response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Add to Redirect Failed');
      dispatch(metaSearchAddRedirectFailed(query, err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(metaSearchAddRedirectFailed(query, error));
    }));
}

export function metaSearchAddVideoEmbedStarting(query) {
  return { type: META_SEARCH_ADD_VIDEO_EMBED_STARTING, query };
}

export function metaSearchAddVideoEmbedSuccess(query, data) {
  return { type: META_SEARCH_ADD_VIDEO_EMBED_SUCCESS, query, data }
}

export function metaSearchAddVideoEmbedFailed(query, err) {
  return { type: META_SEARCH_ADD_VIDEO_EMBED_ERROR, query, err }
}

export function metaSearchAddVideoEmbed(query, webPageId) {
  return dispatch => (
    axios.post(`${apiBaseUrl}/search_results/meta_search/add_video_embed`, {
      q: query,
      webPageId,
    }).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(metaSearchAddVideoEmbedSuccess(query, response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Add  Video Embed Failed');
      dispatch(metaSearchAddVideoEmbedFailed(query, err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(metaSearchAddVideoEmbedFailed(query, error));
    }));
}

export function metaSearchRemoveStarting(query) {
  return { type: META_SEARCH_REMOVE_STARTING, query };
}

export function metaSearchRemoveSuccess(query, data) {
  return { type: META_SEARCH_REMOVE_SUCCESS, query, data }
}

export function metaSearchRemoveFailed(query, err) {
  return { type: META_SEARCH_REMOVE_ERROR, query, err }
}

export function metaSearchRemove(query, webPageId) {
  return dispatch => (
    axios.delete(`${apiBaseUrl}/search_results/meta_search/remove`, {
      q: query,
      webPageId,
    }).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(metaSearchRemoveSuccess(query, response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Remove Failed');
      dispatch(metaSearchRemoveFailed(query, err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(metaSearchRemoveFailed(query, error));
    }));
}

export function metaSearchExtractInfoStarting(query) {
  return { type: META_SEARCH_EXTRACT_INFO_STARTING, query };
}

export function metaSearchExtractInfoSuccess(query, data) {
  return { type: META_SEARCH_EXTRACT_INFO_SUCCESS, query, data };
}

export function metaSearchExtractInfoFailed(query, err) {
  return { type: META_SEARCH_EXTRACT_INFO_ERROR, query, err };
}

export function metaSearchExtractInfo(query, webPageId) {
  return dispatch => (
    axios.post(`${apiBaseUrl}/search_results/meta_search/extract_info`, {
      q: query,
      webPageId,
    }).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(metaSearchExtractInfoSuccess(query, response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Extract Info Failed');
      dispatch(metaSearchExtractInfoFailed(query, err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(metaSearchExtractInfoFailed(query, error));
    }));
}
