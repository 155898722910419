import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from "react-router-dom";
import StarRating from "./StarRating";

// import * as actionsActions from '../actions/actions';

class ProductSpecifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {

    const { title, msrp, discountedPrice, imgPath, reviewsCount, rating } = this.props.product;

    return (
      <div className= "products-list-result">
        <Card className="mb-2">
          <CardBody>
            <Row>
              <Col xs="auto">
                <a href="/search?q=galaxy+tab+a" >
                  <img src={imgPath} />
                </a>
              </Col>
              <Col xs={10}>
                <Link to="/search?q=galaxy+tab+a"> {title}  </Link>
                <Row>
                  <Col xs="auto" className="pr-0">
                    <StarRating
                    rating = {rating}
                    />
                  </Col>
                  <Col xs={2}>
                    <small> {reviewsCount}</small>
                  </Col>

                </Row>
                <p><span className="txt-bold"> {discountedPrice} </span>
                  <span className=" ml-1 txt-strike text-muted txt-small"> {msrp} </span>
                  </p>

              </Col>

            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

ProductSpecifications.defaultProps = {
  actions: {},
};

ProductSpecifications.propTypes = {
  actions: PropTypes.object,
  product: PropTypes.object.isRequired,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSpecifications);
