import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardFooter, Col, Row
} from 'reactstrap';
import WordsList from './DictionaryPrefixResult/WordsList';
import ResultUpdatedDate from "./ResultUpdatedDate";

// import * as actionsActions from '../actions/actions';

class DictionaryPrefixResult extends React.Component {
  render() {
    const {query, resultData} = this.props;
    const {result} = resultData;

    let {prefixStr, meanings, synonyms, antonyms} = result;
    if (!prefixStr) {
      prefixStr = query; // TODO May need to capitalize first letter
    }

    if (synonyms.length > 0) {
      synonyms = (
        <div
          className="mt-3 mb-0 synonyms-text"
          dangerouslySetInnerHTML={{__html: synonyms}}
        />
      );
    }

    if (antonyms.length > 0) {
      antonyms =
        (
          <div
            className="mt-3 mb-0 antonyms-text"
            dangerouslySetInnerHTML={{__html: antonyms}}
          />
        );

    }


    return (
      <Row className="dictionary-prefix-result">
        <Col xs={12}>
          <Card>
            {/*<CardHeader>
              <Row>
                <Col>
                  <LinkToDefaultSearchEngine
                    query={query}
                  />

                  {query}
                </Col>
                <Col className='text-right'>
                  <SearchCount searchCount={resultData.result.searchCount} />
                </Col>
                <Col xs="auto" className="d-none d-md-block">
                  <div className="d-inline-block  ml-3">
                    <AddResultTypeDropdown
                      query={query}
                      resultData={resultData}
                    />
                  </div>
                </Col>
              </Row>
            </CardHeader>*/}
            <CardBody>
              <Row>
                <Col xs={12} md={6}>
                  <h2><b> {prefixStr}</b></h2>
                   <div
                    className="mt-3 mb-0 meanings-text"
                    dangerouslySetInnerHTML={{__html: meanings}}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <b>{result.lang}</b> language
                </Col>
              </Row>
              {synonyms}
              {antonyms}

              <hr/>
              <WordsList
                prefixStr={prefixStr}
              />
            </CardBody>
            <CardFooter>
              <ResultUpdatedDate updatedAt={result.updatedAt}/>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    );
  }
}

DictionaryPrefixResult.propTypes = {
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};

export default DictionaryPrefixResult;
