import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SocialProfiles from "./SocialProfiles";


// import * as actionsActions from '../actions/actions';

class BasicDetails extends React.Component {
  render() {
    return (
      <div className="company-details">
        <Card>
          <CardBody>
            <Row className="">
              <Col xs="auto" className="">
                <img className="mt-1 display-pic" src="/assets/daburlogo.png" alt="Card image cap"/>
              </Col>

              <Col className="d-flex flex-column justify-content-center">
                <h5> Dabur India Limited
                </h5>
              </Col>
            </Row>

            <div>
              <p className="mt-3">
                Dabur is an Indian consumer goods company founded in 1884 by S. K. Burman.
                It manufactures Ayurvedic medicine and natural consumer products.
                It is one of the largest fast-moving consumer goods companies in India.
                Anurag Gupta is appointed as a director of Dabur Pharmacuticles in 2015
              </p>
              <SocialProfiles/>

            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

BasicDetails.defaultProps = {
  actions: {},
};

BasicDetails.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */)=> ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);
