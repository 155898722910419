import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import NewsPost from "./NewsPost";
import pm1 from "../../../assets/pm1.png";
import pm2 from "../../../assets/pm2.png";
import pm3 from "../../../assets/pm3.png";
import pm4 from "../../../assets/pm4.png";
import pm5 from "../../../assets/pm5.png";
import pm6 from "../../../assets/pm6.png";

class News extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="">

        <NewsPost
          channel="The New Indian Express"
          headline={"The power Modi derives from his office is multiplied manifold by charisma and influence "}
          description="Greatness is the ability of a leader to respond effectively to events – seen and unforeseen – and achieve national policy objectives. To succeed, the leader must communicate his ideas "
          imgPath={pm1}
          time="1 day ago"/>
        <NewsPost
          channel="Times Now"
          headline={"PM Modi is 69 and running the country', Arun Lal unwilling to follow BCCI SOP for professionals aged 60+"}
          description="Former India cricketer Arun Lal has categorically denied BCCI's SOPs for professionals aged 60-plus. Lal cited PM Narendra Modi's example as he hoped to continue as Bengal coach despite COVID-19 threat. "
          imgPath={pm2}
          time="2 days ago"/>
        <NewsPost
          channel="Indian Express"
          headline={"Uddhav Thackeray writes to PM Modi, seeks creation of buffer stock to help dairies pay better prices to milk farmers"}
          description="Thackeray said that Maharashtra has implemented a scheme to procure 10 lakh litres of milk per day and convert it into SMP and white butter. Till July 25, at least six crore litres of milk has been procured, he said. "
          imgPath={pm3}
          time="13 hours ago"/>
        <NewsPost
          channel="Republic World"
          headline={"Ram Mandir Bhumi Pujan LIVE Update: PM Hails Efforts For Temple, Calls It Gift Of Truth "}
          description="Eight months after the Supreme Court verdict paving the way for the Ram Mandir in Ayodhya's Ramjanmabhoomi, PM Narendra Modi to laid the foundation stone for the temple in Ayodhya."
          imgPath={pm4}
          time="6 hours ago"/>
        <NewsPost
          channel="Northeast Now"
          headline={"UPSC civil service candidate links Rahul Gandhi with Narendra Modi"}
          description="Rahul Gandhi is definitely the biggest critic of Prime Minister Narendra Modi. But, a civil service candidate has linked the two arch political rivals."
          imgPath={pm5}
          time="23 hours ago"/>
        <NewsPost
          channel="ThePrint"
          headline={"Japan, Swadeshi Jagran Manch and BCCI: Why Modi’s China headache just got bigger this week"}
          description="From Japan’s demands to Jaishankar’s comment on China, it is clear why India continues to choose ‘strategic autonomy’, the 21st-century version of ‘non-alignment’."
          imgPath={pm6}
          time="3 hours ago"/>



      </div>
    );
  }
}

News.defaultProps = {
  actions: {},
};

News.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(News);
