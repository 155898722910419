import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NewsPost from "./NewsPost";
import us1 from "../../../assets/us1.jpg";

class News extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="news-container">

        <NewsPost
          channel="NDTV "
          headline={"Results Start Next Week : Donald Trump Defies Election Loss, Block Joe Biden"}
          imgPath={us1}
          time=" 20 hours ago" />
       </div>
    );
  }
}

News.defaultProps = {
  actions: {},
};

News.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */)  => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(News);
