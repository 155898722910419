import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row} from 'reactstrap';
import Footer from './Common/Footer';
import Header from './CustomPage/Header';
import {connect} from 'react-redux';
import {setDocumentTitle} from "../utils/general";
import * as usersActions from '../actions/usersActions';
import {bindActionCreators} from "redux";
import Noty from "noty";
import { history } from '../store/configureStore';
import {Link} from "react-router-dom";


class LoginPage extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      email: '',
      password: '',
    };

    this.goToSignUp = this.goToSignUp.bind(this);
    this.onEmailChanged = this.onEmailChanged.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.onSignInClicked = this.onSignInClicked.bind(this);

    this.createStarted = false;

  }

  componentDidMount() {
    setDocumentTitle('Login Page');
  }

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    if (
      this.createStarted &&
      this.props.login !== nextProps.login
    ) {
      this.handleStatusChange(nextProps);
    }
  }

  handleStatusChange(props) {
    if (
      !props.login.saving && props.login.saved
    ) {
      new Noty({
        type: 'success',
        layout: 'bottomLeft',
        text: 'New user created successfully.',
      }).show();

      this.createStarted = false;
    } else if (
      !props.login.saving && !props.login.saved
    ) {
      let errorMessage = props.login.error;
      if (!errorMessage) {
        errorMessage = 'Failed to login due to server error.';
      }

      new Noty({
        type: 'error',
        layout: 'bottomLeft',
        text: errorMessage,
      }).show();

      this.createStarted = false;
    }
  }

  onEmailChanged(event) {
    this.setState({
      email: event.target.value,
    });
  }

  onPasswordChanged(event) {
    this.setState({
      password: event.target.value,
    });
  }

  goToSignUp() {
    history.push(`/sign-up`);
  }

  onSignInClicked() {
    this.props.actions.userLoginStarting();
    this.props.actions.userLogin(this.state);
    this.createStarted = true;
  }


  render() {
    const {email, password} = this.state;
    const {login} = this.props;
    const loginData = login;

    let btnEnter;
    if (loginData && loginData.saving) {
      btnEnter = (
        <Button color="success" disabled>
          <i className="fa fa-spinner fa-spin"/>
          &nbsp;
          Please wait...
        </Button>
      );
    } else {
      btnEnter = (
        <Button color="primary" onClick={this.onSignInClicked}>
          <i className="fa fa-sign-in"/>
          &nbsp;
          Sign In
        </Button>
      );
    }

    return (
      <div className="custom-page login-page">
        <Header pageTitle="Sign In "/>

        <Container>
          <Card style={{width: '35rem'}}>
            <CardBody>
              <Row>
                <Col sm="12">
                  <FormGroup className="">
                    <Label for="email">Email</Label>
                    <Input
                      autoFocus
                      type="text"
                      name="email"
                      id="email"
                      placeholder=""
                      value={email}
                      onChange={this.onEmailChanged}
                    />
                  </FormGroup>
                  <FormGroup className="">
                    <Label for="password">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder=""
                      value={password}
                      onChange={this.onPasswordChanged}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <div className="mt-0">
            <Card style={{width: '35rem'}}>
              <CardBody>
                <Row>
                  <Col>
                    <Button color="success" size="sm" onClick={this.goToSignUp}>
                      Sign Up
                    </Button>

                    <small>
                      <br/><br />
                      <a href=""> Forgot Password</a>
                    </small>
                  </Col>

                  <Col className="text-right">
                    {btnEnter}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Container>

        <Footer/>
      </div>
    );
  }
}


LoginPage.defaultProps = {
  actions: {},
  login: {},
};

LoginPage.propTypes = {
  actions: PropTypes.object,
  login: PropTypes.object,
};

const mapStateToProps = (state => ({
  login: state.login,

}));

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, usersActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
