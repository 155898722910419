import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody,
  Container, CardHeader,
  Row, Button, Col, Form, InputGroup, InputGroupAddon, Input,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Header from "./CustomPage/Header";
import Footer from "./Common/Footer";
import ManageFileManagerData from "./FileManagerPage/ManageFileManagerData";
import UploadFileModal from "./FileManagerPage/UploadFileModal";

// import * as actionsActions from '../actions/actions';

class FileManagerPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showUploadFileModal: false,
    };

    this.toggleUploadFileModal = this.toggleUploadFileModal.bind(this);
  }

  toggleUploadFileModal() {
    const { showUploadFileModal } = this.state;
    this.setState({
      showUploadFileModal: !showUploadFileModal,
    });
  }

  render() {
    const { showUploadFileModal} = this.state;
    let uploadFileModal = null;

    if (showUploadFileModal) {
      uploadFileModal = (
        <UploadFileModal
          toggleModal={this.toggleUploadFileModal}
        />
      );
    }

    return (
      <div className="custom-page file-manager-page">
        <Header pageTitle="File Manager"/>
        <Container>
          <Card>
            <CardHeader>
              <Row>

                <Col xs={6} className="d-flex">
                  <Form>
                    <InputGroup className="">
                      <Input size="sm" placeholder="Search" />
                      <InputGroupAddon addonType="append">
                        <Button size="sm" type="button" color="success" >
                          <i className="fa fa-search" />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </Form>

                </Col>

                <Col className='text-right'>
                  <Button size="sm" type="button" color="info" onClick={this.toggleUploadFileModal} title="Upload">
                    <i className="fa fa-upload" />&nbsp;Upload
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>

              <ManageFileManagerData/>

            </CardBody>
          </Card>
        </Container>
        <Footer/>
        {uploadFileModal}
      </div>
    );
  }
}

FileManagerPage.defaultProps = {
  actions: {},
};

FileManagerPage.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */)  => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileManagerPage);
