import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col, Input, InputGroup, InputGroupAddon,
  Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DictionaryWord from '../../Common/DictionaryWord';
import * as dictionaryActions from '../../../actions/dictionaryActions';

class WordsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: '',
    };

    this.onSearchQueryChanged = this.onSearchQueryChanged.bind(this);
    this.setSearchTimeout = this.setSearchTimeout.bind(this);

    this.searchTimeout = null;
  }

  componentDidMount() {
    this.doSearch();
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  clearTimeout() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = null;
    }
  }

  setSearchTimeout() {
    this.clearTimeout();
    this.searchTimeout = setTimeout(() => {
      this.doSearch();
    }, 300);
  }

  onSearchQueryChanged(event) {
    console.log(event);

    this.setState({
      searchQuery: event.target.value,
    });

    this.setSearchTimeout();
  }

  doSearch() {
    const { prefixStr, dictionaryWordsInPrefix } = this.props;
    const { searchQuery } = this.state;

    if (!dictionaryWordsInPrefix[prefixStr] || (
        !dictionaryWordsInPrefix[prefixStr].loading && !dictionaryWordsInPrefix[prefixStr].loaded
      ) || (
        !dictionaryWordsInPrefix[prefixStr].searchQuery !== searchQuery
      )) {
      this.props.actions.getDictWordsInPrefixStarting(prefixStr, searchQuery);
      this.props.actions.getDictWordsInPrefix(prefixStr, searchQuery);
    }
  }

  getWords() {
    const { prefixStr, dictionaryWordsInPrefix } = this.props;
    if (!dictionaryWordsInPrefix[prefixStr]) {
      return null;
    }

    if (dictionaryWordsInPrefix[prefixStr].loading) {
      return (
        <Col xs={12} md={6} lg={4} xl={3}>
          <p>Loading..&hellip;</p>
        </Col>
      );
    }

    if (!dictionaryWordsInPrefix[prefixStr].loaded) {
      return (
        <Col xs={12} md={6} lg={4} xl={3}>
          <p>Loading..&hellip;</p>
        </Col>
      );
    }

    const { ids, map } = dictionaryWordsInPrefix[prefixStr];
    const wordEls = [];

    ids.forEach((wordId) => {
      const word = map[wordId];
      wordEls.push((
        <DictionaryWord key={wordId} word={word.word} />
      ));
    });

    return wordEls;
  }

  render() {
    const { searchQuery } = this.state;
    const { prefixStr, dictionaryWordsInPrefix } = this.props;

    let btnSearch;
    if (dictionaryWordsInPrefix[prefixStr] && dictionaryWordsInPrefix[prefixStr].loading) {
      btnSearch = (
        <Button type="button" disabled={true} color="success">
          <i className="fa fa-spinner fa-spin" />
        </Button>
      );
    } else {
      btnSearch = (
        <Button type="button" color="success" onClick={this.setSearchTimeout}>
          <i className="fa fa-search" />
        </Button>
      );
    }

    const matchCount = dictionaryWordsInPrefix[prefixStr] && dictionaryWordsInPrefix[prefixStr].matchCount ? dictionaryWordsInPrefix[prefixStr].matchCount : 0;

    const wordEls = this.getWords();

    let heading;

    if (searchQuery) {
      if (matchCount) {
        heading = `Words using prefix ${prefixStr} (${matchCount} matches)`;
      } else {
        heading = 'No matches found.';
      }
    } else {
      if (matchCount) {
        heading = `Words using prefix ${prefixStr} (${matchCount})`;
      } else {
        heading = 'No words found.';
      }
    }

    return (
      <div>
        <div className="pb-2">
          <Row>
            <Col xs={12} md={8}>
              <p className="m-0">{heading}</p>
            </Col>

            <Col xs={12} md={4} className="mt-2 mb-2 mt-md-0 mb-md-0">
              <InputGroup >
                <Input
                  type="text"
                  placeholder="search"
                  value={searchQuery}
                  onChange={this.onSearchQueryChanged}
                />

                <InputGroupAddon addonType="append">
                  {btnSearch}
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        </div>

        <Row>
          {wordEls}
        </Row>
      </div>
    );
  }
}

WordsList.defaultProps = {
  actions: {},
  dictionaryWordsInPrefix: {},
};

WordsList.propTypes = {
  actions: PropTypes.object,
  dictionaryWordsInPrefix: PropTypes.object,
  prefixStr: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  dictionaryWordsInPrefix: state.dictionaryWordsInPrefix,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, dictionaryActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WordsList);
