import React from 'react';
import PropTypes from 'prop-types';
import { toHumanFormat } from '../../utils/numberFormat';

class SearchCount extends React.Component {
  render() {
    const { searchCount } = this.props;
    const searchCountText = toHumanFormat(searchCount);

    return (
      <div
        className="d-inline-block"
        title={`Looked up ${searchCount} time${searchCount === 1 ? '' : 's'}`}
      >
        {(searchCount > 0) && (
          <span className="text-muted text-small">
          <i className="fa fa-star" />
            &nbsp;
            {searchCountText}
        </span>
        )}
      </div>
    );
  }
}

SearchCount.defaultProps = {
  searchCount: 0,
};

SearchCount.propTypes = {
  searchCount: PropTypes.number,
};

export default SearchCount;
