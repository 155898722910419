import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import MetaSearchResult from './MetaSearchResult';
import Redirect from '../Common/Redirect';
import PersonResult from './PersonResult';
import CompanyResult from "./CompanyResult";
import PlaceResult from "./PlaceResult";
import BookResult from "./BookResult";
import MovieResult from "./MovieResult";
import DictionaryPrefixResult from './DictionaryPrefixResult';
import FixedTextResult from './FixedTextResult';
import DictionaryWordResult from './DictionaryWordResult';
import RedirectToDefaultSearchEngine from './RedirectToDefaultSearchEngine';
import CalculatorResult from "./CalculatorResult";
import PhotosTimelineResult from "./PhotosTimelineResult";
import PostTimelineResult from "./PostTimelineResult";
import FilesResult from "./FilesResult";
import VideoResult from "./VideoResult";
import BlogArticleResult from "./BlogArticleResult";
import ProductResult from "./ProductResult";
import ProductsListResult from "./ProductsListResult";
import CalendarResult from "./CalendarResult";


class SearchResult extends React.Component {
  render() {
    const { query, resultData } = this.props;
    const { resultType } = resultData.result;

    switch (resultType) {
      case 'expression': {
        return (
          <CalculatorResult
            query={query}
            resultData={resultData}
          />
        );
      }

      case 'meta_search': {
        return (
          <MetaSearchResult
            query={query}
            resultData={resultData}
          />
        );
      }

      case 'fixed_text': {
        return (
          <FixedTextResult
            query={query}
            resultData={resultData}
          />
        )
      }

      case 'redirect': {
        const url = resultData.result.targetUrl;
        if (url) {
          return (
            <Card className="redirect-result">
              <CardBody>
                <Redirect to={url} />
              </CardBody>
            </Card>
          );
        } else {
          return (
            <div>
              No target url specified for redirect.
            </div>
          );
        }
      }

      case 'dictionary_prefix': {
        return (
          <DictionaryPrefixResult
            query={query}
            resultData={resultData}
          />
        );
      }

      case 'dictionary_word': {
        return (
          <DictionaryWordResult
            query={query}
            resultData={resultData}
          />
        );
      }

      case 'person': {
        return (
          <PersonResult
            query={query}
            resultData={resultData}
          />
        );
      }

      case 'company': {
        return (
          <CompanyResult
            query={query}
            resultData={resultData}
          />
        );
      }
      case 'place': {
        return (
          <PlaceResult
            query={query}
            resultData={resultData}
          />
        );
      }
      case 'book': {
        return (
          <BookResult
            query={query}
            resultData={resultData}
          />
        );
      }

      case 'movie': {
        return (
          <MovieResult
            query={query}
            resultData={resultData}
          />
        );
      }

      case 'photos_timeline': {
        return (
          <PhotosTimelineResult
            query={query}
            resultData={resultData}
          />
        );
      }

      case 'post_timeline': {
        return (
          <PostTimelineResult
            query={query}
            resultData={resultData}
          />
        );
      }

      case 'files': {
        return (
          <FilesResult
            query={query}
            resultData={resultData}
          />
        );
      }
      case 'video': {
        return (
          <VideoResult
            query={query}
            resultData={resultData}
          />
        );
      }

      case 'blog_article': {
        return (
          <BlogArticleResult
            query={query}
            resultData={resultData}
          />
        );
      }

      case 'calendar': {
        return (
          <CalendarResult
            query={query}
            resultData={resultData}
          />
        );
      }

      case 'product': {
        return (
          <ProductResult
            query={query}
            resultData={resultData}
          />
        );
      }

      case 'products_list': {
        return (
          <ProductsListResult
            query={query}
            resultData={resultData}
          />
        );
      }

      case '':
      case 'none': {
        return (
          <RedirectToDefaultSearchEngine
            query={query}
          />
        );
      }

      default: {
        return (
          <Card style={{ minHeight: '10vh' }}>
            <CardBody className="d-flex flex-column justify-content-center">
              <p className="m-0">
                <i className="fa fa-exclamation-circle" />
                &nbsp;&nbsp;
                This result type does not have a corresponding view.
              </p>
            </CardBody>
          </Card>
        );
      }
    }
  }
}

SearchResult.defaultProps = {
  query: '',
};

SearchResult.propTypes = {
  query: PropTypes.string,
  resultData: PropTypes.object.isRequired,
};

export default SearchResult;
