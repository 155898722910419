import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import * as actionsActions from '../actions/actions';

class SocialProfiles extends React.Component {
  render() {
    return (
      <div className="social-profiles">
        <h6 className="m-0"> Profiles</h6>
        <Row className="mt-3">
          <Col className="text-center">
            <a href={""}>
              <i className="fa fa-wikipedia-w" />
              <p> Wikipedia</p>
            </a>

          </Col>
          <Col className="text-center">
            <a href={""}>
              <i className="fa fa-twitter " />
              <p> Twitter</p>
            </a>

          </Col>
          <Col className="text-center">
            <a href={""}>
              <i className="fa fa-facebook" />
              <p> Facebook</p>
            </a>

          </Col>
          <Col className="text-center">
            <a href={""}>
              <i className="fa fa-instagram" />
              <p> Instagram</p>
            </a>

          </Col>
          <Col className="text-center">
            <a href={""}>
              <i className="fa fa-youtube" />
              <p> Youtube</p>
            </a>

          </Col>
        </Row>
      </div>
    );
  }
}

SocialProfiles.defaultProps = {
  actions: {},
};

SocialProfiles.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialProfiles);
