import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col,
  Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import * as actionsActions from '../actions/actions';

class BasicDetails extends React.Component {
  render() {
    return (
      <div className="movie-details">
        <Card>
          <CardBody>
            <Row className="">
              <Col xs="12" className="">
                <h5> Jungle Book
                </h5>
              </Col>
            </Row>

            <div>
              <p className="mt-3">
                Join Mowgli, Bagheera, and Baloo on an unforgettable journey through the jungle. Learn all about the
                bare necessities of life, and what it means to find a place where you truly belong. This deluxe
                storybook retelling of the classic animated film is a treasure to behold.
              </p>
              <hr />
              <p><b> Author : </b> Parragon Books Ltd </p>
              <p><b> Publisher :</b> Parragon Book Service Limited, 2016 </p>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}


BasicDetails.defaultProps = {
  actions: {},
};

BasicDetails.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);

