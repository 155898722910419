import { cloneDeep } from 'lodash';
import {
  DELETE_FILE_STARTING,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function deleteFileReducer(state = initialState.deleteFile, action) {
  switch (action.type) {
    case DELETE_FILE_STARTING: {
      const { fileId } = action;
      const newState = cloneDeep(state);

      if (!newState[fileId]) {
        newState[fileId] = cloneDeep(initialState.deleteFile.fileId);
      }

      const deleteData = newState[fileId];
      deleteData.saving = true;
      deleteData.saved = false;
      deleteData.error = null;
      return newState;
    }

    case DELETE_FILE_SUCCESS: {
      const { fileId } = action;
      const newState = cloneDeep(state);

      if (!newState[fileId]) {
        newState[fileId] = cloneDeep(initialState.deleteFile.fileId);
      }

      const deleteData = newState[fileId];
      deleteData.saving = false;
      deleteData.saved = true;
      return newState;
    }

    case DELETE_FILE_ERROR: {
      const { fileId } = action;
      const newState = cloneDeep(state);

      if (!newState[fileId]) {
        newState[fileId] = cloneDeep(initialState.deleteFile.fileId);
      }

      const deleteData = newState[fileId];
      deleteData.saving = false;
      deleteData.saved = false;
      deleteData.error = action.err ? action.err.message : 'Failed to delete File';
      return newState;
    }

    default: {
      return state;
    }
  }
}
