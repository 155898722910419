import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Noty from 'noty';
import MDEditor from '@uiw/react-md-editor'
import ReactQuill from 'react-quill';
import {history} from '../../store/configureStore';
import ResultTypeHelperText from './ResultTypeHelperText';
import * as searchActions from '../../actions/searchActions';
import {setStateFromEvent, setStateFromValue, setStateFromChecked} from '../../utils/general';
import ManageFilesTable from "./ManageFilesTable";
import FileUpload from "./FileUpload";
import arrayMove from 'array-move';
import FeaturedImageUpload from "./FeaturedImageUpload";
import ManageBioTable from "./ManageBioTable";

const modules = {
  toolbar: [
    [{'header': [1, 2, 3, 4, 5, 6, false]}],
    [
      {'font': []}, {'size': []}, {'color': []}, {'background': []}, {'align': []},
    ],
    [
      'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'clean',
    ],
    [
      {'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}, {'direction': 'rtl'},
    ],
    ['link', 'script'],
  ],
};

const formats = [
  'header', 'font', 'size', 'color', 'background',
  'bold', 'italic', 'underline', 'strike', 'align', 'blockquote', 'clean',
  'list', 'bullet', 'indent', 'code-block',
  'link', 'script'
];

class EditResultForm extends React.Component {
  static getStateFromProps(props) {
    const {resultData, defaultResultType} = props;

    let {
      resultType,

      fixedText,
      fixedTextType,
      hideCardHeader,

      targetUrl,
      fileIds, prefixStr, word,
      lang,
      meanings,
      videoUrl,
      videoWidth,
      videoHeight,
      videoAutoplay,
      videoSource,

      aliasQuery,

      blogTitle,
      blogExcerpt,
      blogContent,
      blogFeaturedImage,
      blogImageCaption,
      blogHashtags,
      socialSharingEnabled,
      showPostSuggestions,
      blogAuthor,
      blogTimestamp,

      journalNotes,

      synonyms,
      antonyms,
      isPrivate,
      allowedTokens,
      searchCount,
      notes,

      productTitle,
      productBrand,
      productMsrp,
      productSellingPrice,
      productCurrency,
      productQtyInStock,
      productDescription,
      productSpecifications,

      placeType,
      placeName,
      placeDescription,
      placeAlias,
      placeAddress,
      placeExtraInfo,
      placeLat,
      placeLng,

      personName,
      personIntro,
      personShortBio,
      personProfiles,
      personContactDetails,
      personBio,


    } = resultData.result;

    if (defaultResultType) {
      resultType = defaultResultType;
    } else if (!resultType) {
      resultType = 'none';
    }

    if (!hideCardHeader) {
      hideCardHeader = false;
    }

    if (!fixedTextType) {
      fixedTextType = '';
    }

    if (!fixedText) {
      fixedText = '';
    }

    if (!targetUrl) {
      targetUrl = '';
    }

    if (!fileIds) {
      fileIds = [];
    }

    if (!prefixStr) {
      prefixStr = '';
    }

    if (!word) {
      word = '';
    }

    if (!lang) {
      lang = '';
    }

    if (!meanings) {
      meanings = '';
    }

    if (!synonyms) {
      synonyms = ''
    }

    if (!antonyms) {
      antonyms = '';
    }

    if (!aliasQuery) {
      aliasQuery = '';
    }

    if (!isPrivate) {
      isPrivate = false;
    }

    if (!allowedTokens) {
      allowedTokens = '';
    }

    if (!searchCount) {
      searchCount = 0;
    }

    if (!notes) {
      notes = '';
    }

    if (!videoUrl) {
      videoUrl = '';
    }

    if (!videoSource) {
      videoSource = '';
    }

    if (!videoWidth) {
      videoWidth = '';
    }

    if (!videoHeight) {
      videoHeight = '';
    }

    if (!videoAutoplay) {
      videoAutoplay = '';
    }

    if (!blogTitle) {
      blogTitle = '';
    }

    if (!blogContent) {
      blogContent = '';
    }

    if (!blogExcerpt) {
      blogExcerpt = '';
    }

    if (!blogFeaturedImage) {
      blogFeaturedImage = '';
    }

    if (!blogImageCaption) {
      blogImageCaption = '';
    }

    if (!blogHashtags) {
      blogHashtags = '';
    }

    if (!socialSharingEnabled) {
      socialSharingEnabled = false;
    }

    if (!showPostSuggestions) {
      showPostSuggestions = false;
    }

    if (!blogAuthor) {
      blogAuthor = '';
    }

    if (!blogTimestamp) {
      blogTimestamp = '';
    }


    if (!journalNotes) {
      journalNotes = '';
    }

    if (!productTitle) {
      productTitle = '';
    }

    if (!productBrand) {
      productBrand = '';
    }

    if (!productMsrp) {
      productMsrp = '';
    }
    if (!productSellingPrice) {
      productSellingPrice = '';
    }
    if (!productCurrency) {
      productCurrency = '';
    }
    if (!productQtyInStock) {
      productCurrency = '';
    }
    if (!productDescription) {
      productDescription = '';
    }
    if (!productSpecifications) {
      productSpecifications = '';
    }
    if (!placeType) {
      placeType = '';
    }
    if (!placeName) {
      placeName = '';
    }
    if (!placeDescription) {
      placeDescription = '';
    }
    if (!placeAlias) {
      placeAlias = '';
    }
    if (!placeAddress) {
      placeAddress = '';
    }
    if (!placeExtraInfo) {
      placeExtraInfo = '';
    }
    if (!placeLat) {
      placeLat = '';
    }
    if (!placeLng) {
      placeLng = '';
    }

    if (!personName) {
      personName = '';
    }

    if (!personIntro) {
      personIntro = '';
    }

    if (!personShortBio) {
      personShortBio = '';
    }

    if (!personProfiles) {
      personProfiles = '';
    }

    if (!personContactDetails) {
      personContactDetails = '';
    }
    if (!personBio) {
      personBio = [];
    }

    return {
      resultType,

      fixedText,
      fixedTextType,
      hideCardHeader,

      targetUrl,
      fileIds,

      videoUrl,
      videoWidth,
      videoHeight,
      videoAutoplay,
      videoSource,

      aliasQuery,

      blogTitle,
      blogExcerpt,
      blogContent,
      blogFeaturedImage,
      blogImageCaption,
      blogHashtags,
      socialSharingEnabled,
      showPostSuggestions,
      blogAuthor,
      blogTimestamp,

      journalNotes,

      prefixStr,
      word,
      lang,
      meanings,
      synonyms,
      antonyms,

      isPrivate,
      allowedTokens,
      searchCount,
      notes,

      productTitle,
      productBrand,
      productMsrp,
      productSellingPrice,
      productCurrency,
      productQtyInStock,
      productDescription,
      productSpecifications,

      placeType,
      placeName,
      placeDescription,
      placeAlias,
      placeAddress,
      placeExtraInfo,
      placeLat,
      placeLng,

      personName,
      personIntro,
      personShortBio,
      personProfiles,
      personContactDetails,
      personBio,
    };
  }

  constructor(props) {
    super(props);


    this.state = EditResultForm.getStateFromProps(props);

    this.onFixedTextChanged = setStateFromValue.bind(this, this, 'fixedText');
    this.onFixedTextChanged2 = setStateFromEvent.bind(this, this, 'fixedText');
    this.onFixedTextTypeChanged = setStateFromEvent.bind(this, this, 'fixedTextType');
    this.onHideCardHeaderChanged = setStateFromChecked.bind(this, this, 'hideCardHeader');
    this.onResultTypeChanged = setStateFromEvent.bind(this, this, 'resultType');
    this.onTargetUrlChanged = setStateFromEvent.bind(this, this, 'targetUrl');
    this.onPrefixStrChanged = setStateFromEvent.bind(this, this, 'prefixStr');
    this.onWordChanged = setStateFromEvent.bind(this, this, 'word');
    this.onLangChanged = setStateFromEvent.bind(this, this, 'lang');
    this.onMeaningsChanged = setStateFromValue.bind(this, this, 'meanings');
    this.onSynonymsChanged = setStateFromValue.bind(this, this, 'synonyms');
    this.onAntonymsChanged = setStateFromValue.bind(this, this, 'antonyms');

    this.onAliasQueryChanged = setStateFromEvent.bind(this, this, 'aliasQuery');

    this.onVideoUrlChanged = setStateFromEvent.bind(this, this, 'videoUrl');
    this.onVideoSourceChanged = setStateFromEvent.bind(this, this, 'videoSource');
    this.onVideoWidthChanged = setStateFromEvent.bind(this, this, 'videoWidth');
    this.onVideoHeightChanged = setStateFromEvent.bind(this, this, 'videoHeight');
    this.onVideoAutoplayChanged = setStateFromChecked.bind(this, this, 'videoAutoplay');

    this.onBlogTitleChanged = setStateFromEvent.bind(this, this, 'blogTitle');
    this.onBlogExcerptChanged = setStateFromValue.bind(this, this, 'blogExcerpt');
    this.onBlogContentChanged = setStateFromValue.bind(this, this, 'blogContent');
    this.onBlogImageCaptionChanged = setStateFromEvent.bind(this, this, 'blogImageCaption');
    this.onBlogAuthorChanged = setStateFromEvent.bind(this, this, 'blogAuthor');
    this.onBlogTimestampChanged = setStateFromEvent.bind(this, this, 'blogTimestamp');
    this.onBlogHashtagsChanged = setStateFromEvent.bind(this, this, 'blogHashtags');
    this.onSocialSharingChanged = setStateFromChecked.bind(this, this, 'socialSharingEnabled');
    this.onShowPostSuggestionsChanged = setStateFromChecked.bind(this, this, 'showPostSuggestions');
    this.onBlogFeaturedImageUploaded = this.onBlogFeaturedImageUploaded.bind(this);
    this.onJournalNotesChanged = setStateFromValue.bind(this, this, 'journalNotes');
    this.onSearchCountChanged = setStateFromEvent.bind(this, this, 'searchCount');
    this.onNotesChanged = setStateFromValue.bind(this, this, 'notes');

    this.onIsPrivateChanged = setStateFromChecked.bind(this, this, 'isPrivate');
    this.onAllowedTokensChanged = setStateFromEvent.bind(this, this, 'allowedTokens');
    this.onFileUploaded = this.onFileUploaded.bind(this);
    this.onFileAction = this.onFileAction.bind(this);

    this.onProductTitleChanged = setStateFromEvent.bind(this, this, 'productTitle');
    this.onProductBrandChanged = setStateFromEvent.bind(this, this, 'productBrand');
    this.onProductMsrpChanged = setStateFromEvent.bind(this, this, 'productMsrp');
    this.onProductSellingPriceChanged = setStateFromEvent.bind(this, this, 'productSellingPrice');
    this.onProductCurrencyChanged = setStateFromEvent.bind(this, this, 'productCurrency');
    this.onProductQtyInStockChanged = setStateFromEvent.bind(this, this, 'productQtyInStock');
    this.onProductDescriptionChanged = setStateFromValue.bind(this, this, 'productDescription');
    this.onProductSpecificationsChanged = setStateFromEvent.bind(this, this, 'productSpecifications');

    this.onPlaceTypeChanged = setStateFromEvent.bind(this, this, 'placeType');
    this.onPlaceNameChanged = setStateFromEvent.bind(this, this, 'placeName');
    this.onPlaceDescriptionChanged = setStateFromValue.bind(this, this, 'placeDescription');
    this.onPlaceAliasChanged = setStateFromEvent.bind(this, this, 'placeAlias');
    this.onPlaceAddressChanged = setStateFromEvent.bind(this, this, 'placeAddress');
    this.onPlaceExtraInfoChanged = setStateFromEvent.bind(this, this, 'placeExtraInfo');
    this.onPlaceLatChanged = setStateFromEvent.bind(this, this, 'placeLat');
    this.onPlaceLngChanged = setStateFromEvent.bind(this, this, 'placeLng');

    this.onPersonNameChanged = setStateFromEvent.bind(this, this, 'personName');
    this.onPersonIntroChanged = setStateFromEvent.bind(this, this, 'personIntro');
    this.onPersonShortBioChanged = setStateFromEvent.bind(this, this, 'personShortBio');
    this.addBioRow = this.addBioRow.bind(this);
    this.editBioRow = this.editBioRow.bind(this);

    this.openSearchPage = this.openSearchPage.bind(this);
    this.saveSearchResult = this.saveSearchResult.bind(this);

    this.saveStarted = false;
  }

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    const {query} = this.props;

    if (this.props.resultData !== nextProps.resultData) {
      this.setState(EditResultForm.getStateFromProps(nextProps));
    }

    if (
      this.saveStarted &&
      this.props.saveSearchResult !== nextProps.saveSearchResult &&
      nextProps.saveSearchResult[query]
    ) {
      this.handleStatusChange(nextProps);
    }
  }

  handleStatusChange(props) {
    const {query} = props;

    if (
      props.saveSearchResult[query] && !props.saveSearchResult[query].saving && props.saveSearchResult[query].saved
    ) {
      new Noty({
        type: 'success',
        layout: 'bottomLeft',
        text: 'Changes have been saved.',
      }).show();

      this.saveStarted = false;
    } else if (
      props.saveSearchResult[query] && !props.saveSearchResult[query].saving && !props.saveSearchResult[query].saved
    ) {
      let errorMessage = props.saveSearchResult[query].error;
      if (!errorMessage) {
        errorMessage = 'Failed to save result data due to server error.';
      }

      new Noty({
        type: 'error',
        layout: 'bottomLeft',
        text: errorMessage,
      }).show();

      this.saveStarted = false;
    }
  }

  openSearchPage() {
    const {query} = this.props;
    history.push(`/search?q=${encodeURIComponent(query)}`);
  }

  onBlogFeaturedImageChanged(file) {
    const featuredImage = file._id;

    this.setState({
      featuredImage,
    });
  }

  onFileUploaded(file) {
    const fileId = file._id;
    const {fileIds} = this.state;

    if (fileIds.indexOf(fileId) === -1) {
      fileIds.push(file._id);

      this.setState({
        fileIds,
      });
    }
  }

  onBlogFeaturedImageUploaded(file) {
    this.setState({
      blogFeaturedImage: file._id,
    });
  }

  onFileAction(action, fileId, index) {
    const {fileIds} = this.state;
    let newFileIds;

    switch (action) {
      case 'delete': {
        newFileIds = fileIds.filter((f) => f !== fileId);
        break;
      }

      case 'moveToTop': {
        const otherFileIds = fileIds.filter((f) => f !== fileId);
        newFileIds = [fileId].concat(otherFileIds);
        break;
      }

      case 'moveUp': {
        newFileIds = arrayMove(fileIds, index, index - 1);
        break;
      }

      case 'moveDown': {
        newFileIds = arrayMove(fileIds, index, index + 1);
        break;
      }

      default: {
        return;
      }
    }

    this.setState({
      fileIds: newFileIds,
    });
  }

  addBioRow(data) {
    const { personBio } = this.state;
    console.log(personBio);
    //let content = (<div> Data is Saved </div>);

    personBio.push(data);

    this.setState({
      personBio,
    });

    new Noty({
      type: 'success',
      layout: 'bottomLeft',
      text: 'New row added.',
    }).show();
  }

  editBioRow(data) {
    const { personBio, editingBioIndex } = this.state;

    if (editingBioIndex === -1) {
      return;
    }
    // let content = (<div> Modified Data is  Saved </div>);
    personBio[editingBioIndex] = data;

    this.setState({
      personBio,
      showEditBioForm : false,
    });

    new Noty({
      type: 'success',
      layout: 'bottomLeft',
      text: 'Data updated.',
    }).show();
  }

  saveSearchResult() {
    // TODO Validate data
    const {query} = this.props;
    this.props.actions.saveSearchResultStarting(query);
    this.props.actions.saveSearchResult(query, this.state);
    this.saveStarted = true;
  }

  render() {
    const {
      resultType,

      aliasQuery, targetUrl,

      fixedText, fixedTextType,
      hideCardHeader,

      prefixStr, word, lang, meanings,
      synonyms, antonyms,

      fileIds,
      videoUrl,
      videoWidth,
      videoHeight,
      videoAutoplay,
      videoSource,

      blogTitle,
      blogExcerpt,
      blogContent,
      blogFeaturedImage,
      blogImageCaption,
      blogHashtags,
      socialSharingEnabled,
      showPostSuggestions,
      blogAuthor,
      blogTimestamp,

      journalNotes,
      isPrivate,
      allowedTokens,
      searchCount,
      notes,

      productTitle,
      productBrand,
      productMsrp,
      productSellingPrice,
      productCurrency,
      productQtyInStock,
      productDescription,
      productSpecifications,

      placeType,
      placeName,
      placeDescription,
      placeAlias,
      placeAddress,
      placeExtraInfo,
      placeLat,
      placeLng,


      personName,
      personIntro,
      personShortBio,
      personProfiles,
      personContactDetails,
      personBio,


    } = this.state;

    const {query, isInsideModal, saveSearchResult} = this.props;
    const saveSearchResultStatus = saveSearchResult[query];

    let dynamicFields = null;
    let dynamicFields2 = null;
    let dynamicFields3 = null;

    if (isPrivate) {
      dynamicFields3 = (
        <div className="mt-4">
          <FormGroup>
            <Label for="allowedTokens">Allowed Tokens</Label>
            <Input
              type="textarea"
              name="allowedTokens"
              id="allowedTokens"
              placeholder=""
              value={allowedTokens}
              onChange={this.onAllowedTokensChanged}
            />
          </FormGroup>
        </div>
      );
    }

    if (resultType === 'alias') {
      dynamicFields = (
        <div className="mt-4">
          <FormGroup>
            <Label for="aliasQuery">Alias to</Label>
            <Input
              type="text"
              name="aliasQuery"
              id="aliasQuery"
              placeholder="Enter the alias here"
              value={aliasQuery}
              onChange={this.onAliasQueryChanged}
            />
          </FormGroup>
        </div>
      );
    } else if (resultType === 'redirect') {
      dynamicFields = (
        <div className="mt-4">
          <FormGroup>
            <Label for="targetURL">Redirect to URL</Label>
            <Input
              type="text"
              name="targetURL"
              id="targetURL"
              placeholder="Enter the URL here"
              value={targetUrl}
              onChange={this.onTargetUrlChanged}
            />
          </FormGroup>
        </div>
      );
    } else if (resultType === 'fixed_text') {
      if (fixedTextType === 'markdown') {
        dynamicFields2 = (
          <FormGroup>
            <Label for="fixedText">Enter Text below</Label>
            <MDEditor
              name="fixedText"
              value={fixedText}
              onChange={this.onFixedTextChanged}
              height={400}
            />
          </FormGroup>
        );
      } else if (fixedTextType === 'html') {
        dynamicFields2 = (
          <FormGroup>
            <Label for="fixedText">Enter Raw HTML below</Label>
            <Input
              name="fixedText"
              type="textarea"
              value={fixedText}
              onChange={this.onFixedTextChanged2}
              rows={8}
            />
          </FormGroup>
        );
      } else {
        dynamicFields2 = (
          <FormGroup>
            <Label for="fixedText">Enter Text below</Label>
            <ReactQuill
              className="fixed-text-editor"
              theme="snow"
              value={fixedText}
              onChange={this.onFixedTextChanged}
              modules={modules}
              formats={formats}
            />
          </FormGroup>
        );
      }

      dynamicFields = (
        <div className="mt-4">
          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                <Label for="fixedTextType">Text Type</Label>
                <Input
                  type="select"
                  name="fixedTextType"
                  id="fixedTextType"
                  value={fixedTextType}
                  onChange={this.onFixedTextTypeChanged}
                >
                  <option value="wysiwig">WYSIWYG</option>
                  <option value="markdown">Markdown</option>
                  <option value="html">HTML</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>

          {dynamicFields2}

          <Row>
            <Col xs={12} md={12}>
              <FormGroup className="pl-4">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={hideCardHeader}
                    onChange={this.onHideCardHeaderChanged}
                  />&nbsp;
                  Hide Card Header
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </div>
      )
    } else if (resultType === 'dictionary_prefix' || resultType === 'dictionary_word') {
      if (resultType === 'dictionary_prefix') {
        dynamicFields2 = (
          <FormGroup>
            <Label for="prefixStr">Prefix (clean)</Label>
            <Input
              type="text"
              name="prefixStr"
              id="prefixStr"
              placeholder="Prefix"
              value={prefixStr}
              onChange={this.onPrefixStrChanged}
            />
          </FormGroup>
        );
      } else if (resultType === 'dictionary_word') {
        dynamicFields2 = (
          <FormGroup>
            <Label for="prefixStr">Word (clean)</Label>
            <Input
              type="text"
              name="word"
              id="word"
              placeholder="Word"
              value={word}
              onChange={this.onWordChanged}
            />
          </FormGroup>
        );
      }

      dynamicFields = (
        <div className="mt-4">
          <Row>
            <Col xs={12} lg={6}>
              {dynamicFields2}
            </Col>

            <Col xs={12} lg={6}>
              <FormGroup>
                <Label for="lang">Lang</Label>
                <Input
                  type="select"
                  name="lang"
                  id="lang"
                  placeholder="Language"
                  value={lang}
                  onChange={this.onLangChanged}
                >
                  <option value="">-- Unknown --</option>
                  <option value="English">English</option>
                  <option value="Greek">Greek</option>
                  <option value="Latin">Latin</option>
                  <option value="Anglosaxon">Anglo Saxon</option>
                  <option value="French">French</option>
                  <option value="German">German</option>
                </Input>
              </FormGroup>
            </Col>

            <Col xs={12} lg={6}>
              <FormGroup>
                <Label for="meanings">Meanings</Label>
                <ReactQuill
                  className="meanings-editor"
                  theme="snow"
                  value={meanings}
                  onChange={this.onMeaningsChanged}
                  modules={modules}
                  formats={formats}
                />
              </FormGroup>
            </Col>

            <Col xs={12} lg={6}>
              <FormGroup>
                <Label for="synonyms">Synonyms</Label>
                <ReactQuill
                  className="synonyms-editor"
                  theme="snow"
                  value={synonyms}
                  onChange={this.onSynonymsChanged}
                  modules={modules}
                  formats={formats}
                />
              </FormGroup>
            </Col>

            <Col xs={12} lg={6}>
              <FormGroup>
                <Label for="antonyms">Antonyms</Label>
                <ReactQuill
                  className="antonyms-editor"
                  theme="snow"
                  value={antonyms}
                  onChange={this.onAntonymsChanged}
                  modules={modules}
                  formats={formats}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      )
    } else if (resultType === 'files' || resultType === 'photos_timeline') {
      dynamicFields = (
        <Row>
          <Col xs={12} md={4}>
            <div className="mt-4">
              <FormGroup className="">
                <Label for="exampleFile">Upload New File</Label>
                <FileUpload
                  onFileUploaded={this.onFileUploaded}
                />
              </FormGroup>
            </div>
          </Col>
          <Col xs={12} md={8}>
            <ManageFilesTable
              fileIds={fileIds}
              onFileAction={this.onFileAction}
            />
          </Col>
        </Row>
      );
    } else if (resultType === 'video') {

      dynamicFields = (
        <div className="mt-4">
          <Row>
            <Col xs={12} lg={6}>
              <FormGroup>
                <Label for="source"> Source </Label>
                <Input
                  type="select"
                  name="source"
                  placeholder=""
                  value={videoSource}
                  onChange={this.onVideoSourceChanged}
                >
                  <option value="">-- Select Source --</option>
                  <option value="embed">Embed</option>
                  <option value="file">File</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xs={12} lg={6}>
              <FormGroup>
                <Label for="videoUrl">Video URL</Label>
                <Input
                  type="text"
                  name="videoUrl"
                  placeholder="Enter the embed URL here"
                  value={videoUrl}
                  onChange={this.onVideoUrlChanged}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="videoWidth">Width</Label>
                <Input
                  type="number"
                  name="videoWidth"
                  placeholder="480"
                  value={videoWidth}
                  onChange={this.onVideoWidthChanged}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="videoHeight">Height</Label>
                <Input
                  type="number"
                  name="videoHeight"
                  id="videoHeight"
                  placeholder="320"
                  value={videoHeight}
                  onChange={this.onVideoHeightChanged}
                />
              </FormGroup>
            </Col>
            <Col className="">
              <FormGroup check className="pt-3">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={videoAutoplay}
                    onChange={this.onVideoAutoplayChanged}
                  />&nbsp;
                  Auto Play
                </Label>
              </FormGroup>
            </Col>
          </Row>

        </div>
      );
    } else if (resultType === 'blog_article') {
      dynamicFields = (
        <div className="mt-4">
          <FormGroup>
            <Label for="aliasQuery">Title</Label>
            <Input
              type="text"
              name="blogTitle"
              id="blogTitle"
              placeholder="Enter the blog title here"
              value={blogTitle}
              onChange={this.onBlogTitleChanged}
            />
          </FormGroup>

          <FormGroup>
            <Label for="blogExcerpt">Excerpt</Label>
            <ReactQuill
              className="blog-excerpt-editor"
              theme="snow"
              value={blogExcerpt}
              onChange={this.onBlogExcerptChanged}
              modules={modules}
              formats={formats}
            />
          </FormGroup>

          <FormGroup>
            <Label for="blogContent">Content</Label>
            <ReactQuill
              className="blog-article-editor"
              theme="snow"
              value={blogContent}
              onChange={this.onBlogContentChanged}
              modules={modules}
              formats={formats}
            />
          </FormGroup>
          <Row>
            <Col xs={12} lg={6}>
              <FormGroup className="">
                <Label for="exampleFile">Featured Image</Label>
                <FeaturedImageUpload
                  featuredImageId={blogFeaturedImage}
                  onBlogFeaturedImageUploaded={this.onBlogFeaturedImageUploaded}
                />
              </FormGroup>
            </Col>
            <Col xs={12} lg={6}>
              <FormGroup>
                <Label for="blogImageCaption"> Featured Image Caption </Label>
                <Input
                  type="text"
                  name="blogImageCaption"
                  placeholder="Caption"
                  value={blogImageCaption}
                  onChange={this.onBlogImageCaptionChanged}
                />
              </FormGroup>

              <FormGroup className="mt-2">
                <Label for="blogAuthor">Author</Label>
                <Input
                  type="text"
                  name="blogAuthor"
                  placeholder="Author Name"
                  value={blogAuthor}
                  onChange={this.onBlogAuthorChanged}
                />
              </FormGroup>

              <FormGroup className="mt-2">
                <Label for="blogTimestamp">Timestamp</Label>
                <Input
                  type="text"
                  name="blogTimestamp"
                  placeholder="Created time of the article"
                  value={blogTimestamp}
                  onChange={this.onBlogTimestampChanged}
                />
              </FormGroup>
              <FormGroup className="mt-2">
                <Label for="blogHashtags">Hashtags</Label>
                <Input
                  type="textarea"
                  name="blogHashtags"
                  placeholder=""
                  value={blogHashtags}
                  onChange={this.onBlogHashtagsChanged}
                />
              </FormGroup>

              <FormGroup check className="mt-3">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={socialSharingEnabled}
                    onChange={this.onSocialSharingChanged}
                  />&nbsp;
                  Enable Social Sharing
                </Label>
              </FormGroup>
              <FormGroup check className="mt-3">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={showPostSuggestions}
                    onChange={this.onShowPostSuggestionsChanged}
                  />&nbsp;
                  Show Post Suggestions
                </Label>
              </FormGroup>

            </Col>
          </Row>
        </div>
      );
    } else if (resultType === 'calendar') {
      dynamicFields = (
        <div className="mt-4">
          <FormGroup>
            <Label for="journalNotes">Notes</Label>
            <ReactQuill
              className="journal-notes-editor"
              theme="snow"
              value={journalNotes}
              onChange={this.onJournalNotesChanged}
              modules={modules}
              formats={formats}
            />
          </FormGroup>

          <Row>
            <Col xs={12} md={4}>
              <div className="mt-4">
                <FormGroup className="">
                  <Label for="exampleFile">Upload New File</Label>
                  <FileUpload
                    onFileUploaded={this.onFileUploaded}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col xs={12} md={8}>
              <ManageFilesTable
                fileIds={fileIds}
                onFileAction={this.onFileAction}
              />
            </Col>
          </Row>
        </div>
      );
    } else if (resultType === 'person') {
      dynamicFields = (
        <div>
          <Row>
            <Col>
              <FormGroup>
                <Label for="personName "> Name </Label>
                <Input
                  type="text"
                  name="personName "
                  id="personName "
                  placeholder=""
                  value={personName}
                  onChange={this.onPersonNameChanged}
                />
              </FormGroup>

            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="personIntro "> Introduction </Label>
                <Input
                  type="text"
                  name="personIntro "
                  id="personIntro "
                  placeholder=""
                  value={personIntro}
                  onChange={this.onPersonIntroChanged}
                />
              </FormGroup>

            </Col>
            <Col>
              <FormGroup>
                <Label for="personShortBio "> Short Bio </Label>
                <Input
                  type="text"
                  name="personShortBio "
                  id="personShortBio "
                  placeholder=""
                  value={personShortBio}
                  onChange={this.onPersonShortBioChanged}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
                <ManageBioTable personBio={personBio} addBioRow={this.addBioRow} editBioRow={this.editBioRow} />
          </Col>
         </Row>
          <Row>
            <Col xs={12} md={4}>
              <div className="mt-4">
                <FormGroup className="">
                  <Label for="exampleFile">Upload New File</Label>
                  <FileUpload
                    onFileUploaded={this.onFileUploaded}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col xs={12} md={8}>
              <ManageFilesTable
                fileIds={fileIds}
                onFileAction={this.onFileAction}
              />
            </Col>
          </Row>
        </div>
      );
    } else if (resultType === 'place') {
      dynamicFields = (
        <div>
          <Row>
            <Col xs={12} md={4}>
              <div className="mt-4">
                <FormGroup className="">
                  <Label for="exampleFile">Upload New File</Label>
                  <FileUpload
                    onFileUploaded={this.onFileUploaded}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col xs={12} md={8}>
              <ManageFilesTable
                fileIds={fileIds}
                onFileAction={this.onFileAction}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="placeType "> Type </Label>
                <Input
                  type="select"
                  name="placeType"
                  id="placeType"
                  value={placeType}
                  onChange={this.onPlaceTypeChanged}
                >
                  <option value="">-- Select Place Type --</option>
                  <option value="street">Street</option>
                  <option value="city">City</option>
                  <option value="state">State</option>
                  <option value="province">Province</option>
                  <option value="country">Country</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="placeName "> Name </Label>
                <Input
                  type="text"
                  name="placeName "
                  id="placeName "
                  placeholder=""
                  value={placeName}
                  onChange={this.onPlaceNameChanged}
                />
              </FormGroup>

            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="placeDescription">Description </Label>
                <ReactQuill
                  className="place-description"
                  theme="snow"
                  value={placeDescription}
                  onChange={this.onPlaceDescriptionChanged}
                  modules={modules}
                  formats={formats}
                />
              </FormGroup>

            </Col>
            <Col>
              <FormGroup>
                <Label for="placeAlias "> Alias </Label>
                <Input
                  type="textarea"
                  name="placeAlias "
                  id="placeAlias "
                  placeholder=""
                  value={placeAlias}
                  onChange={this.onPlaceAliasChanged}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="placeAddress "> Address </Label>
                <Input
                  type="textarea"
                  name="placeAddress "
                  id="placeAddress "
                  placeholder=""
                  value={placeAddress}
                  onChange={this.onPlaceAddressChanged}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="placeExtraInfo "> Extra Info </Label>
                <Input
                  type="textarea"
                  name="placeExtraInfo "
                  id="placeExtraInfo "
                  placeholder=""
                  value={placeExtraInfo}
                  onChange={this.onPlaceExtraInfoChanged}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="placeLat "> Latitude </Label>
                <Input
                  type="number"
                  name="placeLat "
                  id="placeLat "
                  placeholder=""
                  value={placeLat}
                  onChange={this.onPlaceLatChanged}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="placeLng "> Longitude</Label>
                <Input
                  type="number"
                  name="placeLng "
                  id="placeLng "
                  placeholder=""
                  value={placeLng}
                  onChange={this.onPlaceLngChanged}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>

      );
    } else if (resultType === 'company') {
      dynamicFields = (
        <div>
          <Row>
            <Col xs={12} md={4}>
              <div className="mt-4">
                <FormGroup className="">
                  <Label for="exampleFile">Upload New File</Label>
                  <FileUpload
                    onFileUploaded={this.onFileUploaded}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col xs={12} md={8}>
              <ManageFilesTable
                fileIds={fileIds}
                onFileAction={this.onFileAction}
              />
            </Col>
          </Row>
        </div>

      );
    } else if (resultType === 'product') {
      dynamicFields = (
        <div>
          <Row>
            <Col xs={12} md={4}>
              <div className="mt-4">
                <FormGroup className="">
                  <Label for="exampleFile">Upload New File</Label>
                  <FileUpload
                    onFileUploaded={this.onFileUploaded}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col xs={12} md={8}>
              <ManageFilesTable
                fileIds={fileIds}
                onFileAction={this.onFileAction}
              />
            </Col>
          </Row>

          <div className="mt-4">
            <Row>
              <Col>
                <FormGroup>
                  <Label for="productTitle">Title</Label>
                  <Input
                    type="text"
                    name="productTitle"
                    id="productTitle"
                    placeholder=""
                    value={productTitle}
                    onChange={this.onProductTitleChanged}
                  />
                </FormGroup>

              </Col>

              <Col>
                <FormGroup>
                  <Label for="productBrand">Brand</Label>
                  <Input
                    type="text"
                    name="productBrand"
                    id="productBrand"
                    placeholder=""
                    value={productBrand}
                    onChange={this.onProductBrandChanged}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="productBrand">MSRP</Label>
                  <Input
                    type="number"
                    name="productMsrp"
                    id="productMsrp"
                    placeholder=""
                    value={productMsrp}
                    onChange={this.onProductMsrpChanged}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="productSellingPrice">Selling Price</Label>
                  <Input
                    type="number"
                    name="productSellingPrice"
                    id="productSellingPrice"
                    placeholder=""
                    value={productSellingPrice}
                    onChange={this.onProductSellingPriceChanged}
                  />
                </FormGroup>

              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="productCurrency "> Currency </Label>
                  <Input
                    type="select"
                    name="currency"
                    id="currency"
                    value={productCurrency}
                    onChange={this.onProductCurrencyChanged}
                  >
                    <option value="">-- Select Currency --</option>
                    <option value="inr">INR</option>
                    <option value="usd">USD</option>
                    <option value="eur">EUR</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="productQtyInStock "> Quantity Available </Label>
                  <Input
                    type="number"
                    name="productQtyInStock "
                    id="productQtyInStock "
                    placeholder=""
                    value={productQtyInStock}
                    onChange={this.onProductQtyInStockChanged}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="productDescription">Description</Label>
                  <ReactQuill
                    className="product-description"
                    theme="snow"
                    value={productDescription}
                    onChange={this.onProductDescriptionChanged}
                    modules={modules}
                    formats={formats}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="productSpecifications">Specifications</Label>
                  <Input
                    type="textarea"
                    name="productSpecifications"
                    id="productSpecifications"
                    placeholder=""
                    value={productSpecifications}
                    onChange={this.onProductSpecificationsChanged}
                  />
                </FormGroup>

              </Col>
            </Row>
          </div>
        </div>

      );
    }

    let btnSave;
    if (saveSearchResultStatus && saveSearchResultStatus.saving) {
      btnSave = (
        <Button color="success" disabled>
          <i className="fa fa-spinner fa-spin"/>
          &nbsp;
          Saving
        </Button>
      );
    } else {
      btnSave = (
        <Button color="success" onClick={this.saveSearchResult}>
          <i className="fa fa-save"/>
          &nbsp;
          Save
        </Button>
      );
    }

    let btnBack;
    if (isInsideModal) {
      btnBack = (
        <Button color="secondary" onClick={this.props.toggleModal}>
          <i className="fa fa-backward"/>&nbsp;
          Back to Search
        </Button>
      );
    } else {
      btnBack = (
        <Button color="secondary" onClick={this.openSearchPage}>
          <i className="fa fa-backward"/>&nbsp;
          Back to Search
        </Button>
      );
    }

    let btnClose = null;
    if (isInsideModal) {
      btnClose = (
        <a href={null} className="text-muted pointer link" onClick={this.props.toggleModal}>
          <i className="fa fa-times"/>
        </a>
      )
    }

    return (
      <div>
        <Card>
          <CardHeader>
            <Row>
              <Col xs="auto">
                <b>{query}</b> - Edit Search Result
              </Col>
              <Col className="ml-auto" xs="auto">
                {btnClose}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Label for="resultType">Result Type</Label>
                    <Input
                      type="select"
                      name="resultType"
                      id="resultType"
                      className=""
                      value={resultType}
                      onChange={this.onResultTypeChanged}
                    >
                      <option value="none">None</option>
                      <option value="alias">Alias</option>
                      <option value="expression">Math Expression</option>
                      <option value="meta_search">Meta Search</option>
                      <option value="redirect">Redirect</option>
                      <option value="fixed_text">Fixed Text</option>
                      <option value="files">File Upload</option>
                      <option value="video">Video Embed</option>
                      <option value="photos_timeline">Photos Timeline</option>
                      <option value="dictionary_prefix">Dictionary Prefix</option>
                      <option value="dictionary_word">Dictionary Word</option>
                      <option value="blog_article">Blog Article</option>
                      <option value="calendar">Calendar</option>
                      <option value="post_timeline">Post Timeline (NA)</option>
                      <option value="person">Person (NA)</option>
                      <option value="company">Company (NA)</option>
                      <option value="place">Place (NA)</option>
                      <option value="book">Book (NA)</option>
                      <option value="movie">Movie (NA)</option>
                      <option value="product">Product (NA)</option>
                      <option value="products_list">Products List (NA)</option>
                      <option value="webpage_extract">Webpage Extract (NA)</option>

                    </Input>
                  </FormGroup>

                  <ResultTypeHelperText resultType={resultType}/>
                </Col>
              </Row>

              {dynamicFields}
              <Row className="mt-4">
                <Col xs={12} md={6}>
                  <FormGroup check className="pt-3">
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={isPrivate}
                        onChange={this.onIsPrivateChanged}
                      />&nbsp;
                      This result is Private
                    </Label>
                  </FormGroup>
                </Col>

                <Col xs={12} md={6}>
                  {dynamicFields3}
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Label for="searchCount">Search Count</Label>
                    <Input
                      type="number"
                      name="searchCount"
                      id="searchCount"
                      min="-9999999999"
                      max="9999999999"
                      placeholder="Edit Search Count"
                      value={searchCount}
                      onChange={this.onSearchCountChanged}
                    />
                  </FormGroup>
                </Col>

                <Col xs={12} md={6}>

                  <FormGroup>
                    <Label for="notes">Notes</Label>
                    <ReactQuill
                      className="journal-notes-editor"
                      theme="snow"
                      value={notes}
                      onChange={this.onNotesChanged}
                      modules={modules}
                      formats={formats}
                    />
                  </FormGroup>
                  {/*  <FormGroup>
                    <Label for="notes">Notes</Label>
                    <Input
                      type="textarea"
                      name="notes"
                      id="notes"
                      placeholder=""
                      value={notes}
                      onChange={this.onNotesChanged}
                    />
                  </FormGroup>*/}

                  <p className="helper-text m-0">
                    Notes are just for you in edit section. They won&rsquo;t show up on result page.
                  </p>
                </Col>
              </Row>
            </Form>
          </CardBody>

          <CardFooter>
            <Row>
              <Col xs={6}>
                {btnSave}
              </Col>
              <Col xs={6} className="d-flex justify-content-end">
                {btnBack}
              </Col>
            </Row>

          </CardFooter>
        </Card>
      </div>
    );
  }
}

EditResultForm.defaultProps = {
  actions: {},
  defaultResultType: '',
  isInsideModal: false,
  query: '',
  saveSearchResult: {},
  toggleModal: () => {
  },
};

EditResultForm.propTypes = {
  actions: PropTypes.object,
  defaultResultType: '',
  isInsideModal: PropTypes.bool,
  query: PropTypes.string,
  resultData: PropTypes.object.isRequired,
  saveSearchResult: PropTypes.object,
  toggleModal: PropTypes.func,
};

const mapStateToProps = state => ({
  saveSearchResult: state.saveSearchResult,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, searchActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditResultForm);
