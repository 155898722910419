/*
 global localStorage
 */
import { cloneDeep } from 'lodash';
import {
  USER_LOGIN_STARTING,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,

  USER_LOGOUT_STARTING,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_ERROR,

  GET_USER_INFO_STARTING,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_ERROR,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function currentUserReducer(state = initialState.currentUser, action) {
  switch (action.type) {
    case USER_LOGIN_STARTING: {
      const newState = cloneDeep(state);
      newState.loginInProgress = true;
      newState.forceLogin = false;
      newState.loggedIn = false;
      newState.refetchInfo = false;
      return newState;
    }

    case GET_USER_INFO_STARTING: {
      const newState = cloneDeep(state);
      newState.forceLogin = false;
      newState.refetchInfo = false;
      newState.fetchingInfo = true;
      return newState;
    }

    case USER_LOGIN_SUCCESS:
    case GET_USER_INFO_SUCCESS: {
      const newState = cloneDeep(state);
      const { user } = action.data;
      newState.userData = user;

      newState.userId = user._id;
      newState.loginInProgress = false;
      newState.fetchingInfo = false;
      newState.forceLogin = false;
      newState.loggedIn = true;
      newState.error = null;

      return newState;
    }

    case USER_LOGIN_ERROR:
    case GET_USER_INFO_ERROR: {
      const newState = cloneDeep(state);
      newState.loginInProgress = false;
      newState.forceLogin = true;
      newState.error = action.err ? action.err.message : 'Failed to login user due to an unknown error at server.';
      newState.loggedIn = false;

      return newState;
    }

    case USER_LOGOUT_STARTING: {
      const newState = cloneDeep(state);
      newState.loginInProgress = true;
      return newState;
    }

    case USER_LOGOUT_SUCCESS: {
      const newState = cloneDeep(state);
      newState.userData = {};
      newState.userId = 0;
      newState.forceLogin = true;
      newState.loggedIn = false;
      newState.loginInProgress = false;
      newState.error = '';
      newState.prevRoute = '';

      return newState;
    }

    case USER_LOGOUT_ERROR: {
      const newState = cloneDeep(state);
      newState.loginInProgress = false;
      newState.error = action.err.message;
      return newState;
    }

    default: {
      return state;
    }
  }
}
