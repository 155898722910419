import { cloneDeep } from 'lodash';
import {
  FILE_UPLOAD_SUCCESS, GET_FILES_SUCCESS, GET_SEARCH_RESULT_SUCCESS,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function filesReducer(state = initialState.files, action) {
  switch (action.type) {
    case FILE_UPLOAD_SUCCESS: {
      const { file } = action.data;
      const fileId = file._id;

      const newState = cloneDeep(state);

      if (!newState[fileId]) {
        newState[fileId] = cloneDeep(initialState.files.fileId);
      }

      const fileData = newState[fileId];
      fileData.loading = false;
      fileData.loaded = true;
      fileData.error = null;
      fileData.file = file;

      return newState;
    }

    case GET_SEARCH_RESULT_SUCCESS: {
      const { result } = action.data;

      if (!result || !result.fileIds || !result.fileIds.length) {
        return state;
      }

      const { filesData } = action.data;

      const newState = cloneDeep(state);

      result.fileIds.forEach((fileId) => {
        const file = filesData[fileId];
        if (!file) {
          return true;
        }

        if (!newState[fileId]) {
          newState[fileId] = cloneDeep(initialState.files.fileId);
        }

        const fileData = newState[fileId];
        fileData.loading = false;
        fileData.loaded = true;
        fileData.error = null;
        fileData.file = file;
      });

      return newState;
    }

    case GET_FILES_SUCCESS: {
      const newState = cloneDeep(state);
      const { data } = action;

      data.files.forEach((file) => {
        const fileId = file._id;

        if (!newState[fileId]) {
          newState[fileId] = cloneDeep(initialState.files.fileId);
        }

        const fileData = newState[fileId];
        fileData.loading = false;
        fileData.loaded = true;
        fileData.error = null;
        fileData.file = file;
      });

      return newState;
    }

    default: {
      return state;
    }
  }
}
