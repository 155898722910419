import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';


// import * as actionsActions from '../actions/actions';

class StarRating extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {

    const {rating} = this.props;
    let fullStar = [];
    let tempRating = Math.floor(rating);


    let i = 0;

      for (i = 0; i < tempRating; i++) {
        fullStar.push(<i className="fa fa-star" aria-hidden="true" />);
      }

      let remaining = rating - tempRating;
      if (remaining > 0) {
        fullStar.push(<i className="fa fa-star-half-o" aria-hidden="true" />);
      }

      while (fullStar.length < 5) {
        fullStar.push(<i className="fa fa-star-o" aria-hidden="true" />);
      }

    return (

      <div>
        {fullStar}
      </div>


    )
      ;
  }
}

StarRating.defaultProps = {
  actions: {},
};

StarRating.propTypes = {
  actions: PropTypes.object,
  rating: PropTypes.number.isRequired,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StarRating);




