import { cloneDeep } from 'lodash';
import {
  GET_FILES_STARTING,
  GET_FILES_SUCCESS,
  GET_FILES_ERROR,
  DELETE_FILE_SUCCESS,


} from '../constants/actionTypes';
import initialState from './initialState';

export default function fileManagerDataReducer(state = initialState.fileManagerData, action) {
  switch (action.type) {
    case GET_FILES_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      newState.error = null;
      return newState;
    }

    case GET_FILES_SUCCESS: {
      const newState = cloneDeep(state);
      const { data } = action;

      const ids = [];
      const map = {};

      data.files.forEach((file) => {
        ids.push(file._id);
        map[file._id] = file;
      });

      newState.loading = false;
      newState.loaded = true;
      newState.ids = ids;
      newState.map = map;
      return newState;
    }

    case GET_FILES_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err.message : 'Failed to get file manager data.';
      return newState;
    }

    case DELETE_FILE_SUCCESS: {
      const newState = cloneDeep(state);
      const { fileId } = action;
      newState.ids = newState.ids.filter(id => id !== fileId);
      delete newState.map[fileId];
      return newState;
    }

    default: {
      return state;
    }
  }
}
