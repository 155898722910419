import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import News from './NewsWidget/News';

// import * as actionsActions from '../actions/actions';

class NewsWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="dashboard-widget news-widget">

            <News />

      </div>
    );
  }
}

NewsWidget.defaultProps = {
  actions: {},
};

NewsWidget.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsWidget);
