import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Clock from "react-clock";

// import * as actionsActions from '../actions/actions';

class AnalogClockWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="dashboard-widget analog-clock-container">
        <Card>
          <CardBody>
            <Clock />
          </CardBody>
        </Card>
      </div>
    );
  }
}

AnalogClockWidget.defaultProps = {
  actions: {},
};

AnalogClockWidget.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalogClockWidget);
