import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import NewsPost from "./NewsPost";
import pm1 from "../../../assets/pm1.png";
import pm2 from "../../../assets/pm2.png";
import pm3 from "../../../assets/pm3.png";
import pm4 from "../../../assets/pm4.png";
import pm5 from "../../../assets/pm5.png";
import pm6 from "../../../assets/pm6.png";

class Notes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
     const { resultData } = this.props;
      const notesHtml = resultData.result.notes;

      if (!notesHtml.length) {
        return (
          <div>
            <i>No notes added yet.</i>
          </div>
        );
      }

      return (
        <div className="mt-0">
          <div
            className="mt-1 pl-3 notes-body"
            dangerouslySetInnerHTML={{__html: notesHtml}}
          />
        </div>
      );
    }
}

Notes.defaultProps = {
  actions: {},
};

Notes.propTypes = {
  actions: PropTypes.object,
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
