import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, } from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ProductSpecifications from "./ProductSpecifications";


// import * as actionsActions from '../actions/actions';

class ProductsListData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const productsList = [
      {
        title: "Samsung Galaxy M31 (Ocean Blue, 6GB RAM, 68GB Storage)",
        msrp: "₹ 19,999",
        discountedPrice: "₹ 17,499",
        imgPath: "../../assets/g1.png",
        rating: 2.5,
        reviewsCount: 8950,

      },

      {
        title: "Samsung Galaxy A21s (Blue 4GB RAM, 64GB Storage)",
        msrp: "₹ 17,999",
        discountedPrice: "₹ 14,499",
        imgPath: "../../assets/g2.png",
        rating: 4.7,
        reviewsCount: 7895,
      },
      {
        title: "Samsung Galaxy M21 (Raven Black, 6GB RAM 128GB Storage)",
        msrp: "₹ 17,199",
        discountedPrice: "₹ 15,499",
        imgPath: "../../assets/g3.png",
        rating: 3.5,
        reviewsCount: 1895,
      },
      {
        title: "Samsung Galaxy M11 (Black, 4GB RAM 64GB Storage)",
        msrp: "₹ 16,999",
        discountedPrice: "₹ 13,499",
        imgPath: "../../assets/g4.png",
        rating: 4.7,
        reviewsCount: 45895,
      },
    ];


    return (
      <div className="products-cart">
        <Card>
          <CardBody>

            <ProductSpecifications
              product={productsList[0]}
            />
            <ProductSpecifications
              product={productsList[1]}
            />
            <ProductSpecifications
              product={productsList[2]}
            />
            <ProductSpecifications
              product={productsList[3]}
            />

          </CardBody>
        </Card>
      </div>
    );
  }
}


ProductsListData.defaultProps = {
  actions: {},
};

ProductsListData.propTypes = {
  actions: PropTypes.object,

};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsListData);
