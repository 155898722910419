import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Form, FormGroup, Input, Label,
  Modal, ModalBody, ModalFooter, ModalHeader,
  Row, Alert,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as fileActions from "../../actions/filesActions";
import Noty from "noty";
import {isNil} from "lodash";
import FilePreview from "../SearchResultPage/FilesResult/FilePreview";


class EditFileModal extends React.Component {
  constructor(props) {
    super(props);

    const {selectedFile} = this.props;

    this.state = {
      originalFileName: selectedFile.originalFileName,
    };

    this.onOriginalFileNameChanged = this.onOriginalFileNameChanged.bind(this);
    this.onTitleChanged = this.onTitleChanged.bind(this);
    this.onDescriptionChanged = this.onDescriptionChanged.bind(this);
    this.onHashTagsChanged = this.onHashTagsChanged.bind(this);
    this.onDescriptionChanged = this.onDescriptionChanged.bind(this);
    this.onIsPrivateChanged = this.onIsPrivateChanged.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.onConfirmPasswordChanged = this.onConfirmPasswordChanged.bind(this);

    this.onSaveClicked = this.onSaveClicked.bind(this);
    this.updateStarted = false;
  }

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    const fileId = this.props.selectedFile._id;
    if (this.updateStarted) {
      if (this.props.updateFile !== nextProps.updateFile && !isNil(nextProps.updateFile[fileId])) {
        this.handleStatusChange(nextProps);
      }
    }
  }

  handleStatusChange(props) {
    const fileId = this.props.selectedFile._id;

    if (
      !props.updateFile[fileId].saving && props.updateFile[fileId].saved
    ) {
      new Noty({
        type: 'success',
        layout: 'bottomLeft',
        text: 'File data updated successfully.',
      }).show();

      this.updateStarted = false;
      this.props.toggleModal();
    } else if (
      !props.updateFile[fileId].saving &&
      !props.updateFile[fileId].saved
    ) {
      new Noty({
        type: 'error',
        layout: 'bottomLeft',
        text: props.updateFile[fileId].error,
      }).show();

      this.updateStarted = false;
    }
  }

  onOriginalFileNameChanged(event) {
    this.setState({
      originalFileName: event.target.value,
    });
  }
  onTitleChanged(event) {
    this.setState({
      title: event.target.value,
    });
  }

  onDescriptionChanged(event) {
    this.setState({
      description: event.target.value,
    });
  }

  onHashTagsChanged(event) {
    this.setState({
      hashTags: event.target.value,
    });
  }

  onIsPrivateChanged(event) {
    this.setState({
      isPrivate: event.target.checked,
    });
  }

  onConfirmPasswordChanged(event) {
    this.setState({
      confirmPassword: event.target.value,
    });
  }

  onPasswordChanged(event) {
    this.setState({
      password: event.target.value,
    });
  }

  onSaveClicked() {
    const {selectedFile} = this.props;
    this.props.actions.updateFileStarting(selectedFile._id);
    this.props.actions.updateFile(selectedFile._id, this.state);
    this.updateStarted = true;
  }

  render() {
    const {originalFileName, title, description, hashTags, isPrivate, password, confirmPassword} = this.state;
    const {toggleModal, selectedFile, updateFile} = this.props;

    let dynamicFields = null;

    if (isPrivate) {

      dynamicFields = (

        <div className="mt-4">
          <Alert color="primary">
            Password and Confirm Password should be same.
          </Alert>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              type="text"
              name="password"
              id="password"
              placeholder=""
              value={password}
              onChange={this.onPasswordChanged}
            />
          </FormGroup>
          <FormGroup>
            <Label for="confirmPassword">Confirm Password</Label>
            <Input
              type="text"
              name="confirmPassword"
              id="confirmPassword"
              placeholder=""
              value={confirmPassword}
              onChange={this.onConfirmPasswordChanged}
            />
          </FormGroup>
        </div>
      );
    }

    let btnSave;
    const updateData = updateFile[selectedFile._id];
    if (!isNil(updateData) && updateData.saving) {
      btnSave = (
        <Button color="success" disabled>
          Please wait...
        </Button>
      );
    } else {
      btnSave = (
        <Button color="success" onClick={this.onSaveClicked}>Save</Button>
      );
    }

    return (
      <Modal isOpen={true} toggle={toggleModal} className="edit-file-modal">
        <ModalHeader toggle={toggleModal}>Edit File </ModalHeader>
        <ModalBody>
          <div>
            <Form>
              <Row>
                <Col sm="6">
                  <FilePreview
                    fileId={selectedFile._id}
                    showDocument={false}
                  />
                </Col>

                <Col sm="6">
                  <FormGroup className="">
                    <Label> File Name </Label>
                    <Input
                      type="text"
                      name="fileName"
                      id="fileName"
                      placeholder=""
                      value={originalFileName}
                      onChange={this.onOriginalFileNameChanged}
                    />
                  </FormGroup>
                  <FormGroup className="">
                    <Label> Title </Label>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      placeholder=""
                      value={title}
                      onChange={this.onTitleChanged}
                    />
                  </FormGroup>
                  <FormGroup className="">
                    <Label> Description </Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      placeholder=""
                      value={description}
                      onChange={this.onDescriptionChanged}
                    />
                  </FormGroup>
                  <FormGroup className="">
                    <Label> Hashtags </Label>
                    <Input
                      type="textarea"
                      name="hashTags"
                      id="hashTags"
                      placeholder=""
                      value={hashTags}
                      onChange={this.onHashTagsChanged}
                    />
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="checkbox"
                             checked={isPrivate}
                             onChange={this.onIsPrivateChanged}
                      />&nbsp;
                      This file is Private
                    </Label>
                  </FormGroup>

                  {dynamicFields}
                </Col>
              </Row>
            </Form>
          </div>
        </ModalBody>
        <ModalFooter>
          {btnSave}

          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

EditFileModal.defaultProps = {
  actions: {},
  updateFile: {},
};

EditFileModal.propTypes = {
  actions: PropTypes.object,
  toggleModal: PropTypes.func.isRequired,
  selectedFile: PropTypes.object.isRequired,
  updateFile: PropTypes.object,

};

const mapStateToProps = state => ({
  updateFile: state.updateFile,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, fileActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFileModal);
