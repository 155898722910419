import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Nav, NavItem, NavLink,
  Row, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';
import WebPageRows from './MetaSearchResult/WebPageRows';
import RedirectToDefaultSearchEngine from './RedirectToDefaultSearchEngine';
import AddResultTypeDropdown from './MetaSearchResult/AddResultTypeDropdown';

class MetaSearchResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'd',
    };

    this.setActiveTab = this.setActiveTab.bind(this);
  }

  componentDidMount() {
    const { resultData } = this.props;

    if (
      !resultData.result.ddgResults ||
      !resultData.result.ddgResults.length
    ) {
      if (resultData.result.googleResults && resultData.result.googleResults.length) {
        this.setState({
          activeTab: 'g',
        });
      }
    }
  }

  setActiveTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { activeTab } = this.state;
    const { query, resultData } = this.props;

    if (
      (
        !resultData.result.ddgResults ||
        !resultData.result.ddgResults.length
      )
      &&
      (
        !resultData.result.googleResults ||
        !resultData.result.googleResults.length
      )
    ) {
      // No results exist for meta search
      return (
        <RedirectToDefaultSearchEngine
          query={query}
        />
      )
    }

    return (
      <div className="meta-search-result">
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'd', 'pointer link': true })}
                  onClick={() => {
                    this.setActiveTab('d');
                  }}
                  tag="div"
                >
                  <a href={`https://duckduckgo.com/?q=${query}`}>
                    <i className="fa fa-external-link" />
                  </a>&nbsp;

                  DuckDuckGo&trade;
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 'g', 'pointer link': true })}
                  onClick={() => {
                    this.setActiveTab('g');
                  }}
                  tag="div"
                >
                  <a href={`https://www.google.com/search?q=${query}`}>
                    <i className="fa fa-external-link" />
                  </a>&nbsp;

                  Google&trade;
                </NavLink>
              </NavItem>

              <NavItem className="ml-auto d-none d-md-block" >
                <AddResultTypeDropdown
                  query={query}
                  resultData={resultData}
                />
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="d">
                <WebPageRows
                  query={query}
                  resultData={resultData}
                  source="d"
                />
              </TabPane>
              <TabPane tabId="g">
                <WebPageRows
                  query={query}
                  resultData={resultData}
                  source="g"
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    );
  }
}

MetaSearchResult.defaultProps = {
  query: '',
};

MetaSearchResult.propTypes = {
  query: PropTypes.string,
  resultData: PropTypes.object.isRequired,
};

export default MetaSearchResult;
