import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalBody, ModalHeader,
  } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { humanFileSize } from '../../../utils/numberFormat';
import FilePreview from './FilePreview';
// import * as actionsActions from '../actions/actions';

class FilePreviewModal extends React.Component {

  render() {
    const { file, toggleModal } = this.props;

    return (
      <Modal isOpen={true} toggle={toggleModal} className="file-preview-modal">
        <ModalHeader tag="div">
          {file.originalFileName}
          <small className="ml-3">
            {humanFileSize(file.fileSize, false, 0)}
          </small>
        </ModalHeader>
        <ModalBody>
          <FilePreview
            fileId={file._id}
          />
        </ModalBody>
      </Modal>
    );
  }
}

FilePreviewModal.defaultProps = {
  actions: {},
};

FilePreviewModal.propTypes = {
  actions: PropTypes.object,
  file: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

const mapStateToProps = (/* state */)  => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilePreviewModal);
