import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SocialProfiles from "./SocialProfiles";

// import * as actionsActions from '../actions/actions';

class ContactDetails extends React.Component {
  render() {
    return (
      <div className="contact-details">
             <h6 className="m-0"> Contact Details </h6>
            <p className="txt-small"> <i className="fa fa-phone" />  &nbsp; +91-11-23012312 </p>
            <p className="txt-small"><i className="fa fa-envelope" />  &nbsp; narendramodi1234@gmail.com </p>
       </div>
    );
  }
}

ContactDetails.defaultProps = {
  actions: {},
};

ContactDetails.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails);
