import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import NewsPost from "./NewsPost";

// import * as actionsActions from '../actions/actions';

class News extends React.Component {
  render() {
    return (
      <div className="">

        <NewsPost
          channel="The New Indian Express"
          headline={"Buy Dabur India; target of Rs 565: Motilal Oswal "}
          description="   While results were in-line, ~7% growth in June and July is encouraging.
                        As pointed out in our upgrade note, the structural and medium-term narrative on
                         topline growth is turning highly attractive on account of: (a) strong traction in the profitable Healthcare business, (b) an attractive rural growth outlook (with ~48% of Dabur's domestic sales coming from rural), and (c) a spate of initiatives to boost"

          time="1 day ago"/>

        <NewsPost
          channel="The New Indian Express"
          headline={"Buy Dabur India; target of Rs 565: Motilal Oswal "}
          description="   While results were in-line, ~7% growth in June and July is encouraging.
                        As pointed out in our upgrade note, the structural and medium-term narrative on
                         topline growth is turning highly attractive on account of: (a) strong traction in the profitable Healthcare business, (b) an attractive rural growth outlook (with ~48% of Dabur's domestic sales coming from rural), and (c) a spate of initiatives to boost"

          time="1 day ago"/>


      </div>
    );
  }
}

News.defaultProps = {
  actions: {},
};

News.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(News);
