import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Input,
  Label,
  Row,
  Form, Button,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';


// import * as actionsActions from '../actions/actions';

class EditBioForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      field: props.data.field,
      value: props.data.value,
      index : props.data.index,
    };

    this.onFieldChanged = this.onFieldChanged.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);
    this.onEditBioRowSubmit = this.onEditBioRowSubmit.bind(this);

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.setState({
        field: nextProps.data.field,
        value: nextProps.data.value,
      });
    }
  }

  onFieldChanged(event) {
    let field = event.target.value;

    this.setState({
      field: field,
    });
  }

  onValueChanged(event) {
    let value = event.target.value;

    this.setState({
      value: value,
    });
  }

  onEditBioRowSubmit() {
    const data = this.state;
    this.props.editBioRow(data);

  }

  render() {
    const { field, value, index} = this.state;
    const { data } = this.props;

    return (
      <div>
        <Form method="POST" onSubmit={this.onEditBioRowSubmit}>
          <h6>  Edit -  {data.field} </h6>
          <FormGroup>
            <Label for="bioField "> Field </Label>
            <Input
              type="text"
              name="field "
              id="field "
              placeholder=""
              value={field}
              onChange={this.onFieldChanged}
            />
          </FormGroup>
          <FormGroup>
            <Label for="bioValue "> Value </Label>
            <Input
              type="text"
              name="value "
              id="value "
              placeholder=""
              value={value}
              onChange={this.onValueChanged}
            />
          </FormGroup>
          <Button type="button" color="success" onClick={this.onEditBioRowSubmit}> Update
          </Button>
        </Form>
      </div>
    );
  }
}

EditBioForm.defaultProps = {
  actions: {},
};

EditBioForm.propTypes = {
  actions: PropTypes.object,
  editBioRow: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBioForm);
