import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class Profile extends React.Component {

  render() {
    return (
      <div>
      <Row>
        <Col xs={3}>
        <b>Industry </b>
        </Col>
        <Col xs={6}>
       Consumer Goods
        </Col>
      </Row>
        <Row>
          <Col xs={3}>
           <b> Traded as</b>
          </Col>
          <Col xs={6}>
            BSE: 500096,
            NSE: DABUR
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <b> Origin </b>
          </Col>
          <Col xs={6}>
            India
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <b> Founded </b>
          </Col>
          <Col xs={6}>
            1884
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <b> Owner </b>
          </Col>
          <Col xs={6}>
            Burman family
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <b> Head Quarters </b>
          </Col>
          <Col xs={6}>
            Dabur Corporate Office, Kaushambi, Sahibabad, Ghaziabad, Uttar Pradesh, India
          </Col>
        </Row>
      </div>
    );
  }
}

Profile.defaultProps = {
  actions: {},
};

Profile.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
