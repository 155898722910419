import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'reactstrap';
import Footer from "./Common/Footer";
import {isLokiExtensionActive, isMobile} from '../utils/general';
import {apiBaseUrl, appName, siteName} from '../constants/appConfig';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import DigitalClockWidget from "./CustomHomePage/DigitalClockWidget";
import CalendarWidget from "./CustomHomePage/CalendarWidget";
import SearchForm from "./Common/SearchForm";
import PhotoWidget from "./CustomHomePage/PhotoWidget";
import NewsWidget from "./CustomHomePage/NewsWidget";
import StickyNotesWidget from "./CustomHomePage/StickyNotesWidget";
import QuoteWidget from "./CustomHomePage/QuoteWidget";
import MoviesWidget from "./CustomHomePage/MoviesWidget";
import AnalogClockWidget from "./CustomHomePage/AnalogClockWidget";
import FirstTimeUserModal from "./Common/FirstTimeUserModal";



class CustomHomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileBrowser: isMobile.any(),
      isExtensionActive: true,
    };
  }

  componentDidMount() {
    document.title = siteName;

    setTimeout(() => {
      this.setState({
        isExtensionActive: isLokiExtensionActive(),
      });
    }, 2 * 1000);
  }

  render() {
    let topWarning = null;
    const {appConfig} = this.props;

    if (appConfig.loaded && (!appConfig.data.search || !appConfig.data.search.baseUrl)) {
      topWarning = (
        <div className="unsecure-warning">
          You have not set the Base URL for this instance.
          If you are the site administrator, use the <Link to="/settings">
          Settings page
        </Link> to configure Base URL and install the browser extension.
        </div>
      );
    } else if (appConfig.loaded && appConfig.isPubliclyEditable) {
      topWarning = (
        <div className="unsecure-warning">
          This {appName} instance is publicly editable.
          If you are the site administrator, use the <Link to="/settings">
          Settings page
        </Link> to create user accounts immediately.
        </div>
      );
    }

    let extensionAlert = null;
    if (appConfig.loaded && appConfig.data.search && appConfig.data.search.baseUrl) {
      const {isExtensionActive, isMobileBrowser} = this.state;
      if (!isMobileBrowser && !isExtensionActive) {
        const extensionLink = `${apiBaseUrl}/extension/download`;
        extensionAlert = (
          <div className="download-extension-alert">
            Download the {siteName} browser extension&nbsp;
            <a href={extensionLink} target="_blank" rel="noopener noreferrer">here</a>.
          </div>
        );
      }
    }

    return (
      <div className="dashboard-page">
        <div>
          <div className="search-form-container">
            <div className="container">
              <SearchForm
                autoFocus={false}
              />
            </div>
          </div>

          <div className="container dashboard-widgets-container">
            <Row noGutters>

              <Col xs={12} md={2}>
                <PhotoWidget/>
              </Col>

              <Col xs={12} md={4}>
                <QuoteWidget/>
              </Col>

              <Col xs={12} md={2}>
                <DigitalClockWidget/>
              </Col>

              <Col xs={12} md={4}>
                <CalendarWidget/>
              </Col>

              <Col xs={12} md={4}>
                <MoviesWidget/>
              </Col>

              <Col xs={12} md={4}>
                <NewsWidget/>
              </Col>

            </Row>

            <Row noGutters>
              <Col xs={12} md={2}>
                <AnalogClockWidget/>
              </Col>

              <Col xs={12} md={4}>
                <StickyNotesWidget/>
              </Col>

              <Col xs={12} md={2}>
                Weather
              </Col>
              <Col xs={12} md={2}>
                Map
              </Col>
              <Col xs={12} md={2}>
                Health Bulletin
              </Col>
              <Col xs={12} md={2}>
                Currency Converter
              </Col>
              <Col xs={12} md={2}>
                Count Down for Project deadline
              </Col>
            </Row>
          </div>
        </div>

        {extensionAlert}
        {topWarning}

        <FirstTimeUserModal />

        <Footer/>
      </div>
    );
  }
}

CustomHomePage.defaultProps = {
  appConfig: {},
};

CustomHomePage.propTypes =   {
  appConfig: PropTypes.object,
};

const mapStateToProps = (state => ({
  appConfig: state.appConfig,
}));

export default connect(mapStateToProps)(CustomHomePage);
