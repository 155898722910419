import React from 'react';
import {
  Row, Col,
} from 'reactstrap';
import BasicDetails from "./BasicDetails";
import DetailedInfo from "./DetailedInfo";

class BookDetails extends React.Component {
  render() {
    return (
      <div>
        <Row>
          <Col xs={12} md={4}>
            <BasicDetails />
          </Col>

          <Col xs={12} md={8} className="mt-3 mt-md-0">
            <DetailedInfo />
          </Col>
        </Row>
      </div>
    );
  }
}

export default BookDetails;
