import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Input,
  Label,
  Row,
  Form, Button,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';


// import * as actionsActions from '../actions/actions';

class BioForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      field: '',
      value: '',
    };

    this.onFieldChanged = this.onFieldChanged.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);
    this.onBioNewRowSubmit = this.onBioNewRowSubmit.bind(this);

  }

  onFieldChanged(event) {
    let field = event.target.value;

    this.setState({
      field: field,
    });
  }

  onValueChanged(event) {
    let value = event.target.value;

    this.setState({
      value: value,
    });
  }

  onBioNewRowSubmit() {
    const data = this.state;
    if (!data.field) {
      return;
    }

    if (!data.value) {
      return;
    }

    this.props.addBioRow(data);
  }

  render() {
    const {field, value} = this.state;
    return (
      <div>
        <Form method="POST" onSubmit={this.onBioNewRowSubmit}>
          <h6>  Add New Row </h6>
          <FormGroup>
            <Label for="bioField "> Field </Label>
            <Input
              type="text"
              name="field "
              id="field "
              placeholder=""
              value={field}
              onChange={this.onFieldChanged}
            />
          </FormGroup>
          <FormGroup>
            <Label for="bioValue "> Value </Label>
            <Input
              type="text"
              name="value "
              id="value "
              placeholder=""
              value={value}
              onChange={this.onValueChanged}
            />
          </FormGroup>
          <Button type="button" color="success" onClick={this.onBioNewRowSubmit}> Submit
          </Button>
        </Form>
      </div>
    );
  }
}

BioForm.defaultProps = {
  actions: {},
};

BioForm.propTypes = {
  actions: PropTypes.object,
  addBioRow: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BioForm);
