import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row} from 'reactstrap';
import Footer from './Common/Footer';
import Header from './CustomPage/Header';
import {connect} from 'react-redux';
import {setDocumentTitle, setStateFromEvent} from "../utils/general";
import * as usersActions from '../actions/usersActions';
import {bindActionCreators} from "redux";
import Noty from "noty";
import classNames from "classnames";
import ProfileImageUpload from "./ProfilePage/ProfileImageUpload";

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);


    this.state = {
      email: '',
      password: '',
      fullName: '',
      confirmPassword: '',
      profileImage: '',
    };

    this.onEmailChanged = setStateFromEvent.bind(this, this, 'email');
    this.onFullNameChanged = setStateFromEvent.bind(this, this, 'fullName');
    this.onPasswordChanged = setStateFromEvent.bind(this, this, 'password');
    this.onConfirmPasswordChanged = setStateFromEvent.bind(this, this, 'confirmPassword');

    this.onProfileImageChanged = this.onProfileImageChanged.bind(this);
    this.onSaveClicked = this.onSaveClicked.bind(this);

    this.saveStarted = false;

  }

  componentDidMount() {
    setDocumentTitle('Profile Page');
  }

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    if (
      this.saveStarted &&
      this.props.saveUser !== nextProps.saveUser
    ) {
      this.handleStatusChange(nextProps);
    }
  }

  handleStatusChange(props) {
    if (
      !props.saveUser.saving && props.saveUser.saved
    ) {
      new Noty({
        type: 'success',
        layout: 'bottomLeft',
        text: 'Profile created successfully.',
      }).show();

      this.saveStarted = false;
    } else if (
      !props.saveUser.saving && !props.saveUser.saved
    ) {
      let errorMessage = props.saveUser.error;
      if (!errorMessage) {
        errorMessage = 'Failed to profile due to server error.';
      }

      new Noty({
        type: 'error',
        layout: 'bottomLeft',
        text: errorMessage,
      }).show();

      this.saveStarted = false;
    }
  }

  onProfileImageChanged(file) {
    this.setState({
      profileImage: file._id,
    });
  }

  onSaveClicked() {

    const {password, confirmPassword} = this.state;

    if (password.length > 0) {
      if (password !== confirmPassword) {
        alert("Password and confirm Password should be same ");
        return;
      }
    }

    this.props.actions.saveUserProfileStarting();
    this.props.actions.saveUserProfile(this.state);
    this.saveStarted = true;
  }

  render() {
    const {email, password, confirmPassword, profileImage, fullName} = this.state;
    const {saveUserProfile} = this.props;

    let btnSave;
    if (saveUserProfile && saveUserProfile.saving) {
      btnSave = (
        <Button color="success" disabled>
          <i className="fa fa-spinner fa-spin"/>
          &nbsp;
          Please wait...
        </Button>
      );
    } else {
      btnSave = (
        <Button color="success" onClick={this.onSaveClicked}>
          <i className="fa fa-save"/>
          &nbsp;
          Save
        </Button>
      );
    }

    return (
      <div className="custom-page profile-page">
        <Header pageTitle="Profile Page"/>
        <Container>
          <Card>
            <CardBody>
              <Row>
                <Col sm="6">
                  <FormGroup className="">
                    <ProfileImageUpload
                      image={profileImage}
                      onProfileImageChanged={this.onProfileImageChanged}
                    />
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <FormGroup className="">
                    <Label for="email">Full Name</Label>
                    <Input
                      type="text"
                      name="fullName"
                      id="fullName"
                      placeholder=""
                      value={fullName}
                      onChange={this.onFullNameChanged}
                    />
                  </FormGroup>

                  <FormGroup className="">
                    <Label for="email">Email</Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder=""
                      value={email}
                      onChange={this.onEmailChanged}
                    />
                  </FormGroup>
                  <FormGroup className="">
                    <Label for="password">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder=""
                      value={password}
                      onChange={this.onPasswordChanged}
                    />

                    <p className="helper-text m-1">
                      Leave empty to keep your current password.
                    </p>
                  </FormGroup>
                  {(password && password.length > 0) && (<FormGroup className="">
                    <Label for="confirmPassword"> Confirm Password</Label>
                    <Input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder=""
                      value={confirmPassword}
                      onChange={this.onConfirmPasswordChanged}
                    />

                    <p
                      className={classNames({"helper-text m-1": true, "text-success": (password === confirmPassword)})}>
                      {(password === confirmPassword) && "Passwords match."}
                      {(password !== confirmPassword) && "Must be same as the above password."}
                    </p>
                  </FormGroup>)}
                </Col>
              </Row>
            </CardBody>
          </Card>

          <div className="mt-0">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    {btnSave}
                    {/*{alertMessage}*/}
                  </Col>

                </Row>
              </CardBody>
            </Card>
          </div>
        </Container>

        <Footer/>
      </div>
    );
  }
}


ProfilePage.defaultProps = {
  actions: {},
  saveUserProfile: {},
};

ProfilePage.propTypes = {
  actions: PropTypes.object,
  saveUserProfile: PropTypes.object,
};

const mapStateToProps = (state => ({
  saveUserProfile: state.saveUserProfile,
}));

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, usersActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
