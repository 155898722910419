import React from 'react';
import {
  Container, Col, Row,
} from 'reactstrap';

class Points extends React.Component {
  render() {
    return (
      <div className="section-features section ">
        <Container>
          <Row className="">
            <Col xs={2} md={1} className="">
              <span className="section-number"> 1 </span>
            </Col>
            <Col xs={10} md={5} className=" ">
              <h2 className="pb-2"> Explain how Google tracks you and we don't.</h2>
              <p> Search secrets should remain private and away from the targeting of advertisers.</p>
            </Col>
            <Col xs={2} md={1} className="">
              <span className="section-number"> 2 </span>
            </Col>
            <Col xs={10} md={5} className=" ">
              <h2 className="pb-2">DuckDuckGo apps block Google's hidden trackers.</h2>
              <p> Google trackers lurk on 75% of the top million websites. No thanks.</p>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={2} md={1} className="">
              <span className="section-number"> 3 </span>
            </Col>

            <Col xs={10} md={5} className=" ">
              <h2 className="pb-2"> Get unbiased results outside the Filter Bubble.</h2>
              <p> We're committed to unbiased search that's never based on your search history.</p>
            </Col>

            <Col xs={2} md={1} className="">
              <span className="section-number"> 4 </span>
            </Col>

            <Col xs={10} md={5} className=" ">
              <h2 className="pb-2">Stand up for a pro-privacy business model.</h2>
              <p> The world needs an alternative to the collect-it-all business model.</p>
            </Col>
          </Row>

          <div className="mt-4 text-center">
            <a className="section-button"> Review More Talking Points on Our Blog </a>

          </div>
        </Container>
      </div>
    );
  }
}


export default Points;
