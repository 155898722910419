import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalBody
} from 'reactstrap';
import {connect} from 'react-redux';
import UploadResultForm from "./UploadResultForm";

class UploadPhotoModal extends React.Component {

  render() {
    const {query, toggleModal} = this.props;
    const {searchResults} = this.props;
    const resultData = searchResults[query];

    let content;

    if (!resultData || !resultData.loaded || !resultData.result) {
      content = (
        <UploadResultForm
          isInsideModal={true}
          query={query}
          resultData={resultData}
          toggleModal={toggleModal}
        />
      );
    }

    return (
      <Modal isOpen={true} toggle={toggleModal} className="edit-result-modal">
        <ModalBody>
          {content}
        </ModalBody>
      </Modal>
    );
  }
}

UploadPhotoModal.propTypes = {
  query: PropTypes.string.isRequired,
  searchResults: PropTypes.object,
  toggleModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state => ({
  searchResults: state.searchResults,
}));

const mapDispatchToProps = (dispatch => ({}));

export default connect(mapStateToProps, mapDispatchToProps)(UploadPhotoModal);
