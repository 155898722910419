import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class Products extends React.Component {
  render() {
    const products = [
      "Dabur Honey",
      "Dabur Glucose",
      "Dabur Amla",
      "Dabur Immunity Kit",
    ];

    const productslist = products.map((product, index) => {
      return (
        <Col xs={12} md={12} lg={12} xl={12} key={index} className="product-container">
          {product}
        </Col>
      );
    })

    return (
      <div>
        <div className="products-container">
          <Row>
            <Col xs={"4"}>
              {productslist}
            </Col>
          </Row>
        </div>
      </div>


    );
  }
}

Products.defaultProps = {
  actions: {},
};

Products.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps =(/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
