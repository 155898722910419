import React from 'react';
import PropTypes from 'prop-types';
import {Page} from 'react-pdf';
import {Document} from 'react-pdf/dist/esm/entry.webpack';
import {Button, Row, Col, Label, Input, FormGroup, Form, InputGroup, InputGroupAddon} from 'reactstrap';
import Pagination from "react-js-pagination";
import SearchInput from "../../Common/SearchInput";
import {setStateFromEvent} from "../../../utils/general";

class FilePreviewPDF extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      numPages: -1,
    };

    this.onDocumentLoaded = this.onDocumentLoaded.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.onActivePageChanged = this.onActivePageChanged.bind(this);
    // this.onGoToPageChanged = setStateFromEvent.bind(this, this, 'pageNumber');
    /* Page number needs to be int */
    this.onGoToPageChanged = this.onGoToPageChanged.bind(this);

  }

  /* Other wise this entire thing can be taken down to */

  onGoToPageChanged(event) {
    let pageNumber = event.target.value;
    pageNumber = parseInt(pageNumber)

    this.setState({
      pageNumber,
    });
  }

  onActivePageChanged(pageNumber) {
    console.log(pageNumber);
    console.log(this.state.pageNumber);

    this.setState({
      pageNumber: pageNumber,
    });
  }

  onDocumentLoaded({numPages}) {
    this.setState({
      numPages: numPages,
    });
  }

  previousPage() {
    const {pageNumber} = this.state;
    this.setState({
      pageNumber: pageNumber - 1,
    });

    this.scrollToDocumentTop();
  }

  nextPage() {
    const {pageNumber} = this.state;
    this.setState({
      pageNumber: pageNumber + 1,
    });

    this.scrollToDocumentTop();
  }

  scrollToDocumentTop() {
    window.scrollTo(0, 0);
  }

  getPages(count) {
    const {pageNumber} = this.state;

    if (count <= 0) {
      return (<Page key={pageNumber} pageNumber={pageNumber}/>);
    }

    const numbers = Array.from(new Array(count).keys());
    return numbers.map((number) => {
      const p = number + pageNumber;
      return <Page key={number} pageNumber={p}/>
    });
  }

  render() {
    const {fileUrl} = this.props;
    const {pageNumber, numPages} = this.state;

    if (numPages === 0) {
      return (
        <div>
          File is empty.
        </div>
      )
    }

    console.log(`Page number is ${pageNumber}`);

    let maxPages = (numPages > 0) ? 1 : numPages;

    let content;
    if (!pageNumber) {
      content = (
        <div>
          Incorrect page number.
        </div>
      );
    } else if (pageNumber <= numPages) {
      content = this.getPages(maxPages);
    } else {
      content = (
        <div>
          Page not found.
        </div>
      );
    }

    return (
      <div className="pdf-container ">
        <Document
          file={fileUrl}
          onLoadSuccess={this.onDocumentLoaded}
        >
          <div className=" d-none d-md-block">
          <Row className=" ">
            <Col xs={6} md={6} >
              <Pagination
                prevPageText='<'
                nextPageText='>'
                firstPageText='<<'
                lastPageText='>>'
                activePage={pageNumber}
                itemsCountPerPage={1}
                totalItemsCount={numPages}
                pageRangeDisplayed={5}
                onChange={this.onActivePageChanged}/>
            </Col>

            <Col xs={6} md={6} className=" d-flex align-items-center">
              <Form>
                <FormGroup row>
                  <Label xs="auto" className=" txt-small">Goto Page</Label>
                  <Col xs={2}  md={4} className="pl-0 pr-0">
                    <InputGroup >
                      <Input
                        type="number"
                        name="goToPage"
                        id="goToPage"
                        placeholder="Go to Page"
                        value={pageNumber}
                        min={1}
                        step={1}
                        onChange={this.onGoToPageChanged}
                        className="txt-small "
                        /* On change fires event handler */
                        /* The first param to this fn will be event */
                      />

                      <InputGroupAddon addonType="append">
                        <Button className="txt-small" type="button" color="primary" onClick={this.onActivePageChanged}>
                          Go
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Label xs="auto" className="txt-small">
                    of {numPages} pages
                  </Label>
                </FormGroup>
              </Form>

            </Col>
          </Row>
          </div>

          <div className=" d-md-none mobile-view">
            <Row className=" ">
              <Col xs={6} md={6} >

                 <Pagination
                  prevPageText='<'
                  nextPageText='>'
                  firstPageText='<<'
                  lastPageText='>>'
                  activePage={pageNumber}
                  itemsCountPerPage={1}
                  totalItemsCount={numPages}
                  pageRangeDisplayed={5}
                  onChange={this.onActivePageChanged}/>

              </Col>

              <Col xs={6} md={6} className=" d-flex align-items-center">
                <Form>
                  <FormGroup row>
                    <Label xs="auto" className=" txt2-small">Goto Page</Label>
                    <Col className="pl-0 pr-0">
                      <InputGroup >
                        <Input
                          type="number"
                          name="goToPage"
                          id="goToPage"
                          placeholder="Go to Page"
                          value={pageNumber}
                          min={1}
                          step={1}
                          onChange={this.onGoToPageChanged}
                        />

                        <InputGroupAddon addonType="append">
                          <Button className="txt2-small" type="button" color="primary" onClick={this.onActivePageChanged}>
                            Go
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Label xs="auto" className="txt2-small">
                      of {numPages} pages
                    </Label>
                  </FormGroup>
                </Form>

              </Col>
            </Row>
          </div>
          {content}
        </Document>

        <Pagination
          prevPageText='<'
          nextPageText='>'
          firstPageText='<<'
          lastPageText='>>'
          activePage={pageNumber}
          itemsCountPerPage={1}
          totalItemsCount={numPages}
          pageRangeDisplayed={5}
          onChange={this.onActivePageChanged}/>

       {/*<div className=" d-md-none mobile-view">
        <Pagination
           prevPageText='<'
           nextPageText='>'
           firstPageText='<<'
           lastPageText='>>'
           activePage={pageNumber}
           itemsCountPerPage={1}
           totalItemsCount={numPages}
           pageRangeDisplayed={5}
           onChange={this.onActivePageChanged}/>

        </div>*/}
      </div>
    );
  }
}

FilePreviewPDF.propTypes = {
  fileUrl: PropTypes.string.isRequired,
};

export default FilePreviewPDF;
