import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal, ModalBody, ModalFooter, ModalHeader,
  } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Noty from 'noty';
import * as searchResultActions from '../../../actions/searchResultActions';

class RemoveModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.saveRemove = this.saveRemove.bind(this);

    this.saveStarted = false;
  }

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    const { query } = this.props;

    if (
      this.saveStarted &&
      this.props.metaSearchRemove !== nextProps.metaSearchRemove &&
      nextProps.metaSearchRemove[query]
    ) {
      this.handleStatusChange(nextProps);
    }
  }

  handleStatusChange(props) {
    const { query } = props;

    if (
      props.metaSearchRemove[query] && !props.metaSearchRemove[query].saving && props.metaSearchRemove[query].saved
    ) {
      new Noty({
        type: 'success',
        layout: 'bottomLeft',
        text: 'Removed successfully.',
      }).show();

      this.props.toggleModal();

      this.deleteStarted = false;
    } else if (
      props.metaSearchRemove[query] && !props.metaSearchRemove[query].saving && !props.metaSearchRemove[query].saved
    ) {
      let errorMessage = props.metaSearchRemove[query].error;
      if (!errorMessage) {
        errorMessage = 'Failed to remove due to server error.';
      }

      new Noty({
        type: 'error',
        layout: 'bottomLeft',
        text: errorMessage,
      }).show();

      this.deleteStarted = false;
    }
  }

  saveRemove() {
    const { query, webPageId } = this.props;
    this.props.actions.metaSearchRemoveStarting(query);
    this.props.actions.metaSearchRemove(query, webPageId);
    this.saveStarted = true;
  }

  render() {
    const { query, webPageData, metaSearchRemove, toggleModal } = this.props;
    const removeStateData = metaSearchRemove[query];
    console.log('data in removeStateData');
    console.log(removeStateData);

    let btnSave;
    if (removeStateData && removeStateData.saving) {
      btnSave = (
        <Button color="success" disabled>
          <i className="fa fa-spinner fa-spin" />
          &nbsp;
          Please wait...
        </Button>
      );
    } else {
      btnSave = (
        <Button color="success" onClick={this.saveRemove}>
          <i className="fa fa-save" />
          &nbsp;
          Confirm
        </Button>
      );
    }

    return (
      <Modal isOpen={true} toggle={toggleModal} className="remove-modal">
        <ModalHeader toggle={toggleModal}> Remove </ModalHeader>
        <ModalBody>
          Are you sure you want to remove ?
          <br />
          <br />
          <span className="text-primary">{webPageData.title}</span>

        </ModalBody>
        <ModalFooter>
          {btnSave}
          <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

RemoveModal.defaultProps = {
  actions: {},
  metaSearchRemove: {},
  query: '',
};

RemoveModal.propTypes = {
  actions: PropTypes.object,
  metaSearchRemove: PropTypes.object,
  query: PropTypes.string,
  webPageId: PropTypes.string.isRequired,
  webPageData: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  metaSearchRemove: state.metaSearchRemove,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, searchResultActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveModal);
