import React from 'react';

import PropTypes from 'prop-types';
import {
  Col, Row,
} from 'reactstrap';
import VideoPlayer from "./VideoResult/VideoPlayer";


// import * as actionsActions from '../actions/actions';

class VideoResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {resultData, query} = this.props;

    return (
      <Row className="video-result">
        <Col xs={12}>
          <VideoPlayer
            resultData={resultData}
            query={query}
          />
        </Col>
      </Row>
    );
  }
}

VideoResult.propTypes = {
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};

export default VideoResult;
