import { cloneDeep } from 'lodash';
import {
  UPDATE_USER_STARTING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function updateUserReducer(state = initialState.updateUser, action) {
  switch (action.type) {
    case UPDATE_USER_STARTING: {
      const { userId } = action;
      const newState = cloneDeep(state);

      if (!newState[userId]) {
        newState[userId] = cloneDeep(initialState.updateUser.userId);
      }

      const updateData = newState[userId];
      updateData.saving = true;
      updateData.saved = false;
      updateData.error = null;
      return newState;
    }

    case UPDATE_USER_SUCCESS: {
      const { userId } = action;
      const newState = cloneDeep(state);

      if (!newState[userId]) {
        newState[userId] = cloneDeep(initialState.updateUser.userId);
      }

      const updateData = newState[userId];
      updateData.saving = false;
      updateData.saved = true;
      return newState;
    }

    case UPDATE_USER_ERROR: {
      const { userId } = action;
      const newState = cloneDeep(state);

      if (!newState[userId]) {
        newState[userId] = cloneDeep(initialState.updateUser.userId);
      }

      const updateData = newState[userId];
      updateData.saving = false;
      updateData.saved = false;
      updateData.error = action.err ? action.err.message : 'Failed to update user data';
      return newState;
    }

    default: {
      return state;
    }
  }
}
