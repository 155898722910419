import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Button,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as fileActions from '../../actions/filesActions';
import {apiBaseUrl} from "../../constants/appConfig";
import DeleteFileModal from "./DeleteFileModal";
import EditFileModal from "./EditFileModal";
import FileManagerDataRow from "./FileManagerDataRow";



class ManageFileManagerData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      showDeleteFileModal: false,
      showEditFileModal: false,

    };

    this.toggleDeleteFileModal = this.toggleDeleteFileModal.bind(this);
    this.toggleEditFileModal = this.toggleEditFileModal.bind(this);

    }

  componentDidMount() {
    console.log('Mounted');
    const {fileManagerData} = this.props;
    if (!fileManagerData.loaded && !fileManagerData.loading) {
      this.props.actions.getFileStarting();
      this.props.actions.getFile();
    }
  }

  toggleDeleteFileModal(selectedFile) {
    const { showDeleteFileModal } = this.state;
    this.setState({
      selectedFile,
      showDeleteFileModal: !showDeleteFileModal,
    });
  }

  toggleEditFileModal(selectedFile) {
    const { showEditFileModal } = this.state;
    this.setState({
      selectedFile,
      showEditFileModal: !showEditFileModal,
    });
  }


  getFileManagerDataRows() {
    const {fileManagerData} = this.props;
    const rows = [];

    if (!fileManagerData.loaded) {
      return rows;
    }

    fileManagerData.ids.forEach((fileId, index) => {
      const fileData = fileManagerData.map[fileId];
      const fileLink = `${apiBaseUrl}/files/f/${fileId}`;

      rows.push(

        <FileManagerDataRow
          key={fileId}
          fileId={fileId}
          index={index}
          fileData={fileData}
          fileLink={fileLink}

        />
        /*(

        <tr key={fileId}>
          <th scope="row" className="text-center">{index + 1}</th>
          <td>
            {fileData.originalFileName}
          </td>
          <td> {fileData.fileType}</td>
          <td className="text-center">{fileData.createdAt}</td>
          <td className="text-center">
            <a className="btn btn-sm btn-primary" href={fileLink} target="_blank">
              <i className="fa fa-download"/>
            </a>

            <Button size="sm" color="info" className="ml-2" onClick={() => {
              this.toggleEditFileModal(fileData)
            }}>
              <i className="fa fa-edit"/>
            </Button>

            <Button size="sm" color="danger" className="ml-2" onClick={() => {
              this.toggleDeleteFileModal(fileData);
            }}>
              <i className="fa fa-times" />
            </Button>
          </td>

        </tr>
      )*/
      );
    });
    return rows;
  }

  render() {

   const { fileManagerData }= this.props;
   const { showDeleteFileModal, showEditFileModal } = this.state;

   let deleteFileModal = null;
   let editFileModal = null;

    if (showDeleteFileModal) {
      deleteFileModal = (
        <DeleteFileModal
          selectedFile={this.state.selectedFile}
          toggleModal={this.toggleDeleteFileModal}
        />
      );
    }

    if (showEditFileModal) {
      editFileModal = (
        <EditFileModal
          selectedFile={this.state.selectedFile}
          toggleModal={this.toggleEditFileModal}
        />
      );
    }


    let content;
    if (fileManagerData.loading) {
      content = (
        <div>
          Please wait...
        </div>
      );
    } else if (!fileManagerData.ids.length) {
      content = (
        <div>
          No Files found.
        </div>
      )
    } else {

      const tableRows = this.getFileManagerDataRows();

      content = (
        <Table className="mt-1 files-table table-bordered table-striped">
          <thead>
          <tr className="table-info">
            <th style={{
              width: '6%'
            }} className="text-center"> #
            </th>
            <th style={{
              width: '40%'
            }}> File Name
            </th>
            <th style={{width: '10%'}} className="text-center d-none d-md-table-cell">  Type</th>
            <th style={{width: '20%'}} className="text-center d-none d-md-table-cell"> Uploaded Date </th>
            <th style={{ width: '14%' }} className=" d-none d-md-table-cell">&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {tableRows}
          </tbody>
        </Table>
      );
    }

    return (
      <div>
        {content}
        {deleteFileModal}
        {editFileModal}
      </div>
    );
  }
}

ManageFileManagerData.defaultProps = {
  actions: {},
  fileManagerData: {},
};

ManageFileManagerData.propTypes = {
  actions: PropTypes.object,
  fileManagerData: PropTypes.object,
};

const mapStateToProps = state => ({
  fileManagerData: state.fileManagerData,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, fileActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageFileManagerData);
