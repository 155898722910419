import React from 'react';
import {Container, Col, Row} from "reactstrap";
import PropTypes from "prop-types";
import {apiBaseUrl, siteName} from "../../constants/appConfig";
import logoImg from "../../assets/logo1.png";
import {connect} from "react-redux";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {

     const { appearance } = this.props.appConfig.data;

     let appLogo;
     if (appearance && appearance.logo) {
       appLogo = `${apiBaseUrl}/static/logo.png`;
     } else {
       appLogo = logoImg;
     }

    return (
      <div className="hero ">
        <div className="header ">
          <Container fluid>
            <Row className="">
              <Col xs="auto">
                <a href="" className="">
                   <img src={appLogo} className="img-responsive search-logo" alt={`${siteName} Logo`} />
                </a>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="hero-content text-center">
          <Container fluid>
            <Row>
              <Col>
                <h1> Friends Don’t Let</h1>
                <h1 className="pb-3"> Friends Get Tracked! </h1>
                <h5 className="pb-3 ml-auto mr-auto"> We’re setting the new standard of trust online. You can support
                  this
                  mission by teaching your friends why they should switch to DuckDuckGo. </h5>
              </Col>
            </Row>
            <a className="hero-button"> Check Out Our Conversation Tips </a>
          </Container>
        </div>
      </div>
    );
  }
}

HeroSection.defaultProps = {
  appConfig: {},
};

HeroSection.propTypes = {
  appConfig: PropTypes.object,
};

const mapStateToProps = (state => ({
  appConfig: state.appConfig,
}));

export default connect(mapStateToProps)(HeroSection);
