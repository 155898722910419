import React from 'react';
import PropTypes from 'prop-types';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Button,
  Row, Col, Container, Form, FormGroup, Label, Input
} from 'reactstrap';

import {cloneDeep, isNil} from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {SketchPicker} from 'react-color';
import classnames from 'classnames';
import Noty from 'noty';
import Footer from './Common/Footer';
import Header from './CustomPage/Header';
import ManageAuthTokens from "./SettingsPage/ManageUsers";
import {
  apiBaseUrl,
  appName,
  defaultBackgroundColor,
  defaultSearchEngineUrl,
  defaultTagline,
  siteName
} from '../constants/appConfig';
import AppUpdate from './SettingsPage/AppUpdate';
import * as  appConfigActions from '../actions/appConfigActions';
import {setDocumentTitle} from "../utils/general";
import LogoUpload from './SettingsPage/LogoUpload';
import {Link} from 'react-router-dom';
import BackgroundImageUpload from "./SettingsPage/BackgroundImageUpload";
import ReactQuill from "react-quill";

const modules = {
  toolbar: [
    [{'header': [1, 2, 3, 4, 5, 6, false]}],
    [
      {'font': []}, {'size': []}, {'color': []}, {'background': []}, {'align': []},
    ],
    [
      'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'clean',
    ],
    [
      {'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}, {'direction': 'rtl'},
    ],
    ['link', 'script'],
  ],
};

const formats = [
  'header', 'font', 'size', 'color', 'background',
  'bold', 'italic', 'underline', 'strike', 'align', 'blockquote', 'clean',
  'list', 'bullet', 'indent', 'code-block',
  'link', 'script'
];

class SettingsPage extends React.Component {
  static getConfigVars(props) {
    const {appConfig} = props;
    const configVars = {
      appearance: {
        siteName: appName,
        tagline: defaultTagline,
        theme: '',
        logo: '',
        backgroundColor: defaultBackgroundColor,
        backgroundImage: '',
        backgroundPosition: '',
        customBodyCSS: '',
      },

      search: {
        baseUrl: '',
        defaultSearchEngineUrl,
        siteAdminEmail: '',
        showFirstTimeUserModal: false,
        firstTimeUserNotice: '',
        noticeButtonText: '',
        noticeHeading: '',
      },

      authentication: {
        requireAuthentication: false,
        editHistory: false,
        fileAccess: false,
        fileUpload: false,
      },


      /* extension: {
       result: false,
       webPage: false,
       },*/

      privacy: {
        saveUserInfo: false,
        searchSuggestionsEnabled: false,
        trackSearchCount: false,
        trackMetaSearchClicks: false,
      },

      /* resultTypes: {
       metaSearch: false,
       redirect: false,
       fixedText: false,
       file: false,
       person: false,
       dictionaryPrefix: false,
       dictionaryWord: false,
       },*/
    };

    if (!appConfig.loaded) {
      return configVars;
    }

    for (const k in appConfig.data) {
      if (Object.prototype.hasOwnProperty.call(appConfig.data, k)) {
        if (!isNil(appConfig.data[k]) && !isNil(configVars[k])) {
          for (const k2 in configVars[k]) {
            if (Object.prototype.hasOwnProperty.call(configVars[k], k2)) {
              if (!isNil(appConfig.data[k][k2])) {
                configVars[k][k2] = appConfig.data[k][k2];
              }
            }
          }
        }
      }
    }

    return configVars;
  }

  constructor(props) {
    super(props);

    const configVars = SettingsPage.getConfigVars(this.props);

    this.state = {
      activeTab: 'systemconfiguration',

      ...configVars
    };

    this.setActiveTab = this.setActiveTab.bind(this);
    this.onSiteNameChanged = this.onSiteNameChanged.bind(this);
    this.onBaseUrlChanged = this.onBaseUrlChanged.bind(this);
    this.onTaglineChanged = this.onTaglineChanged.bind(this);
    this.onThemeChanged = this.onThemeChanged.bind(this);
    this.onLogoChanged = this.onLogoChanged.bind(this);
    this.clearLogo = this.clearLogo.bind(this);
    this.onBackgroundColorChanged = this.onBackgroundColorChanged.bind(this);
    this.onBackgroundImageChanged = this.onBackgroundImageChanged.bind(this);
    this.clearBackgroundImage = this.clearBackgroundImage.bind(this);
    this.onBackgroundPositionChanged = this.onBackgroundPositionChanged.bind(this);
    this.onCustomBodyCSSChanged = this.onCustomBodyCSSChanged.bind(this);

    this.onSearchEngineUrlChanged = this.onSearchEngineUrlChanged.bind(this);
    this.onSiteAdminEmailChanged = this.onSiteAdminEmailChanged.bind(this);

    this.onShowFirstTimeUserModalChanged = this.onShowFirstTimeUserModalChanged.bind(this);
    this.onFirstTimeUserNoticeChanged = this.onFirstTimeUserNoticeChanged.bind(this);
    this.onNoticeHeadingChanged = this.onNoticeHeadingChanged.bind(this);
    this.onNoticeButtonTextChanged = this.onNoticeButtonTextChanged.bind(this);

    this.onRequireAuthenticationChanged = this.onRequireAuthenticationChanged.bind(this);
    this.onEditHistoryChanged = this.onEditHistoryChanged.bind(this);
    this.onFileAccessChanged = this.onFileAccessChanged.bind(this);
    this.onFileUploadChanged = this.onFileUploadChanged.bind(this);

    this.onSaveUserInfoChanged = this.onSaveUserInfoChanged.bind(this);
    this.onSearchSuggestionsEnabledChanged = this.onSearchSuggestionsEnabledChanged.bind(this);
    this.onTrackSearchCountChanged = this.onTrackSearchCountChanged.bind(this);
    this.onTrackMetaSearchClicksChanged = this.onTrackMetaSearchClicksChanged.bind(this);
    this.onShowPrivacyStatusChanged = this.onShowPrivacyStatusChanged.bind(this);


    /*this.onResultChanged = this.onResultChanged.bind(this);
     this.onWebPageChanged = this.onWebPageChanged.bind(this);

     this.onMetaSearchChanged = this.onMetaSearchChanged.bind(this);
     this.onRedirectChanged = this.onRedirectChanged.bind(this);
     this.onFixedTextChanged = this.onFixedTextChanged.bind(this);
     this.onFileChanged = this.onFileChanged.bind(this);
     this.onPersonChanged = this.onPersonChanged.bind(this);
     this.onDictionaryPrefixChanged = this.onDictionaryPrefixChanged.bind(this);
     this.onDictionaryWordChanged = this.onDictionaryWordChanged.bind(this);
     */
    this.onSaveClicked = this.onSaveClicked.bind(this);

    this.saveStarted = false;
  }

  componentDidMount() {
    setDocumentTitle('Settings');
  }

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    if (this.props.appConfig !== nextProps.appConfig && (
      this.props.appConfig.loading !== nextProps.appConfig.loading &&
      nextProps.appConfig.loaded
    )) {
      const configVars = SettingsPage.getConfigVars(nextProps);
      this.setState(configVars);
    }

    if (
      this.saveStarted &&
      this.props.saveAppConfig !== nextProps.saveAppConfig
    ) {
      this.handleStatusChange(nextProps);
    }
  }

  handleStatusChange(props) {
    if (
      !props.saveAppConfig.saving && props.saveAppConfig.saved
    ) {
      new Noty({
        type: 'success',
        layout: 'bottomLeft',
        text: 'App configuration has been updated. It may take up to 5 minutes to take effect for all users.',
      }).show();

      this.saveStarted = false;
    } else if (
      !props.saveAppConfig.saving && !props.saveAppConfig.saved
    ) {
      let errorMessage = props.saveAppConfig.error;
      if (!errorMessage) {
        errorMessage = 'Failed to save app configuration due to server error.';
      }

      new Noty({
        type: 'error',
        layout: 'bottomLeft',
        text: errorMessage,
      }).show();

      this.saveStarted = false;
    }
  }

  onSiteNameChanged(event) {
    const appearance = cloneDeep(this.state.appearance);
    appearance.siteName = event.target.value;
    this.setState({
      appearance,
    });
  }

  onBaseUrlChanged(event) {
    const search = cloneDeep(this.state.search);
    search.baseUrl = event.target.value;
    this.setState({
      search,
    });
  }

  onTaglineChanged(event) {
    const appearance = cloneDeep(this.state.appearance);
    appearance.tagline = event.target.value;
    this.setState({
      appearance,
    });
  }

  onThemeChanged(event) {
    const appearance = cloneDeep(this.state.appearance);
    appearance.theme = event.target.value;
    this.setState({
      appearance,
    });

    // TODO Remove other theme classes and add theme class to body
  }

  onLogoChanged(file) {
    const appearance = cloneDeep(this.state.appearance);
    appearance.logo = file._id;
    this.setState({
      appearance,
    });
  }

  clearLogo() {
    const appearance = cloneDeep(this.state.appearance);
    appearance.logo = '';
    this.setState({
      appearance,
    });
  }

  onBackgroundColorChanged(color) {
    const appearance = cloneDeep(this.state.appearance);
    appearance.backgroundColor = color.hex;
    this.setState({
      appearance,
    });
  }

  onBackgroundImageChanged(file) {
    const appearance = cloneDeep(this.state.appearance);
    appearance.backgroundImage = file._id;
    this.setState({
      appearance,
    });
  }

  clearBackgroundImage() {
    const appearance = cloneDeep(this.state.appearance);
    appearance.backgroundImage = '';
    this.setState({
      appearance,
    });
  }

  onBackgroundPositionChanged(event) {
    const appearance = cloneDeep(this.state.appearance);
    appearance.backgroundPosition = event.target.value;
    this.setState({
      appearance,
    });
  }

  onCustomBodyCSSChanged(event) {
    const appearance = cloneDeep(this.state.appearance);
    appearance.customBodyCSS = event.target.value;
    this.setState({
      appearance,
    });
  }


  onSearchEngineUrlChanged(event) {
    const search = cloneDeep(this.state.search);
    search.defaultSearchEngineUrl = event.target.value;
    this.setState({
      search,
    });
  }

  onShowFirstTimeUserModalChanged(event) {
    const search = cloneDeep(this.state.search);
    search.showFirstTimeUserModal = event.target.checked;
    this.setState({
      search,
    });
  }

  onFirstTimeUserNoticeChanged(value) {
    const search = cloneDeep(this.state.search);
    search.firstTimeUserNotice = value;
    this.setState({
      search,
    });
  }

  onNoticeHeadingChanged(event) {
    const search = cloneDeep(this.state.search);
    search.noticeHeading = event.target.value;
    this.setState({
      search,
    });
  }

  onNoticeButtonTextChanged(event) {
    const search = cloneDeep(this.state.search);
    search.noticeButtonText = event.target.value;
    this.setState({
      search,
    });
  }

  onSiteAdminEmailChanged(event) {
    const search = cloneDeep(this.state.search);
    search.siteAdminEmail = event.target.value;
    this.setState({
      search,
    });
  }


  onRequireAuthenticationChanged(event) {
    const authentication = cloneDeep(this.state.authentication);
    authentication.requireAuthentication = event.target.checked;
    this.setState({
      authentication,
    });
    // console.log('New result types is', resultTypes);
  }

  onFileAccessChanged(event) {
    const authentication = cloneDeep(this.state.authentication);
    authentication.fileAccess = event.target.checked;
    this.setState({
      authentication,
    });
    // console.log('New result types is', resultTypes);
  }

  onFileUploadChanged(event) {
    const authentication = cloneDeep(this.state.authentication);
    authentication.fileUpload = event.target.checked;
    this.setState({
      authentication,
    });
    // console.log('New result types is', resultTypes);
  }

  onEditHistoryChanged(event) {
    const authentication = cloneDeep(this.state.authentication);
    authentication.editHistory = event.target.checked;
    this.setState({
      authentication,
    });
  }


  /*onResultChanged(event) {
   const extension = cloneDeep(this.state.extension);
   extension.result = event.target.checked;
   this.setState({
   extension,
   });

   }

   onWebPageChanged(event) {
   const extension = cloneDeep(this.state.extension);
   extension.webPage = event.target.checked;
   this.setState({
   extension,
   });

   }*/

  onSaveUserInfoChanged(event) {
    const privacy = cloneDeep(this.state.privacy);
    privacy.saveUserInfo = event.target.checked;
    this.setState({
      privacy,
    });

  }

  onSearchSuggestionsEnabledChanged(event) {
    const privacy = cloneDeep(this.state.privacy);
    privacy.searchSuggestionsEnabled = event.target.checked;
    this.setState({
      privacy,
    });
  }

  onTrackSearchCountChanged(event) {
    const privacy = cloneDeep(this.state.privacy);
    privacy.trackSearchCount = event.target.checked;
    this.setState({
      privacy,
    });
  }

  onTrackMetaSearchClicksChanged(event) {
    const privacy = cloneDeep(this.state.privacy);
    privacy.trackMetaSearchClicks = event.target.checked;
    this.setState({
      privacy,
    });
  }

  onShowPrivacyStatusChanged(event) {
    const privacy = cloneDeep(this.state.privacy);
    privacy.showPrivacyStatus = event.target.checked;
    this.setState({
      privacy,
    });
  }

  /*

   onMetaSearchChanged(event) {
   const resultTypes = cloneDeep(this.state.resultTypes);
   resultTypes.metaSearch = event.target.checked;
   this.setState({
   resultTypes,
   });
   }

   onRedirectChanged(event) {
   const resultTypes = cloneDeep(this.state.resultTypes);
   resultTypes.redirect = event.target.checked;
   this.setState({
   resultTypes,
   });
   }

   onFixedTextChanged(event) {
   const resultTypes = cloneDeep(this.state.resultTypes);
   resultTypes.fixedText = event.target.checked;
   this.setState({
   resultTypes,
   });
   }

   onFileChanged(event) {
   const resultTypes = cloneDeep(this.state.resultTypes);
   resultTypes.file = event.target.checked;
   this.setState({
   resultTypes,
   });
   }

   onPersonChanged(event) {
   const resultTypes = cloneDeep(this.state.resultTypes);
   resultTypes.person = event.target.checked;
   this.setState({
   resultTypes,
   });
   }

   onDictionaryWordChanged(event) {
   const resultTypes = cloneDeep(this.state.resultTypes);
   resultTypes.dictionaryWord = event.target.checked;
   this.setState({
   resultTypes,
   });
   }

   onDictionaryPrefixChanged(event) {
   const resultTypes = cloneDeep(this.state.resultTypes);
   resultTypes.dictionaryPrefix = event.target.checked;
   this.setState({
   resultTypes,
   });

   }
   */

  onSaveClicked() {
    this.props.actions.saveAppConfigStarting();
    this.props.actions.saveAppConfig(this.state);
    this.saveStarted = true;
  }

  setActiveTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {activeTab, appearance, search, authentication, privacy} = this.state;
    const {saveAppConfig, appConfig} = this.props;

    let preContent = null;
    if (!appConfig.loaded) {
      preContent = 'Please wait...';
    } else if (appConfig.loaded && !appConfig.canEdit) {
      preContent = (
        <div>
          <p className="mt-5 mb-0">
            You do not have permission to access this page.
            If you have an authentication token,
            you can set it in the <Link to="/auth">Authentication page</Link>.
          </p>

          <p className="mt-3 mb-0">
            <Link to="/">Go to Home page</Link>
          </p>
        </div>
      );
    }

    if (!isNil(preContent)) {
      return (
        <div className="custom-page settings-page">
          <Container>
            {preContent}
          </Container>
        </div>
      )
    }

    let btnSave;

    if (saveAppConfig.saving) {
      btnSave = (
        <Button color="success" disabled>
          <i className="fa fa-spinner fa-spin"/>
          &nbsp;
          Saving
        </Button>
      );
    } else {
      btnSave = (
        <Button color="success" onClick={this.onSaveClicked}>
          <i className="fa fa-save"/>
          &nbsp;
          Save
        </Button>
      );
    }

    const bottomCard = (
      <div className="mt-0 bottom-card">
        <Card>
          <CardBody>
            {btnSave}
          </CardBody>
        </Card>
      </div>
    );

    let showContent;

    if (search.showFirstTimeUserModal) {
      showContent = (
        <Form>


          <FormGroup>
            <Label for="firstTimeUser">Notice Content</Label>
            <ReactQuill
              className="first-time-user-notice"
              theme="snow"
              value={search.firstTimeUserNotice}
              onChange={this.onFirstTimeUserNoticeChanged}
              modules={modules}
              formats={formats}
            />
          </FormGroup>

          <FormGroup>
            <Label for="noticeHeading">Notice Heading</Label>
            <Input
              type="text"
              name="noticeHeading"
              id="noticeHeading"
              placeholder=""
              value={search.noticeHeading}
              onChange={this.onNoticeHeadingChanged}
            />
          </FormGroup>
          <FormGroup>
            <Label for="noticeButtonText">Notice Button Text</Label>
            <Input
              type="text"
              name="noticeButtonText"
              id="noticeButtonText"
              placeholder=""
              value={search.noticeButtonText}
              onChange={this.onNoticeButtonTextChanged}
            />

          </FormGroup>

        </Form>

      );
    }

    const customStyles = {};

    if (appearance.backgroundColor) {
      customStyles.backgroundColor = appearance.backgroundColor;
    }

    if (appearance.backgroundImage) {
      customStyles.backgroundImage = `${apiBaseUrl}/files/f/${appearance.backgroundImage}`;
    }

    return (
      <div className="custom-page settings-page" style={customStyles}>
        <Header pageTitle={`${siteName} Settings`}/>

        <Container>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({active: activeTab === 'systemconfiguration', 'pointer link': true})}
                onClick={() => {
                  this.setActiveTab('systemconfiguration');
                }}
                tag="div"
              >
                System Configuration
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({active: activeTab === 'appearance', 'pointer link': true})}
                onClick={() => {
                  this.setActiveTab('appearance');
                }}
                tag="div"
              >
                Appearance
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({active: activeTab === 'authentication', 'pointer link': true})}
                onClick={() => {
                  this.setActiveTab('authentication');
                }}
                tag="div"
              >
                Authentication
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({active: activeTab === 'users', 'pointer link': true})}
                onClick={() => {
                  this.setActiveTab('users');
                }}
                tag="div"
              >
                Users
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({active: activeTab === 'privacy', 'pointer link': true})}
                onClick={() => {
                  this.setActiveTab('privacy');
                }}
                tag="div"
              >
                Privacy
              </NavLink>
            </NavItem>
            {/*  <NavItem>
             <NavLink
             className={classnames({ active: activeTab === 'extension', 'pointer link': true })}
             onClick={() => {
             this.setActiveTab('extension');
             }}
             tag="div"
             >
             Extension
             </NavLink>
             </NavItem>
             <NavItem>
             <NavLink
             className={classnames({ active: activeTab === 'resulttypes', 'pointer link': true })}
             onClick={() => {
             this.setActiveTab('resulttypes');
             }}
             tag="div"
             >
             Result Types
             </NavLink>
             </NavItem>*/}
            {/*<NavItem>*/}
            {/*<NavLink*/}
            {/*className={classnames({ active: activeTab === 'update', 'pointer link': true })}*/}
            {/*onClick={() => {*/}
            {/*this.setActiveTab('update');*/}
            {/*}}*/}
            {/*tag="div"*/}
            {/*>*/}
            {/*Update*/}
            {/*</NavLink>*/}
            {/*</NavItem>*/}
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="appearance">

              <Card>
                <CardBody>

                  <Form>
                    <Row>
                      <Col sm="6">
                        <FormGroup className=''>
                          <Label for="siteName">Site Name</Label>
                          <Input
                            type="text"
                            name="siteName"
                            id="siteName"
                            placeholder=""
                            value={appearance.siteName}
                            onChange={this.onSiteNameChanged}
                          />

                          <p className="helper-text">
                            This will be the name of your Loki instance.
                          </p>
                        </FormGroup>

                        <FormGroup className=''>
                          <Label for="siteName">Tagline</Label>
                          <Input
                            type="text"
                            name="tagline"
                            id="tagline"
                            placeholder=""
                            value={appearance.tagline}
                            onChange={this.onTaglineChanged}
                          />

                          <p className="helper-text">
                            This is shown on the home page below search input.
                          </p>
                        </FormGroup>

                        <FormGroup className=''>
                          <Label for="siteName">Theme</Label>
                          <Input
                            type="select"
                            name="theme"
                            id="theme"
                            value={appearance.theme}
                            onChange={this.onThemeChanged}
                          >
                            <option value=""> -- Select Theme --  </option>
                            <option value="default-theme">Default</option>
                            <option value="snow-theme">Snow</option>
                          </Input>

                        </FormGroup>

                        <FormGroup className="">
                          <Label for="color">Background Color</Label>
                          <SketchPicker
                            color={appearance.backgroundColor}
                            onChangeComplete={this.onBackgroundColorChanged}
                          />

                          <p className="helper-text mt-3">
                            This will be used as the background color for all pages in this {appName} instance.
                            Only light colors are supported now.
                          </p>
                        </FormGroup>
                      </Col>

                      <Col sm="6">
                        <FormGroup className="">
                          <Row>
                            <Col xs="auto">
                              <Label for="exampleFile">Logo</Label>
                            </Col>

                            {appearance.logo && (
                              <Col xs="auto" className="ml-auto">
                                <a href={null} className="pointer link text-primary" onClick={this.clearLogo}>
                                  <small>Clear</small>
                                </a>
                              </Col>
                            )}
                          </Row>

                          <LogoUpload
                            logo={appearance.logo}
                            onLogoChanged={this.onLogoChanged}
                          />

                          <p className="helper-text mt-2 mb-0">
                            You must preferably upload a round image in PNG format with 1024x1024 resolution.
                          </p>
                          <p className="helper-text mt-2 mb-0">
                            The server will automatically resize the original image into
                            different resolutions required.

                            After changing the logo, wait for 1 minute for thumbnail
                            generation to complete and then
                            reload your browser cache.
                          </p>
                          <p className="helper-text mt-2 mb-0">
                            You will need to manually update the browser extension
                            and any local browser configuration for the
                            new logo to take effect in those places.
                          </p>
                        </FormGroup>
                        <FormGroup className="">
                          <Row>
                            <Col xs="auto">
                              <Label for="">Background Image</Label>
                            </Col>

                            {appearance.backgroundImage && (
                              <Col xs="auto" className="ml-auto">
                                <a href={null} className="pointer link text-primary"
                                   onClick={this.clearBackgroundImage}>
                                  <small>Clear</small>
                                </a>
                              </Col>
                            )}
                          </Row>

                          <BackgroundImageUpload
                            image={appearance.backgroundImage}
                            onBackgroundImageChanged={this.onBackgroundImageChanged}
                          />
                        </FormGroup>

                        <FormGroup className=''>
                          <Label for="backgroundPosition"> Background position</Label>
                          <Input
                            type="text"
                            name="backgroundPosition"
                            id="backgroundPosition"
                            placeholder=""
                            value={appearance.backgroundPosition}
                            onChange={this.onBackgroundPositionChanged}
                          />
                        </FormGroup>

                        <FormGroup className=''>
                          <Label for="customBodyCSS"> Custom Body CSS</Label>
                          <Input
                            type="textarea"
                            name="customBodyCSS"
                            id="customBodyCSS"
                            placeholder=""
                            value={appearance.customBodyCSS}
                            onChange={this.onCustomBodyCSSChanged}
                          />

                          <p className="helper-text mt-2 mb-0">
                            This must be a JSON string, it will be parsed and passed to <code>body
                            style</code> attribute.
                          </p>
                        </FormGroup>

                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </TabPane>

            <TabPane tabId="systemconfiguration">
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col sm="6">
                        <FormGroup className=''>
                          <Label for="baseUrl">Base URL</Label>
                          <Input
                            type="text"
                            name="baseUrl"
                            id="baseUrl"
                            placeholder=""
                            value={search.baseUrl}
                            onChange={this.onBaseUrlChanged}
                          />

                          <p className="helper-text">
                            This field is required for the {appName} browser
                            extension to work properly.
                            When you change this, you will need to remove
                            the current extension, download the new
                            version and install it.
                          </p>
                        </FormGroup>

                        <FormGroup className=''>
                          <Label for="siteAdminEmail">Site Administrator Email Address</Label>
                          <Input
                            type="text"
                            name="siteAdminEmail"
                            id="siteAdminEmail"
                            placeholder=""
                            value={search.siteAdminEmail}
                            onChange={this.onSiteAdminEmailChanged}
                          />

                          <p className="helper-text">
                            This is displayed in About & Privacy pages so
                            that users can get in touch with the site administrator.
                          </p>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup className="">
                          <Label for="searchEngine">Default Search Engine URL</Label>
                          <Input
                            type="text"
                            name="searchEngine"
                            id="searchEngine"
                            placeholder=""
                            value={search.defaultSearchEngineUrl}
                            onChange={this.onSearchEngineUrlChanged}
                          />
                          <p className="helper-text">
                            Enter your default search engine URL here, using %s in place of the actual query.
                            <br/>
                            Example: https://duckduckgo.com/?q=%s
                          </p>
                        </FormGroup>

                        <FormGroup>
                          <Label check>
                            <Input className="ml-0" type="checkbox"
                                   checked={search.showFirstTimeUserModal}
                                   onChange={this.onShowFirstTimeUserModalChanged}

                            />&nbsp;&nbsp;&nbsp;
                            Show Notice to First time visitors
                          </Label>

                        </FormGroup>

                        {showContent}

                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>

            </TabPane>


            <TabPane tabId="authentication">
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col xs={12}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={authentication.requireAuthentication}
                              onChange={this.onRequireAuthenticationChanged}

                            />&nbsp;
                            Require authentication to access Search Results
                          </Label>

                          <p className="helper-text">
                            If this setting is turned on,
                            authentication Token is required to
                            access the search results.
                          </p>
                        </FormGroup>
                      </Col>
                      <Col xs={12} className="mt-2">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={authentication.fileAccess}
                              onChange={this.onFileAccessChanged}

                            />&nbsp;
                            Require authentication to access Files
                          </Label>

                          <p className="helper-text">
                            TODO
                          </p>
                        </FormGroup>
                      </Col>

                      <Col xs={12} className="mt-2">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={authentication.fileUpload}
                              onChange={this.onFileUploadChanged}

                            />&nbsp;
                            Require authentication to upload Files
                          </Label>

                          <p className="helper-text">
                            TODO
                          </p>
                        </FormGroup>
                      </Col>
                      <Col xs={12} className="mt-2">
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox"
                                   checked={authentication.editHistory}
                                   onChange={this.onEditHistoryChanged}
                            />&nbsp;
                            Save Edit History
                          </Label>

                          <p className="helper-text">
                            This setting is not completely developed yet.
                            Later on, if this setting is turned on,
                            every edited version of search results
                            is stored in a separate collection.
                            This might increase your database size.
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </TabPane>

            <TabPane tabId="users">
              <Card>
                <CardBody>

                  <ManageAuthTokens/>

                </CardBody>
              </Card>

            </TabPane>

            <TabPane tabId="privacy">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="6">
                      <Form>
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox"
                                   checked={privacy.saveUserInfo}
                                   onChange={this.onSaveUserInfoChanged}

                            />&nbsp;
                            Save Search hits & User Information
                          </Label>

                          <p className="helper-text">
                            If this setting is turned on, each search hit is
                            logged separately, along with the User Agent & IP Address of user.
                          </p>
                        </FormGroup>
                        {/*<FormGroup check className="mt-3">*/}
                        {/*<Label check>*/}
                        {/*<Input type="checkbox"*/}
                        {/*checked={privacy.searchSuggestionsEnabled}*/}
                        {/*onChange={this.onSearchSuggestionsEnabledChanged}*/}
                        {/*/>&nbsp;*/}
                        {/*Disable Search Suggestions*/}
                        {/*</Label>*/}

                        {/*<p className="helper-text">*/}
                        {/*This setting is not active yet.*/}
                        {/*Later on, if this setting is turned on, search suggestions are disabled.*/}
                        {/*Adds more privacy to end users.*/}
                        {/*</p>*/}
                        {/*</FormGroup>*/}
                        <FormGroup check className='mt-3'>
                          <Label check>
                            <Input type="checkbox"
                                   checked={privacy.trackSearchCount}
                                   onChange={this.onTrackSearchCountChanged}

                            />&nbsp;
                            Track Search Count
                          </Label>

                          <p className="helper-text">
                            If this setting is turned on, number of searches (searchCount) is
                            tracked for each query. This field helps in making
                            most searched queries come on top of the search suggestions.
                          </p>
                        </FormGroup>
                        <FormGroup check className='mt-3'>
                          <Label check>
                            <Input type="checkbox"
                                   checked={privacy.trackMetaSearchClicks}
                                   onChange={this.onTrackMetaSearchClicksChanged}

                            />&nbsp;
                            Track Web Page Clicks in Meta Search view
                          </Label>

                          <p className="helper-text">
                            This setting is not active yet.
                            Later on, if this setting is turned on, number of clicks (searchCount) is
                            tracked for each web page link. This field helps in ordering the
                            search results in Meta Search view.
                          </p>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox"
                                   checked={privacy.showPrivacyStatus}
                                   onChange={this.onShowPrivacyStatusChanged}

                            />&nbsp;
                            Show Privacy Status
                          </Label>

                          <p className="helper-text">
                            If this setting is turned on, privacy status is
                            shown near the search form.

                          </p>
                        </FormGroup>

                      </Form>
                    </Col>
                  </Row>

                </CardBody>
              </Card>

            </TabPane>
            {/*<TabPane tabId="extension">
             <Card>
             <CardBody>
             <Row>
             <Col sm="6">
             <Form>
             <FormGroup className='ml-3'>
             <Label check>
             <Input
             type="checkbox"
             checked={extension.result}
             onChange={this.onResultChanged}
             />{' '}
             Crawl Results from Search Engines through extension
             </Label>
             </FormGroup>
             <FormGroup className='ml-3'>
             <Label check>
             <Input type="checkbox"
             checked={extension.webPage}
             onChange={this.onWebPageChanged}
             />{' '}
             Allow Saving Web Pages through extension
             </Label>
             </FormGroup>
             </Form>
             </Col>
             </Row>

             </CardBody>
             </Card>
             </TabPane>

             <TabPane tabId="resulttypes">
             <Card>
             <CardBody>
             <Row>
             <Col sm="6">
             <Form>
             <FormGroup className='ml-3'>
             <Label check>
             <Input
             type="checkbox"
             checked={resultTypes.metaSearch}
             onChange={this.onMetaSearchChanged}
             />{' '}
             META Search
             </Label>
             </FormGroup>
             <FormGroup className='ml-3'>
             <Label check>
             <Input
             type="checkbox"
             checked={resultTypes.redirect}
             onChange={this.onRedirectChanged}

             />{' '}
             Redirect
             </Label>
             </FormGroup>
             <FormGroup className='ml-3'>
             <Label check>
             <Input type="checkbox"
             checked={resultTypes.fixedText}
             onChange={this.onFixedTextChanged}
             />{' '}
             Fixed Text
             </Label>
             </FormGroup>
             <FormGroup className='ml-3'>
             <Label check>
             <Input type="checkbox"
             checked={resultTypes.file}
             onChange={this.onFileChanged}

             />{' '}
             File
             </Label>
             </FormGroup>
             <FormGroup className='ml-3'>
             <Label check>
             <Input type="checkbox"
             checked={resultTypes.person}
             onChange={this.onPersonChanged}

             />{' '}
             Person
             </Label>
             </FormGroup>

             <FormGroup className='ml-3'>
             <Label check>
             <Input type="checkbox"
             checked={resultTypes.dictionaryPrefix}
             onChange={this.onDictionaryPrefixChanged}
             />{' '}
             Dictionary Prefix
             </Label>
             </FormGroup>

             <FormGroup className='ml-3'>
             <Label check>
             <Input type="checkbox"
             checked={resultTypes.dictionaryWord}
             onChange={this.onDictionaryWordChanged}

             />{' '}
             Dictionary Word
             </Label>
             </FormGroup>
             </Form>
             </Col>
             </Row>
             </CardBody>
             </Card>
             </TabPane>*/}

            <TabPane tabId="update">
              <AppUpdate/>
            </TabPane>
          </TabContent>

          {bottomCard}
        </Container>

        <Footer/>
      </div>
    );
  }
}

SettingsPage.defaultProps = {
  actions: {},
  appConfig: {},
  saveAppConfig: {},
};

SettingsPage.propTypes = {
  actions: PropTypes.object,
  appConfig: PropTypes.object,
  saveAppConfig: PropTypes.object,
};

const mapStateToProps = (state => ({
  appConfig: state.appConfig,
  saveAppConfig: state.saveAppConfig,
}));

const mapDispatchToProps = (dispatch => ({
  actions: bindActionCreators(
    Object.assign({}, appConfigActions),
    dispatch,
  ),
}));

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
