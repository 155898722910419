import React from 'react';
import {
   Col,
  Container,
  Row,
 } from 'reactstrap';
import Footer from './Common/Footer';
import Header from './CustomPage/Header';
import {setDocumentTitle} from "../utils/general";
import PriceDetails from "./CartPage/PriceDetails";
import ProductData from "./CartPage/ProductData";




class CartPage extends React.Component {
  componentDidMount() {
    setDocumentTitle('Cart Page');
  }

  render() {
    return (
      <div className="custom-page cart-page">
        <Header pageTitle="Cart Page"/>
        <Container>
          <Row>
            <Col xs={12} md={12} lg={8}>
              <ProductData />

            </Col>
            <Col xs={12} md={12} lg={4}>
              <PriceDetails/>
            </Col>
          </Row>
        </Container>
        <Footer/>
      </div>
    );
  }
}


export default CartPage;
