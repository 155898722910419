import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col,
  Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import * as actionsActions from '../actions/actions';

class BasicDetails extends React.Component {
  render() {
    return (
      <div className="book-details">
        <Card>
          <CardBody>
            <Row className="">
              <Col xs="12" className="">
                <h5> The Lion, the Witch and Wardrobe
                </h5>
              </Col>
            </Row>

            <div>
              <p className="mt-3">
                The Lion, the Witch and the Wardrobe is a fantasy novel for children
                by C. S. Lewis, published by Geoffrey Bles in 1950. It is the first published
                and best known of seven novels in The Chronicles of Narnia. Among all the authors
                books, it is also the most widely held in libraries
              </p>
              <hr />
              <p><b> Author : </b> C.S. Lewis </p>
              <p><b> Originally Published :</b> 16 October 1950 </p>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}


BasicDetails.defaultProps = {
  actions: {},
};

BasicDetails.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);
