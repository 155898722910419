import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class DictionaryWord extends React.Component {
  render() {
    const { word } = this.props;

    return (
      <Col xs={12} md={6} lg={4} xl={3}>
        <p className="">
          <Link to={`/search?q=${word}`}>
            {word}
          </Link>
        </p>
      </Col>
    );
  }
}

DictionaryWord.propTypes = {
  word: PropTypes.string.isRequired,
};

export default DictionaryWord;
