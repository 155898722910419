import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Card, CardBody, Col,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class MoviesWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="movies-container">
        <Card>
          <CardBody>
            <Row>
              <Col xs={12} md={3}>
                <img src="../../assets/mv2.jpg" style={{width: '75px'}} />
                <img src="../../assets/mv3.jpg" style={{width: '75px'}} />
              </Col>
              <Col xs={12} md={3}>
                <img src="../../assets/mv1.jpg" style={{width: '75px'}} />
                <img src="../../assets/mv4.jpg" style={{width: '75px'}} />
              </Col>

            </Row>

          </CardBody>
        </Card>
      </div>
    );
  }
}

MoviesWidget.defaultProps = {
  actions: {},
};

MoviesWidget.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps =(/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MoviesWidget);
