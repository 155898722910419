import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Card,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class PostSuggestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {

    const suggestions = this.props.suggestion;
    return (

      <div className='mb-4' >
        {suggestions.title}
        <img src={suggestions.imgPath} className="mt-1 img-responsive"  alt="Card image cap" />

        <a style={{color:'black'}} href={suggestions.link}> {suggestions.url}</a>

      </div>

    );
  }
}

PostSuggestion.defaultProps = {
  actions: {},
};

PostSuggestion.propTypes = {
  actions: PropTypes.object,
  suggestion:PropTypes.object,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostSuggestion);
