import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class PhotoWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="dashboard-widget photos-container">
        <Card>
          <CardBody>
            <img src="../../assets/apj1.jpg" style={{width: '120px'}} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

PhotoWidget.defaultProps = {
  actions: {},
};

PhotoWidget.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */)  => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoWidget);
