import React from 'react';
import {
  Row, Col,
} from 'reactstrap';
import BasicDetails from "./BasicDetails";
import DetailedInfo from "./DetailedInfo";
import PropTypes from "prop-types";
import PersonResult from "../PersonResult";

class PersonDetails extends React.Component {
  render() {
    const { query, resultData } = this.props;
    return (
      <div>
        <Row>
          <Col xs={12} md={4}>
            <BasicDetails
              query={query}
              resultData={resultData}

            />
          </Col>

          <Col xs={12} md={8} className="mt-3 mt-md-0">
            <DetailedInfo
              query={query}
              resultData={resultData}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

PersonDetails.propTypes = {
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};
export default PersonDetails;
