import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class Bio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <Card>
          <CardBody>
            <p><b> Born:</b> 17 September 1950 (age 69 years), <a href={""}> Vadnagar </a></p>
            <p><b> Full name: </b> Narendra Damodardas Modi </p>
            <p><b> Height:</b> 1.7 m</p>
            <p><b>Spouse:</b> <a href={""}> Jashodaben Narendrabhai Modi </a> (m. 1968)</p>
            <p><b> Education: </b> <a href={""}>Gujarat University </a> (1983), <a href={""}>School of Open
              Learning,
              University of Delhi</a> (1978) </p>
          </CardBody>
        </Card>

      </div>
    );
  }
}

Bio.defaultProps = {
  actions: {},
};

Bio.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */)=> ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bio);
