import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Form, FormGroup, Label,
  Modal, ModalBody, ModalFooter, ModalHeader,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import FileUpload from "./FileUpload";


class UploadFileModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onFileUploaded = this.onFileUploaded.bind(this);
  }

  onFileUploaded(file) {
    this.props.toggleModal();
  }

  render() {
    const {toggleModal} = this.props;

    return (
      <Modal isOpen={true} toggle={toggleModal} className="upload-file-modal">
        <ModalHeader toggle={toggleModal}> File Upload </ModalHeader>
        <ModalBody>
          <div>
            <Form>
              <Row>
                <Col sm="12">
                  <FormGroup className="">
                    <Label for="exampleFile"></Label>
                    <FileUpload
                      onFileUploaded={this.onFileUploaded}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>

    );
  }
}

UploadFileModal.defaultProps = {
  actions: {},
  fileUploadStatus: {},
};

UploadFileModal.propTypes = {
  actions: PropTypes.object,
  toggleModal: PropTypes.func.isRequired,
  fileUploadStatus: PropTypes.object,
};

const mapStateToProps = state => ({
  fileUploadStatus: state.fileUploadStatus,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFileModal);
