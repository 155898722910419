import React from 'react';
import {
Col, Row,
} from 'reactstrap';
import MovieDetails from "./MovieResult/MovieDetails";
import ResultTypeUnderDevelopment from "./Common/ResultTypeUnderDevelopment";

// import * as actionsActions from '../actions/actions';

class MovieResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Row className="movie-result">
        <Col xs={12} sm={12} md={12} lg={9} xl={10}>
          <ResultTypeUnderDevelopment />
          <MovieDetails />
        </Col>
      </Row>
    );
  }
}


export default MovieResult;
