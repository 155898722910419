import axios from 'axios';
import {
  FILE_UPLOAD_ERROR,
  FILE_UPLOAD_STARTING,
  FILE_UPLOAD_SUCCESS,

  GET_FILES_STARTING,
  GET_FILES_SUCCESS,
  GET_FILES_ERROR,

  DELETE_FILE_STARTING,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR,

  UPDATE_FILE_STARTING,
  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_ERROR,

} from '../constants/actionTypes';
import { apiBaseUrl } from '../constants/appConfig';

export function uploadFileStarting() {
  return { type: FILE_UPLOAD_STARTING };
}

export function uploadFileSuccess(data) {
  return { type: FILE_UPLOAD_SUCCESS, data };
}

export function uploadFileFailed(err) {
  return { type: FILE_UPLOAD_ERROR, err };
}

export function uploadFile(file) {
  return (dispatch) => {
    dispatch(uploadFileStarting());

    const data = new FormData();
    data.append('file', file);

    axios.post(`${apiBaseUrl}/files`, data,{
      headers: {'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(uploadFileSuccess(response.data));
        return;
      }

      const err = (response && response.data && response.data.error) ?
        response.data.error : 'Failed to upload file.';
      dispatch(uploadFileFailed(err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        err.response.data.error : err.message;
      dispatch(uploadFileFailed(error));
    });
  };
}

export function getFileStarting() {
  return { type: GET_FILES_STARTING, };
}

export function getFileSuccess(data) {
  return { type:  GET_FILES_SUCCESS, data }
}

export function getFileFailed(err) {
  return { type: GET_FILES_ERROR, err }
}

export function getFile() {
  return dispatch => (
    axios.get(`${apiBaseUrl}/files`).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(getFileSuccess(response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Failed to get file manager data');
      dispatch(getFileFailed(err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(getFileFailed(error));
    }));
}


export function deleteFileStarting(fileId) {
  return { type: DELETE_FILE_STARTING, fileId };
}

export function deleteFileSuccess(fileId, data) {
  return { type: DELETE_FILE_SUCCESS, fileId, data }
}

export function deleteFileFailed(fileId, err) {
  return { type: DELETE_FILE_ERROR, fileId, err }
}

export function deleteFile(fileId) {
  return dispatch => (
    axios.delete(`${apiBaseUrl}/files/${fileId}`).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(deleteFileSuccess(fileId, response.data));
        return;
      }

      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Failed to delete file');
      dispatch(deleteFileFailed(fileId, err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(deleteFileFailed(fileId, error));
    }));
}

export function updateFileStarting(fileId) {
  return { type: UPDATE_FILE_STARTING, fileId };
}

export function updateFileSuccess(fileId, data) {
  return { type: UPDATE_FILE_SUCCESS, fileId, data }
}

export function updateFileFailed(fileId, err) {
  return { type: UPDATE_FILE_ERROR, fileId, err }
}

export function updateFile(fileId, data) {
  return dispatch => (
    axios.patch(`${apiBaseUrl}/files/${fileId}`, data).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(updateFileSuccess(fileId, response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Failed to update file');
      dispatch(updateFileFailed(fileId, err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(updateFileFailed(fileId, error));
    }));
}
