import { cloneDeep } from 'lodash';
import {
  META_SEARCH_ADD_REDIRECT_STARTING,
  META_SEARCH_ADD_REDIRECT_SUCCESS,
  META_SEARCH_ADD_REDIRECT_ERROR,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function metaSearchAddToRedirectReducer(state = initialState.metaSearchAddRedirect, action) {
  switch (action.type) {
    case META_SEARCH_ADD_REDIRECT_STARTING: {
      const { query } = action;
      const newState = cloneDeep(state);

      if (!newState[query]) {
        newState[query] = cloneDeep(initialState.metaSearchAddRedirect.query);
      }

      const addToRedirectData = newState[query];
      addToRedirectData.saving = true;
      addToRedirectData.saved = false;
      addToRedirectData.error = null;
      return newState;
    }

    case META_SEARCH_ADD_REDIRECT_SUCCESS: {
      const { query } = action;
      const newState = cloneDeep(state);

      if (!newState[query]) {
        newState[query] = cloneDeep(initialState.metaSearchAddRedirect.query);
      }

      const addToRedirectData = newState[query];
      addToRedirectData.saving = false;
      addToRedirectData.saved = true;
      return newState;
    }

    case META_SEARCH_ADD_REDIRECT_ERROR: {
      const { query } = action;
      const newState = cloneDeep(state);

      if (!newState[query]) {
        newState[query] = cloneDeep(initialState.metaSearchAddRedirect.query);
      }

      const addToRedirectData = newState[query];
      addToRedirectData.saving = false;
      addToRedirectData.saved = false;
      addToRedirectData.error = action.err ? action.err.message : 'Add to Redirect failed';
      return newState;
    }

    default: {
      return state;
    }
  }
}
