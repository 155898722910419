import React from 'react';
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  PocketShareButton,
  InstapaperShareButton,
  HatenaShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  HatenaIcon,
} from 'react-share';

class SocialSharingButtons extends React.Component {
  render() {
    const shareUrl = window.location.href;
    const title = document.title;

    return (
      <div>
        <div className="react-share">
          <FacebookShareButton
            url={shareUrl}
            quote={title}
            className="react-share__share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </div>

        <div className="react-share">
          <FacebookMessengerShareButton
            url={shareUrl}
            appId="521270401588372"
            className="react-share__share-button"
          >
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
        </div>

        <div className="react-share">
          <TwitterShareButton
            url={shareUrl}
            title={title}
            className="react-share__share-button"
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>

        <div className="react-share">
          <TelegramShareButton
            url={shareUrl}
            title={title}
            className="react-share__share-button"
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
        </div>

        <div className="react-share">
          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            className="react-share__share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>

        <div className="react-share">
          <LinkedinShareButton url={shareUrl} className="react-share__share-button">
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>

        <div className="react-share">
          <RedditShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className="react-share__share-button"
          >
            <RedditIcon size={32} round />
          </RedditShareButton>
        </div>

        <div className="react-share">
          <TumblrShareButton
            url={shareUrl}
            title={title}
            className="react-share__share-button"
          >
            <TumblrIcon size={32} round />
          </TumblrShareButton>
        </div>

        <div className="react-share">
          <LivejournalShareButton
            url={shareUrl}
            title={title}
            description={shareUrl}
            className="react-share__share-button"
          >
            <LivejournalIcon size={32} round />
          </LivejournalShareButton>
        </div>

        <div className="react-share">
          <MailruShareButton
            url={shareUrl}
            title={title}
            className="react-share__share-button"
          >
            <MailruIcon size={32} round />
          </MailruShareButton>
        </div>

        <div className="react-share">
          <EmailShareButton
            url={shareUrl}
            subject={title}
            body="body"
            className="react-share__share-button"
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
        <div className="react-share">
          <ViberShareButton
            url={shareUrl}
            title={title}
            className="react-share__share-button"
          >
            <ViberIcon size={32} round />
          </ViberShareButton>
        </div>

        <div className="react-share">
          <WorkplaceShareButton
            url={shareUrl}
            quote={title}
            className="react-share__share-button"
          >
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>
        </div>

        <div className="react-share">
          <LineShareButton
            url={shareUrl}
            title={title}
            className="react-share__share-button"
          >
            <LineIcon size={32} round />
          </LineShareButton>
        </div>

        <div className="react-share">
          <PocketShareButton
            url={shareUrl}
            title={title}
            className="react-share__share-button"
          >
            <PocketIcon size={32} round />
          </PocketShareButton>
        </div>

        <div className="react-share">
          <InstapaperShareButton
            url={shareUrl}
            title={title}
            className="react-share__share-button"
          >
            <InstapaperIcon size={32} round />
          </InstapaperShareButton>
        </div>

        <div className="react-share">
          <HatenaShareButton
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className="react-share__share-button"
          >
            <HatenaIcon size={32} round />
          </HatenaShareButton>
        </div>
      </div>
    );
  }
}

export default SocialSharingButtons;
