import {
  PLACE_ORDER_STARTING,
  PLACE_ORDER_ERROR,
  PLACE_ORDER_SUCCESS,
  SET_CART_ITEM_QUANTITY_STARTING,
  SET_CART_ITEM_QUANTITY_SUCCESS,
  SET_CART_ITEM_QUANTITY_ERROR,
} from "../constants/actionTypes";

import axios from "./axios";
import {apiBaseUrl} from "../constants/appConfig";

export function setCartItemQuantityStarting(productId) {
  return { type: SET_CART_ITEM_QUANTITY_STARTING, productId };
}

export function setCartItemQuantitySuccess(productId, data) {
  return { type: SET_CART_ITEM_QUANTITY_SUCCESS, productId,  data }
}

export function setCartItemQuantityFailed(productId, err) {
  return { type: SET_CART_ITEM_QUANTITY_ERROR, productId, err }
}

export function setCartItemQuantity(productId, qty) {
  return dispatch => (
    axios.post(`${apiBaseUrl}/cart/set_item_quantity`, {
      productId,
      qty,
    }).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(setCartItemQuantitySuccess(qty, response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Failed to update quantity');
      dispatch(setCartItemQuantityFailed(qty, err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(setCartItemQuantityFailed(qty, error));
    }));
}

export function placeOrderStarting() {
  return { type: PLACE_ORDER_STARTING };
}

export function placeOrderSuccess(data) {
  return { type: PLACE_ORDER_SUCCESS, data }
}

export function placeOrderFailed(err) {
  return { type: PLACE_ORDER_ERROR, err }
}

export function placeOrder(orderData) {
  return dispatch => (
    axios.post(`${apiBaseUrl}/cart/order`, orderData).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(placeOrderSuccess(response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Order Failed');
      dispatch(placeOrderFailed(err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(placeOrderFailed(error));
    }));
}
