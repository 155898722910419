import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class Images extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const images = [
      '/assets/m1.png',
      '/assets/m2.png',
      '/assets/m3.png',
      '/assets/m4.png',


    ];

    const imageCols = images.map((imageUrl, index) => {
      return (
        <Col xs={12} md={6} lg={4} xl={3} key={index} className="image-container">
          <img src={imageUrl} />
        </Col>
      );
    })

    return (
      <div>
        <div className="images-container" >
          <Row>
            {imageCols}
          </Row>
        </div>
      </div>
    );
  }
}

Images.defaultProps = {
  actions: {},
};

Images.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Images);
