import { cloneDeep } from 'lodash';
import {
  USER_LOGIN_STARTING,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function userLoginReducer(state = initialState.login, action) {
  switch (action.type) {
    case USER_LOGIN_STARTING: {
      const newState = cloneDeep(state);
      newState.saving = true;
      newState.saved = false;
      return newState;
    }

    case USER_LOGIN_SUCCESS: {
      const newState = cloneDeep(state);
      newState.saving = false;
      newState.saved = true;
      newState.error = null;

      return newState;
    }

    case USER_LOGIN_ERROR: {
      const newState = cloneDeep(state);
      newState.saving = false;
      newState.saved = false;
      newState.error = action.err ? action.err.message : 'Login Failed';

      return newState;
    }

    default: {
      return state;
    }
  }
}
