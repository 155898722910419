import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardFooter, Col,
  Row,
} from 'reactstrap';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import ResultUpdatedDate from "./ResultUpdatedDate";
import WordsList from './DictionaryPrefixResult/WordsList';


class DictionaryWordResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {query, resultData,} = this.props;
    const {result} = resultData;

    let {word, prefixStr, meanings, synonyms, antonyms} = result;
    if (!word) {
      word = query; // TODO May need to capitalize first letter
    }

    if (!prefixStr) {
      prefixStr = 'Unknown';
    }

    if (!meanings) {
      meanings = 'Not available';
    }

    let {prefixData} = resultData;
    if (!prefixData) {
      prefixData = {};
    }

    if (synonyms.length > 0) {
      synonyms = (<p className="mt-0 mb-2">Synonyms:</p>) &&
        (<div
          className="mt-3 mb-0 synonyms-text"
          dangerouslySetInnerHTML={{__html: synonyms}}
        />)
    }
    if (antonyms.length > 0) {
      antonyms =
        (<div
          className="mt-3 mb-0 antonyms-text"
          dangerouslySetInnerHTML={{__html: antonyms}}
        />)

    }


    let prefixMeanings = prefixData.meanings;

    return (
      <Row className="dictionary-word-result">
        <Col xs={12}>
          <Card>
            {/*   <CardHeader>
              <Row>
                <Col>
                  <LinkToDefaultSearchEngine
                    query={query}
                  />

                  {query}
                </Col>
                <Col className='text-right'>
                  <SearchCount searchCount={resultData.result.searchCount} />
                </Col>
                <Col xs="auto" className="d-none d-md-block">
                  <div className="d-inline-block  ml-3">
                    <AddResultTypeDropdown
                      query={query}
                      resultData={resultData}
                    />
                  </div>
                </Col>
              </Row>
            </CardHeader>
*/}
            <CardBody>
              <Row>
                <Col xs={12} md={6}>
                  <p className="m-0">
                      <h2><b> {query}</b></h2>

                    <div
                      className="mt-3 mb-0 meanings-text"
                      dangerouslySetInnerHTML={{__html: meanings}}
                    />

                  </p>
                </Col>

                <Col xs={12} md={6} className="mt-3 mt-md-0">
                  Prefix:&nbsp;
                  <Link to={`/search?q=${prefixData.query}`}>
                    <b>{prefixStr}</b>
                  </Link>&nbsp;(<b>{prefixData.lang}</b>)

                  <div
                    className="mt-1 mb-0 meanings-text"
                    dangerouslySetInnerHTML={{__html: prefixMeanings}}
                  />

                </Col>
              </Row>
              {synonyms}
              {antonyms}

              <hr/>
              <WordsList
                prefixStr={prefixStr}
              />

            </CardBody>
            <CardFooter>
              <ResultUpdatedDate updatedAt={result.updatedAt}/>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    );
  }
}

DictionaryWordResult.defaultProps = {
  appConfig: {},
};

DictionaryWordResult.propTypes = {
  appConfig: PropTypes.object,
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};

const mapStateToProps = (state => ({
  appConfig: state.appConfig,
}));

export default connect(mapStateToProps)(DictionaryWordResult);
