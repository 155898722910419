export default {
  currentUser: {
    userId: '',
    prevRoute: '',
    loading: false,
    loaded: false,
    refetchInfo: false,
    fetchingInfo: false,
    forceLogin: false,
    loggedIn: false,
    loginInProgress: false,

    error: null,
    userData: {},
  },

  appConfig: {
    loading: false,
    loaded: false,
    error: null,

    data: {},

    canEdit: false,
    isPubliclyEditable: false,
  },

  saveAppConfig: {
    saving: false,
    saved: false,
    error: null,
  },

  searchResults: {
    query: {
      loading: false,
      loaded: false,
      error: null,

      result: null,
      webPages: {},
      wikiPage: {},
      prefixData: {},
    },
  },

  saveSearchResult: {
    query: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  searchSuggestions: {
    query: {
      suggestions: [],
      links: {},
      resultsData: {},
      query: '',
      loading: false,
      loaded: false,
      error: null,
    },
  },

  dictionaryWordsInPrefix: {
    prefixStr: {
      loading: false,
      loaded: false,
      error: null,

      searchQuery: '',
      matchCount: 0,

      ids: [],
      map: {},
    },
  },

  fileUploadStatus: {
    uploading: false,
    uploaded: false,
    error: false,

    file: {},
  },

  files: {
    fileId: {
      loading: false,
      loaded: false,
      error: null,

      file: {},
    },
  },

  users: {
    loading: false,
    loaded: false,
    error: null,

    ids: [],
    map: {},
  },

  createUser: {
    saving: false,
    saved: false,
    error: null,
  },

  updateUser: {
    userId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  deleteUser: {
    userId: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  fileManagerData: {
    loading: false,
    loaded: false,
    error: null,

    ids: [],
    map: {},
  },

  deleteFile:{
    fileId:{
      saving: false,
      saved: false,
      error: null,
    },
  },

  updateFile:{
    fileId:{
      saving: false,
      saved: false,
      error: null,
    },
  },

  metaSearchMoveToTop:{
    query: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  metaSearchAddRedirect:{
    query: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  metaSearchAddVideoEmbed:{
    query: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  metaSearchExtractInfo:{
    query: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  metaSearchRemove:{
    query: {
      saving: false,
      saved: false,
      error: null,
    },
  },

  login:{
    saving: false,
    saved: false,
    error: null,
  },

  signUp: {
    saving: false,
    saved: false,
    error: null,
  },

  saveUserProfile: {
    saving: false,
    saved: false,
    error: null,
  },

  setCartItemQuantity: {
    productId: {
      saving: false,
      saved: false,
      error: null,
    }
  },

  placeOrder: {
    saving: false,
    saved: false,
    error: null,
  },

};
