import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class Market extends React.Component {
  render() {
    return (
      <div>
        <Row>
          <Col xs={"3"}>
            Market Cap (Rs.)
          </Col>
          <Col xs={"2"}>
            90,456.20
          </Col>
        </Row>
        <Row>
          <Col xs={"3"}>
            P/E
          </Col>
          <Col xs={"2"}>
            75.34
          </Col>
        </Row>
        <Row>
          <Col xs={"3"}>
            Book Value (Rs.)
          </Col>
          <Col xs={"2"}>
            25.88
          </Col>
        </Row>
        <Row>
          <Col xs={"3"}>
            Dividend
          </Col>
          <Col xs={"2"}>
            300
          </Col>
        </Row>
      </div>
    );
  }
}

Market.defaultProps = {
  actions: {},
};

Market.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */)=> ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Market);
