import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';


// import * as actionsActions from '../actions/actions';

class BasicDetails extends React.Component {
  render() {

    const {query, resultData} = this.props;
    const {result} = resultData;

    let {placeDescription} = result;

    if (placeDescription.length > 0) {
      placeDescription = (<p className="mt-0 mb-2"> Description :</p>) &&
        (<div
          className="mt-3 mb-0 des"
          dangerouslySetInnerHTML={{__html: placeDescription}}
        />)
    }
    return (
      <div className="basic-details">
        <Card>
          <CardBody>
            <Row className="">
              <Col xs="12" className="text-center">
                <h3 className="mb-1"> {result.placeName} </h3>
              </Col>
            </Row>
            <div>
              <p className="mt-2">

                {placeDescription}

              </p>
              <hr/>
              <p><b> Lat:</b> {result.placeLat} <b> Long:</b> {result.placeLng} </p>
              <p><b> Established :</b> 1591 </p>
              <p><b> Founded by :</b> Muhammad Quli Qutb Shah</p>
              <p><b> City :</b> 625 km2 (241 sq mi)</p>
              <p><b> Metro :</b> 7,257 km2 (2,802 sq mi)</p>
            </div>
          </CardBody>
        </Card>

      </div>
    );
  }
}

BasicDetails.defaultProps = {
  actions: {},
};

BasicDetails.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);
