import React from "react";
import PropTypes from "prop-types";
import moment from 'moment';
import { dateFormats } from '../../constants/appConfig';

class ResultUpdatedDate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const updatedAtStr = moment(this.props.updatedAt).format(dateFormats.out.datetime);

    return (
      <p className="mt-0 mb-0 result-updated-dt">
        Last Updated: <span>{updatedAtStr}</span>.
      </p>
    );
  }
}

ResultUpdatedDate.defaultProps = {};

ResultUpdatedDate.propTypes = {
  updatedAt: PropTypes.string.isRequired,
};

export default ResultUpdatedDate;
