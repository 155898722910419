import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardFooter, CardHeader, Col, Row} from 'reactstrap';
import Calendar from 'react-calendar';
import moment from 'moment';
import ResultUpdatedDate from "./ResultUpdatedDate";
import SearchCount from "../Common/SearchCount";
import JournalNotes from "./CalendarResult/JournalNotes";
import {history} from "../../store/configureStore";
import {dateFormats} from "../../constants/appConfig";
import AddResultTypeDropdown from "./MetaSearchResult/AddResultTypeDropdown";
import CalendarFilePreviews from "./CalendarResult/CalendarFilePreviews";


// import * as actionsActions from '../actions/actions';

class CalendarResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onClickDay = this.onClickDay.bind(this);
  }

  onClickDay(dt) {
    const dtStr = moment(dt).format(dateFormats.out.date)
    history.push(`/search?q=${dtStr}`);
  }

  render() {
    const {resultData, query} = this.props;
     const dt = moment(query, 'DD-MM-YYYY').toDate();

    return (
      <div className="blog-article-result">
        <Card>
          <CardHeader>
            <Row>
              <Col xs="auto">
                {query}
              </Col>
              <Col xs="auto" className="ml-auto">
                <SearchCount searchCount={resultData.result.searchCount}/>
              </Col>
              <Col xs="auto" className="d-none d-md-block">
                <div className="d-inline-block  ml-3">
                  <AddResultTypeDropdown
                    query={query}
                    resultData={resultData}
                  />
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs={12} md={8}>
              <JournalNotes
                query={query}
                resultData={resultData}
              />

              <CalendarFilePreviews
                query={query}
                resultData={resultData}
              />

              </Col>
              <Col xs={12} md={4}>
                <Calendar
                  onClickDay={this.onClickDay}
                  defaultActiveStartDate={dt}
                  value={dt}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <ResultUpdatedDate updatedAt={resultData.result.updatedAt}/>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

CalendarResult.propTypes = {
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};

export default CalendarResult;
