import {
  GET_USERS_STARTING,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,

  CREATE_USER_STARTING,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,

  UPDATE_USER_STARTING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,

  DELETE_USER_STARTING,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,

  USER_SIGNUP_STARTING,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_ERROR,

  USER_LOGIN_STARTING,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,

  SAVE_USER_PROFILE_STARTING,
  SAVE_USER_PROFILE_ERROR,
  SAVE_USER_PROFILE_SUCCESS,

} from '../constants/actionTypes';
import axios from "./axios";
import { apiBaseUrl } from '../constants/appConfig';

export function getUsersStarting() {
  return { type: GET_USERS_STARTING, };
}

export function getUsersSuccess(data) {
  return { type: GET_USERS_SUCCESS, data }
}

export function getUsersFailed(err) {
  return { type: GET_USERS_ERROR, err }
}

export function getUsers() {
  return dispatch => (
    axios.get(`${apiBaseUrl}/auth_tokens`).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(getUsersSuccess(response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Failed to get users');
      dispatch(getUsersFailed(err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(getUsersFailed(error));
    }));
}

export function createUserStarting() {
  return { type: CREATE_USER_STARTING };
}

export function createUserSuccess(data) {
  return { type: CREATE_USER_SUCCESS, data }
}

export function createUserFailed(err) {
  return { type: CREATE_USER_ERROR, err }
}

export function createUser(userData) {
  return dispatch => (
    axios.post(`${apiBaseUrl}/auth_tokens`, userData).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(createUserSuccess(response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Failed to create user');
      dispatch(createUserFailed(err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(createUserFailed(error));
    }));
}

export function updateUserStarting(userId) {
  return { type: UPDATE_USER_STARTING, userId };
}

export function updateUserSuccess(userId, data) {
  return { type: UPDATE_USER_SUCCESS, userId, data }
}

export function updateUserFailed(userId, err) {
  return { type: UPDATE_USER_ERROR, userId, err }
}

export function updateUser(userId, userData) {
  return dispatch => (
    axios.put(`${apiBaseUrl}/auth_tokens/${userId}`, userData).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(updateUserSuccess(userId, response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Failed to update user');
      dispatch(updateUserFailed(userId, err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(updateUserFailed(userId, error));
    }));
}

export function deleteUserStarting(userId) {
  return { type: DELETE_USER_STARTING, userId };
}

export function deleteUserSuccess(userId, data) {
  return { type: DELETE_USER_SUCCESS, userId, data }
}

export function deleteUserFailed(userId, err) {
  return { type: DELETE_USER_ERROR, userId, err }
}

export function deleteUser(userId) {
  return dispatch => (
    axios.delete(`${apiBaseUrl}/auth_tokens/${userId}`).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(deleteUserSuccess(userId, response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Failed to delete user');
      dispatch(deleteUserFailed(userId, err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(deleteUserFailed(userId, error));
    }));
}

export function userSignUpStarting() {
  return { type: USER_SIGNUP_STARTING };
}

export function userSignUpSuccess(data) {
  return { type: USER_SIGNUP_SUCCESS, data }
}

export function userSignUpFailed(err) {
  return { type: USER_SIGNUP_ERROR, err }
}

export function userSignUp(userData) {
  return dispatch => (
    axios.post(`${apiBaseUrl}/users/signup`, userData).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(userSignUpSuccess(response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Signup Failed');
      dispatch(userSignUpFailed(err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(userSignUpFailed(error));
    }));
}

export function userLoginStarting(data) {
  return { type: USER_LOGIN_STARTING, data };
}

export function userLoginSuccess(data) {
  return { type: USER_LOGIN_SUCCESS, data }
}

export function userLoginFailed(err) {
  return { type: USER_LOGIN_ERROR, err }
}

export function userLogin(userData) {
  return dispatch => (
    axios.post(`${apiBaseUrl}/users/login`, userData).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(userLoginSuccess(response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Login Failed');
      dispatch(userLoginFailed(err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(userLoginFailed(error));
    }));
}

export function saveUserProfileStarting() {
  return { type: SAVE_USER_PROFILE_STARTING };
}

export function saveUserProfileSuccess(data) {
  return { type: SAVE_USER_PROFILE_SUCCESS, data }
}

export function saveUserProfileFailed(err) {
  return { type: SAVE_USER_PROFILE_ERROR, err }
}

export function saveUserProfile(userData) {
  return dispatch => (
    axios.post(`${apiBaseUrl}/auth_tokens`, userData).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(saveUserProfileSuccess(response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Save User Failed');
      dispatch(saveUserProfileFailed(err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(saveUserProfileFailed(error));
    }));
}
