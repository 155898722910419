import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Card, CardBody, Col, CardHeader,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ActivityItem from "./ActivityItem";

// import * as actionsActions from '../actions/actions';

class Activities extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const activity = [
      {
        imgPath:"/../assets/b1.jpg",
        description: "Add a short description to Hey Bro, so people know what its about",
        time : "24 hours ago"

      },

      {
        imgPath:"/../assets/m1.png",
        description: "Your video is ready to view. You can now watch it.",
        time : " Yesterday at 12:10 pm"
      },

      {
        imgPath:"/../assets/pic1.webp",
        description: "hey Bro, has 1 new ",
        time : " Dec 1 at 12:10 pm"
      },

      {
        imgPath:"/../assets/pic1.webp",
        description: "Grace Bandril and Aldrin  Vadez followed you."
      },

      {
        imgPath:"/../assets/pic1.webp",
        description: "Your video is ready to view. You can now watch it."
      },

      {
        imgPath:"/../assets/pic1.webp",
        description: "Your video is ready to view. You can now watch it."
      },

      {
        imgPath:"/../assets/pic1.webp",
        description: "Your video is ready to view. You can now watch it."
      },


    ];

    return (
      <div className="activities">
        <Row>
          <Col xs={12} md={6}>
            <Card>
              <CardHeader>
                Activities
              </CardHeader>
          <CardBody>

            <ActivityItem
              activity={activity[0]}
            />
            <ActivityItem
              activity={activity[1]}
            />
            <ActivityItem
              activity={activity[2]}
            />
            <ActivityItem
              activity={activity[3]}
            />
            <ActivityItem
              activity={activity[4]}
            />
          </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Activities.defaultProps = {
  actions: {},
};

Activities.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
