import React from 'react';
import {
  Alert,
} from 'reactstrap';

// import * as actionsActions from '../actions/actions';

class ResultTypeUnderDevelopment extends React.Component {

  render() {
    return (
      <div>
        <Alert className="" color="warning">
          <div>
            <i className="fa fa-exclamation-triangle" />
            &nbsp;
            This result type is under development. There are considerably high chances of incorrect information below.
          </div>
        </Alert>
      </div>
    );
  }
}

export default ResultTypeUnderDevelopment;
