import React from 'react';
import PropTypes from 'prop-types';
import {
   Table,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FilesTableRow from './FilesTableRow';
import FilePreviewModal from './FilePreviewModal';

// import * as actionsActions from '../actions/actions';

class FilesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPreviewModal: false,
      selectedFile: null,
    };

    this.togglePreviewModal = this.togglePreviewModal.bind(this);
  }

  togglePreviewModal(selectedFile) {
    const { showPreviewModal } = this.state;
    this.setState({
      selectedFile,
      showPreviewModal: !showPreviewModal,
    });
  }

  getFileRows() {
    const { fileIds, files } = this.props;

    const rows = [];

    fileIds.forEach((fileId, index) => {
      if (!files[fileId]) {
        return true;
      }

      const file = files[fileId].file;
      if (!file) {
        return true;
      }

      rows.push((
        <FilesTableRow
          key={fileId}
          file={file}
          fileId={fileId}
          index={index}
          onFilePreviewClicked={this.togglePreviewModal}
        />
      ));
    });

    return rows;
  }

  render() {
    const tableRows = this.getFileRows();

    let previewModal = null;
    const { selectedFile, showPreviewModal } = this.state;
    if (showPreviewModal) {
      previewModal = (
        <FilePreviewModal
          file={selectedFile}
          toggleModal={this.togglePreviewModal}
        />
      );
    }

    return (
      <div>
        <Table className="table table-bordered table-striped category-table mb-0">
          <thead className="table-info">
          <tr>
            <th
              style={{
                width: '6%'
              }}
              className="text-center"
            >
              #
            </th>

            <th style={{ width: '60%' }}>
              File Details
            </th>

            <th className="text-center d-none d-md-table-cell">&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {tableRows}
          </tbody>
        </Table>

        {previewModal}
      </div>
    );

  }
}

FilesTable.defaultProps = {
  actions: {},
  files: {},
};

FilesTable.propTypes = {
  actions: PropTypes.object,
  files: PropTypes.object,
};

const mapStateToProps = state => ({
  files: state.files,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilesTable);
