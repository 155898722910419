import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class FilePreviewPhoto extends React.Component {

  render() {
    const {fileUrl, file} = this.props;

     return (
      <div className="file-preview-photo">
        <Row>
          <Col xs={12} md={6} lg={4}>
            <img src={fileUrl} title={`${file.originalFileName} Preview`} className="img-responsive"/>
          </Col>
        </Row>
      </div>
    );
  }
}

FilePreviewPhoto.defaultProps = {
  actions: {},
};

FilePreviewPhoto.propTypes = {
  actions: PropTypes.object,
  fileUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (/* state */)=> ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilePreviewPhoto);
