import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ManageFileRow from "./ManageFileRow";

// import * as actionsActions from '../actions/actions';

class ManageFilesTable extends React.Component {
  getFileRows() {
    const { fileIds, files, onFileAction } = this.props;

    const rows = [];

    fileIds.forEach((fileId, index) => {
      if (!files[fileId]) {
        return true;
      }

      const file = files[fileId].file;
      if (!file) {
        return true;
      }

      rows.push((
        <ManageFileRow
          key={fileId}
          file={file}
          index={index}
          onFileAction={onFileAction}
        />
      ));
    });

    return rows;
  }


  render() {
    const { fileIds } = this.props;

    if (!fileIds) {
      return null;
    }

    let content;
    if (!fileIds.length) {
      content = (
        <div>
          No files configured yet.
        </div>
      );
    } else {
      const tableRows = this.getFileRows();

      content = (
        <Table className="table table-bordered mt-1 category-table">
          <thead>
          <tr>
            <th
              style={{
                width: '6%'
              }}
              className="text-center"
            >
              #
            </th>

            <th style={{ width: '50%' }}>Name</th>
            <th style={{ width: '25%' }} className="text-center">Last Updated</th>
            <th style={{ width: '25%' }} className="text-center">Size</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {tableRows}
          </tbody>
        </Table>
      );
    }

    return (
      <div>
        <p>Uploaded Files</p>
        {content}
      </div>
    );
  }
}

ManageFilesTable.defaultProps = {
  actions: {},
  files: {},
};

ManageFilesTable.propTypes = {
  actions: PropTypes.object,
  files: PropTypes.object,
  onFileAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  files: state.files,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageFilesTable);
