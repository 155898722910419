import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Noty from "noty";
import * as fileActions from "../../actions/filesActions";


class DeleteFileModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onConfirmClicked = this.onConfirmClicked.bind(this);

    this.deleteStarted = false;
  }

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    const fileId = this.props.selectedFile._id;

    if (
      this.deleteStarted &&
      this.props.deleteFile !== nextProps.deleteFile &&
      nextProps.deleteFile[fileId]
    ) {
      this.handleStatusChange(nextProps);
    }
  }

  handleStatusChange(props) {
    const fileId = props.selectedFile._id;

    if (
      props.deleteFile[fileId] && !props.deleteFile[fileId].saving && props.deleteFile[fileId].saved
    ) {
      new Noty({
        type: 'success',
        layout: 'bottomLeft',
        text: 'File deleted successfully.',
      }).show();

      this.props.toggleModal();

      this.deleteStarted = false;
    } else if (
      props.deleteFile[fileId] && !props.deleteFile[fileId].saving && !props.deleteFile[fileId].saved
    ) {
      let errorMessage = props.deleteFile[fileId].error;
      if (!errorMessage) {
        errorMessage = 'Failed to delete file due to server error.';
      }

      new Noty({
        type: 'error',
        layout: 'bottomLeft',
        text: errorMessage,
      }).show();

      this.deleteStarted = false;
    }
  }

  onConfirmClicked() {
    const { selectedFile } = this.props;
    this.props.actions.deleteFileStarting();
    this.props.actions.deleteFile(selectedFile._id);
    this.deleteStarted = true;
  }

  render() {

    const { deleteFile, selectedFile, toggleModal } = this.props;

    let btnDelete;

    const deleteData = deleteFile[selectedFile._id];
    if (deleteData && deleteData.saving) {
      btnDelete = (
        <Button color="danger" disabled>
          <i className="fa fa-spinner fa-spin" />
          &nbsp;
          Please wait...
        </Button>
      );
    } else {
      btnDelete = (
        <Button color="danger" onClick={this.onConfirmClicked} >
          Confirm
        </Button>
      );
    }

    return (
      <Modal isOpen={true} toggle={toggleModal} className="delete-file-modal">
        <ModalHeader toggle={toggleModal}>Delete File </ModalHeader>
        <ModalBody>
          <div>
            <p>
              Are you sure you want to delete the File &nbsp; <b>{selectedFile.originalFileName}</b>?

            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          {btnDelete}
          <Button color="secondary" onClick={toggleModal} >Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

DeleteFileModal.defaultProps = {
  actions: {},
  deleteFile: {},

};

DeleteFileModal.propTypes = {
  actions: PropTypes.object,
  toggleModal: PropTypes.func.isRequired,
  selectedFile:PropTypes.object.isRequired,
  deleteFile: PropTypes.object,
};

const mapStateToProps = state => ({
  deleteFile: state.deleteFile,

});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({},fileActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteFileModal);
