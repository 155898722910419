import { cloneDeep } from 'lodash';
import {
  DELETE_USER_STARTING,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function deleteUserReducer(state = initialState.deleteUser, action) {
  switch (action.type) {
    case DELETE_USER_STARTING: {
      const { userId } = action;
      const newState = cloneDeep(state);

      if (!newState[userId]) {
        newState[userId] = cloneDeep(initialState.deleteUser.userId);
      }

      const deleteData = newState[userId];
      deleteData.saving = true;
      deleteData.saved = false;
      deleteData.error = null;
      return newState;
    }

    case DELETE_USER_SUCCESS: {
      const { userId } = action;
      const newState = cloneDeep(state);

      if (!newState[userId]) {
        newState[userId] = cloneDeep(initialState.deleteUser.userId);
      }

      const deleteData = newState[userId];
      deleteData.saving = false;
      deleteData.saved = true;
      return newState;
    }

    case DELETE_USER_ERROR: {
      const { userId } = action;
      const newState = cloneDeep(state);

      if (!newState[userId]) {
        newState[userId] = cloneDeep(initialState.deleteUser.userId);
      }

      const deleteData = newState[userId];
      deleteData.saving = false;
      deleteData.saved = false;
      deleteData.error = action.err ? action.err.message : 'Failed to delete user data';
      return newState;
    }

    default: {
      return state;
    }
  }
}
