import React from 'react';
import PropTypes from 'prop-types';
import SocialSharingButtons from './BlogArticleResult/SocialSharingButtons';
import {Card, CardBody, CardFooter, CardHeader, Col, Row} from 'reactstrap';
import {apiBaseUrl} from "../../constants/appConfig";
import ResultUpdatedDate from "./ResultUpdatedDate";
import SearchCount from "../Common/SearchCount";
import AddResultTypeDropdown from "./MetaSearchResult/AddResultTypeDropdown";
import PostSuggestions from "./BlogArticleResult/PostSuggestions";

// import * as actionsActions from '../actions/actions';

class BlogArticleResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {resultData, query} = this.props;
    const {result} = resultData;

    let featuredImage = null;
    if (result.blogFeaturedImage) {
      const featuredImageUrl = `${apiBaseUrl}/files/f/${result.blogFeaturedImage}`;
      featuredImage = (
        <img
          src={featuredImageUrl}
          className="featured-image"
          alt={result.blogImageCaption ? result.blogImageCaption : result.blogTitle}
        />
      );
    }

    let featuredImageCaption = null;
    if (result.blogImageCaption) {
      featuredImageCaption = (
        <p className="mb-0 caption">
          {result.blogImageCaption}
        </p>
      );
    }

    let blogTitle = null;
    if (result.blogTitle) {
      blogTitle = (
        <h1 className="title mt-0 mb-0">
          {result.blogTitle}
        </h1>
      );
    }

    let metaInfo = null;
    if ((result.blogAuthor) && (result.blogTimestamp)) {
      metaInfo = (
        <div className="mt-1 mb-0 meta-info">
          Author: {result.blogAuthor}&nbsp;&nbsp;|&nbsp;&nbsp;
          {result.blogTimestamp}
        </div>
      );
    } else if (result.blogAuthor) {
      metaInfo = (
        <div className="mt-1 mb-0 meta-info">
          Author: {result.blogAuthor}
        </div>
      );
    } else if (result.blogTimestamp) {
      metaInfo = (
        <div className="mt-1 mb-0 meta-info">
          {result.blogTimestamp}
        </div>
      );
    }

    let socialSharing = null;
    if (result.socialSharingEnabled) {
      socialSharing = (
        <div>
          <div className="mt-3 mb-0 divider"/>

          <div className="mt-3 mb-0 social-sharing">
            <SocialSharingButtons/>
          </div>
        </div>
      );
    }

    return (
      <div className="blog-article-result">
        <Card>
          <CardHeader>
            <Row>
              <Col>
                {query}
              </Col>
              <Col className='text-right'>
                <SearchCount searchCount={resultData.result.searchCount}/>
              </Col>
              <Col xs="auto" className="d-none d-md-block">
                <div className="d-inline-block  ml-3">
                  <AddResultTypeDropdown
                    query={query}
                    resultData={resultData}
                  />
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs={12} md={8}>
                <div className="top-section">
                  {blogTitle}

                  <div
                    className="mt-3 excerpt"
                    dangerouslySetInnerHTML={{__html: result.blogExcerpt}}
                  />
                  {metaInfo}

                </div>

                <div className="mt-3 mb-0 divider"/>

                <Row className="mt-3 mb-0">
                  <Col xs={12} md={12} lg={12} className="">
                    {featuredImage}

                    {featuredImageCaption}

                    {socialSharing}

                    <div className="mt-3 mb-0 divider"/>

                    <div className="mt-3">
                      <div
                        className="mt-1 article-body"
                        dangerouslySetInnerHTML={{__html: result.blogContent}}
                      />
                    </div>
                  </Col>

                </Row>

                {socialSharing}

              </Col>

              <Col xs={12} md={4}>
                <PostSuggestions/>
              </Col>

            </Row>
          </CardBody>
          <CardFooter>
            <ResultUpdatedDate updatedAt={resultData.result.updatedAt}/>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

BlogArticleResult.propTypes = {
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};

export default BlogArticleResult;
