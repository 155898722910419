import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardFooter, CardHeader, Col, Row,
} from 'reactstrap';
import classNames from 'classnames';
import FilesTable from "./FilesResult/FilesTable";
import SearchCount from '../Common/SearchCount';
import ResultUpdatedDate from "./ResultUpdatedDate";
import AddResultTypeDropdown from "./MetaSearchResult/AddResultTypeDropdown";
import FilePreview from './FilesResult/FilePreview';

// import * as actionsActions from '../actions/actions';

class FilesResult extends React.Component {
  render() {
    const {resultData, query} = this.props;
    const {result} = resultData;
    const {fileIds} = result;

    let content;
    let isFilePreview = false;

    if (!fileIds) {
      content = (
        <div className="p-3">
          No files uploaded on this page yet.
        </div>
      );
    } else if (fileIds.length === 1) {
      isFilePreview = true;
      content = (
        <div>
          <FilePreview
            fileId={fileIds[0]}
          />
        </div>
      );
    } else {
      content = (
        <FilesTable
          fileIds={fileIds}
        />
      );
    }

    return (
      <Row className="files-result">
        <Col xs={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col >
                  {query}
                  {isFilePreview && (
                    <span className="ml-4 text-muted">
                      <small>File Preview</small>
                    </span>
                  )}
                </Col>
                <Col xs="auto" className='text-right'>
                  <SearchCount searchCount={resultData.result.searchCount}/>
                </Col>
                <Col xs="auto" className="d-none d-md-block">
                  <div className="d-inline-block  ml-3">
                    <AddResultTypeDropdown
                      query={query}
                      resultData={resultData}
                    />
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className={classNames({ "p-0": (fileIds && fileIds.length > 1)})}>
              {content}
            </CardBody>
            <CardFooter>
              <ResultUpdatedDate updatedAt={result.updatedAt}/>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    );
  }
}

FilesResult.propTypes = {
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};

export default FilesResult;
