import {
  GET_DICT_WORDS_IN_PREFIX_STARTING,
  GET_DICT_WORDS_IN_PREFIX_SUCCESS,
  GET_DICT_WORDS_IN_PREFIX_ERROR,
} from '../constants/actionTypes';
import axios from "./axios";
import { apiBaseUrl } from '../constants/appConfig';

export function getDictWordsInPrefixStarting(prefixStr, searchQuery) {
  return { type: GET_DICT_WORDS_IN_PREFIX_STARTING, prefixStr, searchQuery };
}

export function getDictWordsInPrefixSuccess(prefixStr, searchQuery, data) {
  return { type: GET_DICT_WORDS_IN_PREFIX_SUCCESS, prefixStr, searchQuery, data }
}

export function getDictWordsInPrefixFailed(prefixStr, searchQuery, err) {
  return { type: GET_DICT_WORDS_IN_PREFIX_ERROR, prefixStr, searchQuery, err }
}

export function getDictWordsInPrefix(prefixStr, searchQuery) {
  return dispatch => (
    axios.get(`${apiBaseUrl}/data/dictionary_words_in_prefix`, {
      params: {
        prefixStr: prefixStr,
        searchQuery: searchQuery,
      },
    }).then((response) => {
      if (response && response.status === 200 && response.data.success) {
        dispatch(getDictWordsInPrefixSuccess(prefixStr, searchQuery, response.data));
        return;
      }
      const err = (response && response.data && response.data.error) ?
        new Error(response.data.error) : new Error('Failed to get search result');
      dispatch(getDictWordsInPrefixFailed(prefixStr, searchQuery, err));
    }).catch((err) => {
      const error = (err.response && err.response.data && err.response.data.error) ?
        new Error(err.response.data.error) : err;
      dispatch(getDictWordsInPrefixFailed(prefixStr, searchQuery, error));
    }));
}
