import React from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown, DropdownToggle, DropdownItem,
} from 'reactstrap';
import { humanFileSize } from '../../utils/numberFormat';
import moment from 'moment';
import DropdownMenu from "reactstrap/es/DropdownMenu";

// import * as actionsActions from '../actions/actions';

class ManageFileRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDropdownOpen: false,
    };

    this.onMoveUp = this.onMoveUp.bind(this);
    this.onMoveDown = this.onMoveDown.bind(this);
    this.onMoveToTop = this.onMoveToTop.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggleDropdown() {
    const { isDropdownOpen } = this.state;
    this.setState({
      isDropdownOpen: !isDropdownOpen,
    });
  }

  onMoveUp() {
    const fileId = this.props.file._id;
    this.props.onFileAction('moveUp', fileId, this.props.index);
  }

  onMoveDown() {
    const fileId = this.props.file._id;
    this.props.onFileAction('moveDown', fileId, this.props.index);
  }

  onMoveToTop() {
    const fileId = this.props.file._id;
    this.props.onFileAction('moveToTop', fileId, this.props.index);
  }

  onDelete() {
    const fileId = this.props.file._id;
    this.props.onFileAction('delete', fileId, this.props.index);
  }

  render() {
    const { isDropdownOpen } = this.state;
    const { file, index } = this.props;
    const updatedDt = moment(file.updatedDt);
    const updatedDtReadable = updatedDt.fromNow();

    return (
      <tr>
        <td className="text-center">
          {index + 1}
        </td>

        <td>
          {file.originalFileName}
        </td>

        <td className="text-center">
          {updatedDtReadable}
        </td>

        <td className="text-center">
          {humanFileSize(file.fileSize, false, 0)}
        </td>

        <td>
          <Dropdown tag="div" isOpen={isDropdownOpen} toggle={this.toggleDropdown} direction="left">
            <DropdownToggle
              tag="a"
              data-toggle="dropdown"
              aria-expanded={isDropdownOpen}
              className="pl-2 pr-2 pt-1 pb-1"
              style={{ color: '#CCC' }}
            >
              <i className="fa fa-ellipsis-v" />
            </DropdownToggle>
            <DropdownMenu className="p-0">
              {(index !== 0) && (
                <DropdownItem onClick={this.onMoveUp}>
                  Move Up
                </DropdownItem>
              )}

              <DropdownItem onClick={this.onMoveDown}>
                Move Down
              </DropdownItem>

              {(index !== 0) && (
                <DropdownItem onClick={this.onMoveToTop}>
                  Move to Top
                </DropdownItem>
              )}

              <DropdownItem onClick={this.onDelete}>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </td>

      </tr>
    );
  }
}

ManageFileRow.defaultProps = {
  file: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default ManageFileRow;
