import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'reactstrap';
import { getFileUrl } from '../../../utils/general';

class FileActionButtons extends React.Component {
  render() {
    const { file, onFilePreviewClicked } = this.props;

    const downloadLink = getFileUrl(file);

    return (
      <div>
        <Button className="btn btn-light btn-sm border-success" onClick={() => {
          console.log('Click happened???')
          onFilePreviewClicked(file);
        }}>
          <i className="fa fa-eye" />
          &nbsp;
          Preview
        </Button>

        <a color="primary" className="btn btn-light btn-sm border-primary ml-3" href={downloadLink}
           target="_blank">
          <i className="fa fa-download" />
          &nbsp;
          Download
        </a>
      </div>
    );
  }
}

FileActionButtons.propTypes = {
  file: PropTypes.object.isRequired,
  onFilePreviewClicked: PropTypes.func.isRequired,
};

export default FileActionButtons;
