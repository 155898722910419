import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Table, Col, Button, Card
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import EditBioForm from "./EditBioForm";
import BioForm from "./BioForm";
import Noty from "noty";

// import * as actionsActions from '../actions/actions';

class ManageBioTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showBioForm: false,
      showEditBioForm : false,
    };

    this.onBioFormShow = this.onBioFormShow.bind(this);
    this.onEditBioFormShow = this.onEditBioFormShow.bind(this);
  }

  onBioFormShow() {
    const {showBioForm} = this.state;
    this.props.
    this.setState({
      showEditBioForm: false,
      showBioForm: !showBioForm,
    });
  }

  onEditBioFormShow(index, selectedBio) {
    // const {showEditBioForm} = this.state;
    this.setState({
      editingBio: selectedBio,
      editingBioIndex: index,
      showBioForm : false,
      showEditBioForm: true,
    });
  }

  getBioTableRows() {
    const rows = [];
    this.props.personBio.forEach((bioRow, index) => {
      rows.push((
        <tr key={bioRow.field}>
          <th scope="row">{index + 1}</th>
          <td>{bioRow.field}</td>
          <td>{bioRow.value}</td>
          <td className="text-center">
            <Button color="info" onClick={() => {
              this.onEditBioFormShow(index, bioRow)
            }}>
               <i className="fa fa-edit" />
            </Button> </td>

        </tr>
      ));
    });

    return rows;
  };

  render() {
    let bioForm = null;
    let editBioForm = null;
    const {showBioForm, showEditBioForm,} = this.state;

    if (showBioForm) {

      bioForm = (
        <BioForm
          addBioRow={this.props.addBioRow}
        />
      );
    }
    if (showEditBioForm) {

      editBioForm = (
        <EditBioForm
          data = {this.props.personBio}
          editBioRow={this.props.editBioRow}
        />
      );
    }


    const bioRows = this.getBioTableRows();
    return (
      <div>

        <Row>
          <Col xs={12} md={6}>
            <Row>
              <Col>
                <h6> Manage Bio Table</h6>
              </Col>
              <Col>
                <Button color="primary  float-right" onClick={this.onBioFormShow}>
                  Add New Row
                </Button>
              </Col>
            </Row>

            <Table className="table table-bordered mt-1 Bio-table">
              <thead>
              <tr>
                <th style={{width: '6%'}} className="text-center"> #</th>
                <th style={{width: '30%'}}>Field</th>
                <th style={{width: '60%'}} className="text-center">Value</th>
                <th>&nbsp;</th>
              </tr>
              </thead>
              <tbody>
                {bioRows}
              </tbody>
            </Table>
          </Col>
          <Col xs={12} md={4}>

            {bioForm}
            {editBioForm}

            {/* {content}*/}
          </Col>

        </Row>

      </div>
    );
  }
}

ManageBioTable.defaultProps = {
  actions: {},
};

ManageBioTable.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageBioTable);
