import React from 'react';
import {
 Col, Row,
} from 'reactstrap';
import BookDetails from "./BookResult/BookDetails"
import ResultTypeUnderDevelopment from "./Common/ResultTypeUnderDevelopment";

// import * as actionsActions from '../actions/actions';

class BookResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
     return (
      <Row className="book-result">
        <Col xs={12} sm={12} md={12} lg={9} xl={10}>
          <ResultTypeUnderDevelopment />
          <BookDetails />
        </Col>
      </Row>
    );
  }
}




export default BookResult;

