import React from 'react';
import {
  Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from "classnames";
import Market from "./Market";
import Profile from "./Profile";
import News from "./News";
import Products from "./Products";
import ImagesPreviews from "../Common/ImagesPreviews";
import PropTypes from "prop-types";

// import * as actionsActions from '../actions/actions';

class DetailedInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
    };

    this.setActiveTab = this.setActiveTab.bind(this);
  }

  setActiveTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { query, resultData } = this.props;
    const {
      activeTab
    } = this.state;

    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1', 'pointer link': true })}
              onClick={() => {
                this.setActiveTab('1');
              }}
              tag="div"
            >
              Profile
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2', 'pointer link': true })}
              onClick={() => {
                this.setActiveTab('2');
              }}
              tag="div"
            >
              Market
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3', 'pointer link': true })}
              onClick={() => {
                this.setActiveTab('3');
              }}
              tag="div"
            >
              News
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4', 'pointer link': true })}
              onClick={() => {
                this.setActiveTab('4');
              }}
              tag="div"
            >
              Products
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '5', 'pointer link': true })}
              onClick={() => {
                this.setActiveTab('5');
              }}
              tag="div"
            >
             Images
            </NavLink>
          </NavItem>


        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Card>
              <CardBody>

                <Profile />

              </CardBody>
            </Card>

          </TabPane>
          <TabPane tabId="2">
            <Card>
              <CardBody>

                <Market />

              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId="3">
            <Card>
              <CardBody className="p-0">

                <News />

              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId="4">
            <Card>
              <CardBody className="p-0">

                <Products />

              </CardBody>
            </Card>
          </TabPane>

          <TabPane tabId="5">
            <Card>
              <CardBody>

                <ImagesPreviews
                  query={query}
                  resultData={resultData}
                />

              </CardBody>
            </Card>
          </TabPane>


        </TabContent>
      </div>
    );
  }
}

DetailedInfo.propTypes = {
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};

export default DetailedInfo;
