import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SocialProfiles from "./SocialProfiles";
import ContactDetails from "./ContactDetails";

// import * as actionsActions from '../actions/actions';

class BasicDetails extends React.Component {
  render() {
    const {query, resultData} = this.props;
    const {result} = resultData;

    return (
      <div className="basic-details">
        <Card>
          <CardBody>
            <Row className="">
              <Col xs="auto" className="">
                <img className="mt-1 display-pic" src="/assets/modi.png" alt="Card image cap"/>
              </Col>
              <Col className="d-flex flex-column justify-content-center">
                <h6> Naredra Modi </h6>
                <p>Prime Minister of India</p>
              </Col>
            </Row>

            <div>
              <p className="mt-3">
                Narendra Damodardas Modi is an Indian politician serving as the 14th and current Prime Minister of
                India since 2014. He was the Chief Minister of Gujarat from 2001 to 2014 and is the Member of Parliament
                for Varanasi.
              </p>

              <SocialProfiles />
              <ContactDetails />

            </div>
            </CardBody>
          </Card>

      </div>
    );
  }
}

BasicDetails.defaultProps = {
  actions: {},
};

BasicDetails.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetails);
