import { cloneDeep } from 'lodash';
import {
  GET_USERS_STARTING,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,

  CREATE_USER_SUCCESS,
  UPDATE_USER_SUCCESS, DELETE_USER_SUCCESS,
} from '../constants/actionTypes';
import initialState from './initialState';

export default function usersReducer(state = initialState.users, action) {
  switch (action.type) {
    case GET_USERS_STARTING: {
      const newState = cloneDeep(state);
      newState.loading = true;
      newState.loaded = false;
      newState.error = null;
      return newState;
    }

    case GET_USERS_SUCCESS: {
      const newState = cloneDeep(state);
      const { data } = action;

      const ids = [];
      const map = {};

      data.tokens.forEach((token) => {
        ids.push(token._id);
        map[token._id] = token;
      });

      newState.loading = false;
      newState.loaded = true;
      newState.ids = ids;
      newState.map = map;
      return newState;
    }

    case GET_USERS_ERROR: {
      const newState = cloneDeep(state);
      newState.loading = false;
      newState.loaded = false;
      newState.error = action.err ? action.err.message : 'Failed to get users';
      return newState;
    }

    case CREATE_USER_SUCCESS: {
      const newState = cloneDeep(state);
      const { user } = action.data;
      newState.ids.push(user._id);
      newState.map[user._id] = user;
      return newState;
    }

    case UPDATE_USER_SUCCESS: {
      const newState = cloneDeep(state);
      const { user } = action.data;
      newState.map[user._id] = user;
      return newState;
    }

    case DELETE_USER_SUCCESS: {
      const newState = cloneDeep(state);
      const { userId } = action;
      newState.ids = newState.ids.filter(id => id !== userId);
      delete newState.map[userId];
      return newState;
    }

    default: {
      return state;
    }
  }
}
