import React from 'react';
import PropTypes from 'prop-types';
import {
   Card, CardBody
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Calendar from 'react-calendar';

// import * as actionsActions from '../actions/actions';

class CalendarWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="dashboard-widget calendar-container">
        <Card>
          <CardBody>
            <Calendar />

          </CardBody>
        </Card>
      </div>
    );
  }
}

CalendarWidget.defaultProps = {
  actions: {},
};

CalendarWidget.propTypes = {
  actions: PropTypes.object,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarWidget);
