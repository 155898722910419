export let env = process.env.REACT_APP_HOST_ENV || process.env.NODE_ENV;
//export let apiBaseUrl = '';
export let apiBaseUrl = 'https://ananth.cc';
import * as pkg from '../../package.json';

export const randomColors = [
  "#287E45",
  "#D22B39",
  "#396AC3",
  "#B7845A",
  "#E983B4",
  "#26D04D",
  "#E0CF8B",
  "#C11288",
  "#0AD4CC",
  "#B6E92E",
  "#AE3896",
  "#FB154A"
];

if (!env) {
  env = 'development';
}

// TODO Get App Name from server config

export const appName = 'Ananth\'s Space';
export let siteName = appName;
export let windowTitle = `${siteName} Search`;

export function setSiteName(newName) {
  siteName = newName;
  windowTitle = `${siteName} Search`;
}

export const appVersion = pkg.version;

export const defaultTagline = 'You may not be able to remember all the important information you read on the web. Loki does it for you.';
export const defaultBackgroundColor = '#e7edff';
export const defaultSearchEngineUrl = 'https://duckduckgo.com/?q=%s';

export const githubProjectLink = 'https://github.com/a1ananth/loki';

export const dateFormats = {
  in: {
    date: 'YYYY-MM-DD',
    time: 'HH:mm:ss',
    datetime: 'YYYY-MM-DD HH:mm:ss',
  },

  out: {
    date: 'DD-MM-YYYY',
    time: 'HH:mm:ss',
    datetime: 'DD-MM-YYYY HH:mm:ss',

    tenure: 'MMM YY',
  },
};
