import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ReactPlayer from "react-player/lazy";

// import * as actionsActions from '../actions/actions';

class FilePreviewVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: true,
    };

    this.onEnded = this.onEnded.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onPlay = this.onPlay.bind(this);
  }

  onEnded() {
    this.setState({
      playing: false,
    });
  }

  onPause() {
    this.setState({
      playing: false,
    });
  }

  onPlay() {
    this.setState({
      playing: true,
    });
  }

  render() {
    const { fileUrl } = this.props;
    const { playing } = this.state;

    return (
      <div className="file-preview-video">
        <Row>
          <Col xs={12} lg={9} className="video-player">
            <ReactPlayer
              controls={true}
              playing={playing}
              loop={true}
              pip={false}
              stopOnUnmount={true}
              url={fileUrl}
              onPause={this.onPause}
              onPlay={this.onPlay}
              onEnded={this.onEnded}
              width="100%"
              height="100%"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

FilePreviewVideo.defaultProps = {
  actions: {},
};

FilePreviewVideo.propTypes = {
  actions: PropTypes.object,
  fileUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilePreviewVideo);
