import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card, CardBody, Col,
  Container, FormGroup, Input, Label,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {setDocumentTitle} from "../utils/general";
import Header from "./CustomPage/Header";
import Footer from "./Common/Footer";

import * as usersActions from '../actions/usersActions';
import Noty from "noty";
import {Link} from "react-router-dom";

class SignUpPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };

    this.onEmailChanged = this.onEmailChanged.bind(this);
    this.onPasswordChanged = this.onPasswordChanged.bind(this);
    this.onSaveClicked = this.onSaveClicked.bind(this);


    this.createStarted = false;
  }

  componentDidMount() {
    setDocumentTitle('Signup Page');
  }


  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    if (
      this.createStarted &&
      this.props.signUp !== nextProps.signUp
    ) {
      this.handleStatusChange(nextProps);
    }
  }

  handleStatusChange(props) {
    if (
      !props.signUp.saving && props.signUp.saved
    ) {
      new Noty({
        type: 'success',
        layout: 'bottomLeft',
        text: 'New user created successfully.',
      }).show();

      this.createStarted = false;
    } else if (
      !props.signUp.saving && !props.signUp.saved
    ) {
      let errorMessage = props.signUp.error;
      if (!errorMessage) {
        errorMessage = 'Failed to create user due to server error.';
      }

      new Noty({
        type: 'error',
        layout: 'bottomLeft',
        text: errorMessage,
      }).show();

      this.createStarted = false;
    }
  }

  onEmailChanged(event) {
    this.setState({
      email: event.target.value,
    });
  }

  onPasswordChanged(event) {
    this.setState({
      password: event.target.value,
    });
  }

  onSaveClicked() {
    this.props.actions.userSignUpStarting();
    this.props.actions.userSignUp(this.state);
    this.createStarted = true;
  }


  render() {
    const {email, password} = this.state;
    const {signUp} = this.props;
    const signUpData = signUp;

    let btnRegister;
    if (signUpData && signUpData.saving) {
      btnRegister = (
        <Button color="success" disabled>
          <i className="fa fa-spinner fa-spin"/>
          &nbsp;
          Please wait...
        </Button>
      );
    } else {
      btnRegister = (
        <Button color="success" onClick={this.onSaveClicked}>
          <i className="fa fa-sign-in"/>
          &nbsp;
          Register
        </Button>
      );
    }

    return (
      <div className="custom-page login-page">
        <Header pageTitle="Sign Up"/>
        <Container>
          <Card style={{ width: '35rem' }} >
            <CardBody>
              <Row>
                <Col sm={12} >
                  <FormGroup className="">
                    <Label for="email">Email</Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder=""
                      value={email}
                      onChange={this.onEmailChanged}
                    />
                  </FormGroup>
                  <FormGroup className="">
                    <Label for="password">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder=""
                      value={password}
                      onChange={this.onPasswordChanged}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" className="mt-2">
                  <FormGroup check>
                    <Label check>
                      <Input
                        className="bg-primary"
                        type="checkbox"
                        checked={true}
                        disabled
                        readonly

                      />&nbsp;
                      I have read the Privacy Policy and I agree to Terms of Service.
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <div className="mt-0">
            <Card  style={{ width: '35rem' }}>
              <CardBody>
                <Row>
                  <Col>
                    <small> <Link to="/sign-in">Login</Link> </small>
                  </Col>
                  <Col className="text-right">
                    {btnRegister}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Container>
        <Footer/>
      </div>
    );
  }
}

SignUpPage.defaultProps = {
  actions: {},
  signUp: {},
};

SignUpPage.propTypes = {
  actions: PropTypes.object,
  signUp: PropTypes.object,
};

const mapStateToProps = state => ({
  signUp: state.signUp,

});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, usersActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
