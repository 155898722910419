import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getFileUrl} from "../../../utils/general";

// import * as actionsActions from '../actions/actions';

class CalendarFilePreviews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  getImageCols() {
    const {resultData} = this.props;
    const { fileIds } = resultData.result;
    const {files} = this.props;
    const rows = [];

    fileIds.forEach((fileId, index) => {
      if (!files[fileId]) {
        return true;
      }
      const file = files[fileId].file;
      if (!file) {
        return true;
      }

      //console.log( file);
      const imageUrl = getFileUrl(file);

      rows.push((
        <Col xs={4} key={index} className="files-container pb-2 ">
          <img
            src={imageUrl}
           />
        </Col>
      ));
    });

    return rows;
  }

  render() {
    const { resultData } = this.props;
    const { fileIds } = resultData.result;

    if (!fileIds || !fileIds.length) {
      return null;
    }

    const imageCols = this.getImageCols();

    return (
      <div>
        <Row>
       {/* { fileIds.join(', ') }*/}
        {imageCols}
        </Row>
      </div>
    );
  }
}

CalendarFilePreviews.defaultProps = {
  actions: {},
  files: {},
};

CalendarFilePreviews.propTypes = {
  actions: PropTypes.object,
  files: PropTypes.object,
  resultData: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  files: state.files,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarFilePreviews);
