import React from 'react';
import PropTypes from 'prop-types';
import {
 Col, Row,
} from 'reactstrap';
import PersonDetails from "./PersonResult/PersonDetails";
import ResultTypeUnderDevelopment from './Common/ResultTypeUnderDevelopment';

// import * as actionsActions from '../actions/actions';

class PersonResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { query, resultData } = this.props;

    return (
      <Row className="person-result">
        <Col xs={12} sm={12} md={12} lg={9} xl={10}>
          <ResultTypeUnderDevelopment />

          <PersonDetails
            query={query}
            resultData={resultData}/>
        </Col>
      </Row>
    );
  }
}

PersonResult.propTypes = {
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};

export default PersonResult;
