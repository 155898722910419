import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, CardFooter, CardHeader, Col, Row,
} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import SearchCount from "../Common/SearchCount";
import ResultUpdatedDate from "./ResultUpdatedDate";
import AddResultTypeDropdown from './MetaSearchResult/AddResultTypeDropdown';

const htmlParser = require('react-markdown/plugins/html-parser');

const parseHtml = htmlParser({
  isValidNode: node => node.type !== 'script',
  processingInstructions: [/* ... */]
});

class FixedTextResult extends React.Component {
  render() {
    const { query, resultData } = this.props;
    let { fixedText, fixedTextType } = resultData.result;
    const { hideCardHeader, updatedAt } = resultData.result;
    let content;

    if (fixedTextType === 'markdown') {
      content = (
        <ReactMarkdown
          source={fixedText}
          escapeHtml={false}
          astPlugins={[parseHtml]}
        />
      );
    } else if (fixedTextType === 'html') {
      content = (
        <div dangerouslySetInnerHTML={{ __html: fixedText }} />
      );
    } else {
      content = (
        <div className="ql-editor" dangerouslySetInnerHTML={{ __html: fixedText }} />
      );
    }

    let cardHeader = null;
    if (hideCardHeader !== true) {
      cardHeader = (
        <CardHeader>
          <Row>
            <Col xs="auto">
              {query}
            </Col>
            <Col xs="auto" className="  ml-auto">
              <SearchCount searchCount={resultData.result.searchCount} />
            </Col>
            <Col xs="auto" className="d-none d-md-block">
              <div className="d-inline-block  ml-3">
                <AddResultTypeDropdown
                  query={query}
                  resultData={resultData}
                />
              </div>
            </Col>
          </Row>
        </CardHeader>
      );
    }

    return (
      <Row className="fixed-text-result">
        <Col xs={12}>
          <Card>
            {cardHeader}
            <CardBody className="fixed-text-container">
              {content}
            </CardBody>
            <CardFooter>
              <ResultUpdatedDate updatedAt={updatedAt} />
            </CardFooter>
          </Card>
        </Col>
      </Row>
    );
  }
}

FixedTextResult.propTypes = {
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};

export default FixedTextResult;
