import React from 'react';
import {
 Row, Col,
} from 'reactstrap';

// import * as actionsActions from '../actions/actions';

class ThreePhotoView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

  }

  render() {
    const {media} = this.props;

    return (
      <div className="three-photoview-container text-center">
        <Row noGutters>
          <Col xs={6} >
            <img src={media[0]} />
          </Col>
            <Col xs={6} >
              <Row>
              <img src={media[1]} />
              </Row>
              <Row>
                <img src={media[2]} />
              </Row>

            </Col>
           </Row>
     </div>
    );
  }
}


export default ThreePhotoView;

