import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'reactstrap';
import SearchCount from "./SearchCount";

// import * as actionsActions from '../actions/actions';

class SearchSuggestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {option, result} = this.props;

    let symbol = "";

    switch (result.resultType) {
      case 'alias': {
        symbol = (<i className="fa fa-search fa-fw"/>);
        break;
      }

      case 'expression': {
        symbol = (<i className="fa fa-calculator fa-fw"/>);
        break;
      }

      case 'meta_search': {
        symbol = (<i className="fa fa-search fa-fw"/>);
        break;
      }

      case 'redirect': {
        symbol = (<i className="fa fa-external-link fa-fw"/>);
        break;
      }

      case 'person': {
        symbol = (<i className="fa fa-user-o fa-fw"/>);
        break;
      }

      case 'files': {
        symbol = (<i className="fa fa-files-o fa-fw"/>);
        break;
      }

      case 'company': {
        symbol = (<i className="fa fa-building fa-fw"/>);
        break;
      }

      case 'place': {
        symbol = (<i className="fa fa-map-marker fa-fw"/>);
        break;
      }

      case 'book': {
        symbol = (<i className="fa fa-book fa-fw"/>);
        break;
      }

      case 'movie': {
        symbol = (<i className="fa fa-film fa-fw"/>);
        break;
      }

      case 'dictionary_prefix': {
        symbol = (<i className="fa fa-file-word-o fa-fw"/>);
        break;
      }

      case 'dictionary_word': {
        symbol = (<i className="fa fa-file-word-o fa-fw"/>);
        break;
      }

      case 'video': {
        symbol = (<i className="fa fa-video-camera fa-fw"/>);
        break;
      }
      case 'fixed_text': {
        symbol = (<i className="fa fa-align-left fa-fw"/>);
        break;
      }

      case 'photos_timeline': {
        symbol = (<i className="fa fa-camera fa-fw"/>);
        break;
      }

      case 'blog_article': {
        symbol = (<i className="fa fa-file-text-o fa-fw"/>);
        break;
      }

      default: {
        symbol = (<i className="fa fa-question-circle fa-fw"/>);
        break;
      }

    }

    return (
      <div key={option} className="suggestion-row">
        <Row>
          <Col className="suggestion-symbol ">
            {symbol}
            <span className="ml-3 text-center">
              {option}
            </span>
          </Col>
          <Col xs="auto" className="d-none d-sm-flex ml-auto text-right">
            <SearchCount searchCount={result.searchCount}/>
           </Col>
        </Row>
      </div>
    );
  }
}

SearchSuggestion.defaultProps = {};

SearchSuggestion.propTypes = {
  option: PropTypes.string.isRequired,
  result: PropTypes.object.isRequired,
};

export default SearchSuggestion;
