import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Modal, ModalHeader, ModalBody, ModalFooter, Form, Col, FormGroup, Label,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import FileUpload from "../../EditPage/FileUpload";


class UploadResultForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onFileUploaded = this.onFileUploaded.bind(this);
  }

  onFileUploaded(file) {
    this.props.toggleModal();
  }

  render() {
    const {toggleModal} = this.props;

    return (
      <Modal isOpen={true} toggle={toggleModal} className="upload-photo-modal">
        <ModalHeader toggle={toggleModal}>Photo Upload </ModalHeader>
        <ModalBody>
          <div>
            <Form>
              <Row>
                <Col sm="12">
                  <FormGroup className="">
                    <Label for="exampleFile">Upload New Photo</Label>
                    <FileUpload
                      onFileUploaded={this.onFileUploaded}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
        </ModalBody>
        <ModalFooter>

        </ModalFooter>
      </Modal>

    );
  }
}

UploadResultForm.defaultProps = {
  actions: {},
  fileUploadStatus: {},

};

UploadResultForm.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  fileUploadStatus: PropTypes.object,
  onFileUploaded: PropTypes.func.isRequired,
};

const mapStateToProps = (/* state */)  => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({},), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadResultForm);
