import React from 'react';
import PropTypes from 'prop-types';
import {Col, Form, Input, InputGroup, Row, InputGroupAddon, Button,} from 'reactstrap';
import { history } from '../../../store/configureStore';
import { suppressEvent } from '../../../utils/general';
import { connect } from 'react-redux';

class SearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: props.defaultQuery ? props.defaultQuery : '',
    };

    this.onSearchQueryChanged = this.onSearchQueryChanged.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.clearQuery = this.clearQuery.bind(this);
  }

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus) {
      const input = document.querySelector('.search-form .search-input');
      if (input) {
        input.focus();
      }
    }
  }

  /* eslint-disable-next-line react/no-deprecated */
  componentWillReceiveProps(nextProps) {
    if (this.props.defaultQuery !== nextProps.defaultQuery && this.state.query !== nextProps.defaultQuery) {
      this.setState({
        query: nextProps.defaultQuery,
      });
    }
  }

  onSearchQueryChanged(event) {
    suppressEvent(event);

    this.setState({
      query: event.target.value,
    });
  }

  onSearchSubmit(event) {
    suppressEvent(event);

    const { query } = this.state;
    if (!query) {
      return false;
    }

    history.push(`/search?q=${encodeURIComponent(query)}`);
    return false;
  }

  clearQuery() {
    this.setState({
      query: '',
    });

    document.querySelectorAll('div.calculator-result-search-form input[type=text]')[0].focus();
  }

  render() {
    const { query } = this.state;

    return (
      <Row className="calculator-result-search-form search-form">
        <Col xs={12}>
          <Form method="POST" onSubmit={this.onSearchSubmit}>
            <InputGroup className="mt-0">
              <InputGroupAddon addonType="prepend">
                <Button color="danger" className="" onClick={this.clearQuery}>
                  <i className="fa fa-times" />
                </Button>
              </InputGroupAddon>
              <Input
                className="text-right"
                value={query}
                onChange={this.onSearchQueryChanged}
              />
            </InputGroup>
          </Form>
        </Col>
      </Row>
    );
  }
}

SearchForm.defaultProps = {
  appConfig: {},
  autoFocus: false,
  defaultQuery: '',
};

SearchForm.propTypes = {
  appConfig: PropTypes.object,
  autoFocus: PropTypes.bool,
  defaultQuery: PropTypes.string,
};

const mapStateToProps = (state => ({
  appConfig: state.appConfig,
}));

export default connect(mapStateToProps)(SearchForm);
