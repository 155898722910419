import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row,
} from 'reactstrap';
import CompanyDetails from "./CompanyResult/CompanyDetails";
import ResultTypeUnderDevelopment from "./Common/ResultTypeUnderDevelopment";
import PersonDetails from "./PersonResult/PersonDetails";

class CompanyResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { query, resultData } = this.props;
    return (
      <Row className="company-result">
        <Col xs={12} sm={12} md={12} lg={9} xl={10}>
          <ResultTypeUnderDevelopment />

          <CompanyDetails
            query={query}
            resultData={resultData}
          />
        </Col>
      </Row>
    );
  }
}

CompanyResult.propTypes = {
  query: PropTypes.string.isRequired,
  resultData: PropTypes.object.isRequired,
};

export default CompanyResult;
