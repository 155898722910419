import React from 'react';
import {Button} from 'reactstrap';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as cartActions from "../../actions/cartActions";
import {connect} from "react-redux";

class QuantityButtons extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.onDecreaseQuantity = this.onDecreaseQuantity.bind(this);
    this.onIncreaseQuantity = this.onIncreaseQuantity.bind(this);

    this.qtyChangeStarted = false;
  }

  onIncreaseQuantity() {
    const qty = 1;
    const { _id } = this.props.product;
    this.props.actions.setCartItemQuantityStarting(_id,qty+1);
    this.props.actions.setCartItemQuantity(this.state);

    this.qtyChangeStarted = true;

  }

  onDecreaseQuantity() {
    const qty = 1;
    const { _id } = this.props.product;
    this.props.actions.setCartItemQuantityStarting(_id,qty-1);
    this.props.actions.setCartItemQuantity(this.state);

    this.qtyChangeStarted = true;
  }

  render() {
    return (
      <div className="mt-2">
        <Button size="sm" color="white" className="btn-outline-danger" onClick={this.onDecreaseQuantity}><i className="fa fa-minus"/></Button>
        <span className="ml-2 txt-product-qty">1</span>
        <Button size="sm" color="white" className="btn-outline-primary ml-2" onClick={this.onIncreaseQuantity}><i className="fa fa-plus"/></Button>
      </div>
    );
  }
}

QuantityButtons.defaultProps = {
  actions: {},
  setCartItemQuantity: {},
};

QuantityButtons.propTypes = {
  actions: PropTypes.object,
  setCartItemQuantity: PropTypes.object,
  product: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  setCartItemQuantity: state.setCartItemQuantity,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, cartActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuantityButtons);
