import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { humanFileSize } from '../../../utils/numberFormat';
import FileActionButtons from './FileActionButtons';

class FilesTableRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPreviewModal: false,
    };

    this.togglePreviewModal = this.togglePreviewModal.bind(this);
  }

  togglePreviewModal() {
    const { showPreviewModal } = this.state;
    this.setState({
      showPreviewModal: !showPreviewModal,
    });
  }

  render() {
    const { file, fileId, index, onFilePreviewClicked } = this.props;
    if (!file) {
      return null;
    }

    console.log(onFilePreviewClicked);
    const updatedDt = moment(file.updatedDt);
    const updatedDtReadable = updatedDt.fromNow();

    return (
      <tr key={fileId}>
        <th scope="row" className="text-center">{index + 1}</th>

        <td className="text-left">
          <div className="file-name">
            {file.originalFileName}
          </div>

          <div>
            <p className="mt-0 mb-0">
              <small>
                {humanFileSize(file.fileSize, false, 0)}
              </small>
            </p>

            <p className="mt-0 mb-0">
              <small>
                <i className="fa fa-clock-o" />&nbsp; {updatedDtReadable}
              </small>
            </p>
          </div>

          <div className="d-block d-md-none mt-2 mb-0">
            <FileActionButtons
              file={file}
              onFilePreviewClicked={onFilePreviewClicked}
            />
          </div>
        </td>

        <td className="text-center d-none d-md-table-cell">
          <FileActionButtons
            file={file}
            onFilePreviewClicked={onFilePreviewClicked}
          />
        </td>
      </tr>
    );
  }
}

FilesTableRow.propTypes = {
  file: PropTypes.object.isRequired,
  fileId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onFilePreviewClicked: PropTypes.func.isRequired,
};

export default FilesTableRow;
