import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, CardHeader,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import * as actionsActions from '../actions/actions';

class QuotePost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {quote} = this.props;
    return (
      <div className= "news-post">
        <Card className="mb-2">
         <CardHeader>
           <h6> Quote for the Day </h6>
         </CardHeader>
          <CardBody>
            <Row>
              <Col xs={12}  >

                <p className=""> {quote}</p>

              </Col>

            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }
}

QuotePost.defaultProps = {
  actions: {},
  quote: '',
};

QuotePost.propTypes = {
  actions: PropTypes.object,
  quote: PropTypes.string,

};

const mapStateToProps = (/* state */)  => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuotePost);
