import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Card, CardBody, Table, Col, Button
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddUserModal from "./AddUserModal";
import EditUserModal from "./EditUserModal";
import DeleteUserModal from "./DeleteUserModal";
import * as usersActions from '../../actions/usersActions';

class ManageUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddUserModal: false,
      showEditUserModal: false,
      showDeleteUserModal: false,
      selectedUser: null,
    };

    this.toggleAddUserModal = this.toggleAddUserModal.bind(this);
    this.toggleEditUserModal = this.toggleEditUserModal.bind(this);
    this.toggleDeleteUserModal = this.toggleDeleteUserModal.bind(this);
  }

  componentDidMount() {
    const { users } = this.props;
    if (!users.loaded && !users.loading) {
      this.props.actions.getUsersStarting();
      this.props.actions.getUsers();
    }
  }

  toggleAddUserModal() {
    const { showAddUserModal } = this.state;
    this.setState({
      showAddUserModal: !showAddUserModal,
    });
  }

  toggleEditUserModal(selectedUser) {
    console.log(selectedUser);
    const { showEditUserModal } = this.state;
    this.setState({
      selectedUser,
      showEditUserModal: !showEditUserModal,
    });
  }

  toggleDeleteUserModal(selectedUser) {
    const { showDeleteUserModal } = this.state;
    this.setState({
      selectedUser,
      showDeleteUserModal: !showDeleteUserModal,
    });
  }

  getUserRows() {
    const { users } = this.props;

    const rows = [];
    users.ids.forEach((userId, index) => {
      const userData = users.map[userId];
      rows.push((
        <tr key={userId}>
          <th scope="row">{index + 1}</th>
          <td>{userData.email}</td>
          <td className="text-center">{userData.canEdit ? 'Yes' : 'No'}</td>
          <td className="text-center">{userData.isAdministrator ? 'Yes' : 'No'}</td>
          <td className="text-center">
            <Button
              color="info"
              onClick={() => {
                this.toggleEditUserModal(userData);
              }}
            >
              <i className="fa fa-edit" />
            </Button>
            <Button
              color="danger"
              className="ml-3"
              onClick={() => {
                this.toggleDeleteUserModal(userData);
              }}
            >
              <i className="fa fa-times" />
            </Button>
          </td>
        </tr>
      ));
    });

    return rows;
  }

  render() {
    const { users } = this.props;

    let content;
    if (users.loading) {
      content = (
        <p className="m-0">Please wait...</p>
      );
    } else if (!users.loaded) {
      content = (
        <p className="m-0">Please wait...</p>
      );
    } else if (!users.ids.length) {
      content = (
        <p className="m-0">No users created yet.</p>
      );
    } else {
      const rows = this.getUserRows();

      content = (
        <Table className="mt-3">
          <thead>
          <tr className="">
            <th style={{ width: '5%' }} >#</th>
            <th style={{ width: '50%' }}>Email</th>
            {/*<th className="text-left">Token</th>*/}
            <th  style={{ width: '15%' }}>Edit Privilege</th>
            <th style={{ width: '15%' }}>Is Administrator</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      );
    }

    const { showAddUserModal, showEditUserModal, showDeleteUserModal, selectedUser } = this.state;

    let addUserModal = null;
    let editUserModal = null;
    let deleteUserModal = null;

    if (showAddUserModal) {
      addUserModal = (
        <AddUserModal toggleModal={this.toggleAddUserModal} />
      );
    }

    if (showEditUserModal) {
      editUserModal = (
        <EditUserModal
          selectedUser={selectedUser}
          toggleModal={this.toggleEditUserModal}
        />
      );
    }

    if (showDeleteUserModal) {
      deleteUserModal = (
        <DeleteUserModal
          selectedUser={selectedUser}
          toggleModal={this.toggleDeleteUserModal}
        />
      );
    }

    return (
      <div className="mt-2 manage-users d-flex flex-column justify-content-center  ">
        <Card>
          <CardBody>
            <Row className="">
              <Col>
                <h5>Manage Users</h5>
              </Col>
              <Col className="">
                <Button color="primary  float-right" onClick={this.toggleAddUserModal}>
                  Add New User
                </Button>
              </Col>
            </Row>

            {content}

          </CardBody>
        </Card>

        {addUserModal}
        {editUserModal}
        {deleteUserModal}
      </div>
    );
  }
}

ManageUsers.defaultProps = {
  actions: {},
  users: {},
};

ManageUsers.propTypes = {
  actions: PropTypes.object,
  users: PropTypes.object,
};

const mapStateToProps = state => ({
  users: state.users,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, usersActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
