import React from 'react';
import Points from "./LandingPage/Points";
import HeroSection from "./LandingPage/HeroSection";
import Footer from "./LandingPage/Footer";
import Marketing from "./LandingPage/Marketing";


class LandingPage extends React.Component {
  render() {
    return (
      <div className="landing-page">
        <HeroSection />
        <Points />
        <Marketing />
        <Footer />
      </div>
    );
  }
}


export default LandingPage;
