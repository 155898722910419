import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ResultTypeUnderDevelopment from "./Common/ResultTypeUnderDevelopment";
import ProductsListData from "./ProductsListResult/ProductsListData";



// import * as actionsActions from '../actions/actions';

class ProductsListResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Row className="product-list-result">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ResultTypeUnderDevelopment/>

        <ProductsListData />

        </Col>
      </Row>
    );
  }
}

ProductsListResult.defaultProps = {
  actions: {},
};

ProductsListResult.propTypes = {
  actions: PropTypes.object,
  query: PropTypes.string,
  resultData: PropTypes.object.isRequired,
};


const mapStateToProps = (/*state*/) => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsListResult);
